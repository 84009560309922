import React, { useState, useEffect, useCallback } from 'react'

import { Navigate } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import {
    Company,
    LifemarkUserModel,
} from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import Loading from 'web_common/tsx/components/misc/Loading'

import { urls } from 'InternalUrls'

interface ChangeUserCompanyProps {
    user: LifemarkUserModel
}

const ChangeUserCompany : React.FC<ChangeUserCompanyProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [companyList, setCompanyList] = useState<Array<Company>|undefined>(undefined)
    const [selectedCompanyIdx, setSelectedCompanyIdx] = useState<number>(0)

    const { user } = props

    useEffect(() =>  {
        const onResponse = (response: Array<Company>|undefined) =>
        {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                response.sort(
                    (a: Company, b: Company) =>
                {
                    return a.name.localeCompare(b.name)
                })

                let idx: number = response.findIndex((e) =>
                {
                    return e.id === user.company
                })

                if(idx !== -1)
                {
                    setSelectedCompanyIdx(idx)
                }
                setCompanyList(response)
            }
        }

        db.company_list(undefined, onResponse)
    }, [setCompanyList, user])

    const handleChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) =>
    {
        setSelectedCompanyIdx(parseInt(e.target.value))
    }, [setSelectedCompanyIdx])

    const onClick = useCallback(() =>
    {
        if(companyList === undefined)
        {
            return
        }

        let company: Company|undefined = companyList[selectedCompanyIdx]
        if(company === undefined)
        {
            return
        }

        const onResponse = (success: boolean) =>
        {
            if(success === true)
            {
                setShowLoading(false)
                setSuccess(true)
            }
            else
            {
                setShowLoading(false)
                setApiError(true)
            }
        }

        setShowLoading(true)
        db.patch_user_company(
            user.id,
            company.id,
            onResponse)
    }, [selectedCompanyIdx, companyList, user, setShowLoading, setSuccess, setApiError])

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(showLoading === true)
    {
        return (
            <Loading/>
        )
    }
    else if(success === true)
    {
        return (
            <Navigate
                to={urls.getRoot()}
            />
        )
    }
    else if(companyList !== undefined)
    {
        return (
            <div>
                <div className='limn-div-row limn-margin-25 limn-width-50'>
                    <label
                        className='limn-label-padded limn-width-200px'
                        htmlFor='company-select'
                    >
                        Select Company:
                    </label>
                    <select
                        className='limn-select-box'
                        id='company-select'
                        name='company-select'
                        value={selectedCompanyIdx}
                        onChange={handleChange}
                    >
                    {
                        companyList.map(
                            (obj, idx) =>
                        {
                            return (
                                <option
                                    key={idx}
                                    value={idx}
                                >
                                    { obj.name }
                                </option>
                            )
                        })
                    }
                    </select>
                </div>
                <div className='limn-div-centered'>
                    <button
                        className='limn-button limn-width-40 limn-padded-vert-10px'
                        onClick={onClick}
                    >
                        Change User's Company
                    </button>
                </div>
            </div>
        )
    }
    else
    {
        return (
            <Loading/>
        )
    }
}

export default ChangeUserCompany
