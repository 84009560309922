import React, { useState, useEffect } from 'react'

import { Navigate, useParams } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { CompanyAdminPrivilege, LifemarkUserModel, ProcessedPathDetail, PathPreview, AuthToken } from 'web_common/tsx/api/db/Models'
import { PatternTransitionResponse, service_api } from 'web_common/tsx/api/service/Api'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import { urls } from 'InternalUrls'
import PathDetail from 'components/paths/PathDetail'

interface ArchivedPathInfoProps {

}

const ArchivedPathInfo: React.FC<ArchivedPathInfoProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [infoSuccess, setInfoSuccess] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [archivedPath, setArchivedPath] = useState<ProcessedPathDetail|undefined>(undefined)
    const [previewPath, setPreviewPath] = useState<PathPreview|undefined>(undefined)
    const [userToken, setUserToken] = useState<AuthToken|undefined>(undefined)
    const [patternTransitions, setPatternTransitions] = useState<PatternTransitionResponse|undefined>(undefined)

    const { id } = useParams()

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() => {
        if(id !== undefined)
        {
            const onArchivedPath = (response: ProcessedPathDetail|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setArchivedPath(response)
                }
            }

            db.processed_path_detail(id, onArchivedPath)
        }
    }, [id])

    useEffect(() => {
        if(archivedPath !== undefined)
        {
            const onPreview = (response: PathPreview|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setPreviewPath(response)
                }
            }

            db.preview_processed_path(
                archivedPath.preview_url,
                onPreview)
        }
    }, [archivedPath])

    useEffect(() => {
        if(lifemarkUser !== undefined)
        {
            let onResponse = (response: AuthToken|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setUserToken(response)
                }
            }

            db.auth_token(onResponse)
        }
    }, [lifemarkUser])

    useEffect(() => {
        if(archivedPath !== undefined && userToken !== undefined)
        {
            const onTransition = (response: PatternTransitionResponse) =>
            {
                if(response.error !== undefined)
                {
                    console.log(response.error)
                    setApiError(true)
                }
                else
                {
                    setPatternTransitions(response)
                }
            }

            service_api.preview_transitions(
                userToken.token,
                archivedPath.id,
                onTransition)
        }
    }, [archivedPath, userToken])

    const infoArchivedPath = () =>
    {
        setInfoSuccess(true)
    }

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(infoSuccess === true)
    {
        return (
            <Navigate
                to={urls.getArchivedPaths()}
            />
        )
    }
    else if(lifemarkUser !== undefined)
    {
        if(lifemarkUser.privilege === CompanyAdminPrivilege.NONE)
        {
            return (
                <PermissionDenied/>
            )
        }
        if(archivedPath !== undefined && previewPath !== undefined && patternTransitions !== undefined)
        {
            return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Archived Path Info </h1>
                    <PathDetail
                        truckList={undefined}
                        pathPreview={previewPath}
                        patternTransitions={patternTransitions.transitions}
                        referencePath={patternTransitions.reference_path}
                        pathName={archivedPath.path_name}
                        pathDescription={archivedPath.path_description}
                        submitButtonName={'Return to Archived Paths'}
                        onSubmit={infoArchivedPath}
                    />
                </div>
            )
        }
        else
        {
           return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Archived Path Info </h1>
                    <Loading/>
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Archived Path Info </h1>
                <Loading/>
            </div>
        )
    }
}

export default ArchivedPathInfo
