import React, { useState, useEffect } from 'react'

import { Navigate } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { Company, CompanyAdminPrivilege, LifemarkUserModel, Truck } from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import { urls } from 'InternalUrls'
import PathForm, { PathFormSubmitObject } from 'components/paths/PathForm'

interface ProposedPathCreateProps {

}

const ProposedPathCreate: React.FC<ProposedPathCreateProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [showLoading, setShowLoading]  = useState<boolean>(false)
    const [createSuccess, setCreateSuccess] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [company, setCompany] = useState<Company|undefined>(undefined)
    const [trucks, setTrucks] = useState<Array<Truck>|undefined>(undefined)

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() => {
        if(lifemarkUser !== undefined)
        {
            const onCompany = (response: Company|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setCompany(response)
                }
            }

            db.company_detail(lifemarkUser.company, onCompany)
        }
    }, [lifemarkUser])

    useEffect(() =>{
        if(lifemarkUser !== undefined)
        {
            const onTrucks = (response: Array<Truck>|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setTrucks(response)
                }
            }

            db.truck_list(lifemarkUser.company, onTrucks)
        }
    }, [lifemarkUser])

    const createProposedPath = (obj: PathFormSubmitObject) =>
    {
        let truckList: Array<string> = []
        if(obj.truckList !== undefined)
        {
            truckList = obj.truckList.filter(
                (obj) =>
            {
                return obj.assignment === true
            }).map(
                (obj, idx) =>
            {
                return obj.truck.id
            })
        }

        const onCompletion = (results: boolean) =>
        {
            if(results === false)
            {
                setShowLoading(false)
                setApiError(true)
            }
            else
            {
                setShowLoading(false)
                setCreateSuccess(true)
            }
        }

        if(company !== undefined)
        {
            setShowLoading(true)
            db.create_proposed_path(
                obj.pathName,
                obj.pathDescription,
                company.id,
                truckList,
                onCompletion)
        }
    }

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(createSuccess === true)
    {
        return (
            <Navigate
                to={urls.getProposedPaths()}
            />
        )
    }
    else if(showLoading === true)
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Create Proposed Path </h1>
                <Loading/>
            </div>
        )
    }
    else if(lifemarkUser !== undefined)
    {
        if(lifemarkUser.privilege === CompanyAdminPrivilege.NONE)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(lifemarkUser.privilege === CompanyAdminPrivilege.USER)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(lifemarkUser.privilege === CompanyAdminPrivilege.ADMIN)
        {
            if(trucks !== undefined && company !== undefined)
            {
                let truckList = trucks.map(
                    (obj, idx) =>
                {
                    return {
                        truck: obj,
                        assignment: true
                    }
                })

                return (
                    <div className='limn-form-container'>
                        <h1 className='limn-header'> Create Proposed Path </h1>
                        <PathForm
                            truckList={truckList}
                            pathPreview={undefined}
                            referencePath={undefined}
                            patternTransitions={undefined}
                            pathName={''}
                            pathDescription={''}
                            submitButtonName={'Create Proposed Path'}
                            onSubmit={(obj) => { createProposedPath(obj) }}
                        />
                    </div>
                )
            }
            else
            {
                return (
                    <div className='limn-form-container'>
                        <h1 className='limn-header'> Create Proposed Path </h1>
                        <Loading/>
                    </div>
                )
            }
        }
        else
        {
           return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Create Proposed Path </h1>
                    <Loading/>
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Create Proposed Path </h1>
                <Loading/>
            </div>
        )
    }
}

export default ProposedPathCreate
