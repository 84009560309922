export enum CompanyAdminPrivilege {
    USER = 1,
    ADMIN = 2,
    NONE = 3
}

export interface LifemarkUserModel {
    id: number,

    company: string,

    username: string,
    first_name: string,
    last_name: string,
    email: string,

    truck: string|null,
    privilege: CompanyAdminPrivilege,
    company_admin_superuser: boolean,
    solo_user: boolean
}

export interface Company {
    id: string,
    name: string,
    is_active: boolean,
    solo_permission: boolean,
    pattern_coding_unlock: boolean,
    high_res_data_subscription: boolean,
    subscription_remaining_dl_milage: number,
    persistent_remaining_dl_milage: number
}

export interface ProcessedPathInfo {
    id: string,
    path_name: string,
    path_description: string,
    date_created: Date,
    path_length_meters: number|null
}

export interface ProcessedPathExportInfo {
    id: string,
    path_name: string,
    path_description: string,
    date_created: Date,
    path_length_meters: number|null,
    exported: boolean,
}

export interface ProcessedPathSample {
    id: string,
    preview_url: string,
}

export interface ProcessedPathDetail {
    id: string,
    path_name: string,
    path_description: string,
    date_created: Date,
    preview_url: string,
    assigned_truck_list: Array<string>
    is_active: boolean,
    path_length_meters: number|null
}

export interface ProposedPathDetail {
    id: string,
    path_name: string,
    path_description: string,
    date_created: Date,
    assigned_truck_list: Array<string>,
    company: string,
}

export interface Truck {
    id: string,
    customer_name: string,
    limntech_name: string,
    truck_type: string,
    is_active: boolean,
    configuration_update_channel: string|null,
    lifemark_app_update_channel: string|null,
    lm75_update_channel: string|null,
    company: string
}

export interface NtripCredential {
    id: string,
    truck: string,
    rtk_service: string,
    username: string,
    password: string,
    host: string,
    port: number,
    stream: string,
    is_active: boolean,
    region: string
}

export interface RtkService {
    id: string,
    name: string,
    map_url: string
}

export interface ImportedPath
{
    id: string,
    path_name: string,
    path_description: string
}

export interface AuthToken
{
    token: string,
}

export interface EncodedPathEntry {
    encoded_path: string
}

export interface PathPreview {
    path_list: Array<EncodedPathEntry>,
    precision: number
}

export interface HighResolutionPathData {
    id: string,
}
