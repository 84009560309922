import React, { useState } from 'react'

import { Table } from 'react-bootstrap'

import { Truck, PathPreview } from 'web_common/tsx/api/db/Models'
import { PatternTransitions } from 'web_common/tsx/api/service/Api'
import MapOverlay, { EncodedPath } from 'web_common/tsx/components/misc/MapOverlay'

const validatePathName = (pathName: string): boolean =>
{
    return pathName.length <= 50
}
const validatePathDescription = (pathDescription: string): boolean =>
{
    return pathDescription.length <= 200 
}

interface PathNameWarningMessageProps {
    pathName: string
}
const PathNameWarningMessage: React.FC<PathNameWarningMessageProps> = (props) => {
    let valid: boolean = validatePathName(props.pathName)

    if(valid === true)
    {
        return <></>
    }
    else
    {
        return (
            <p className='limn-warning limn-margin-10'>
                The path name must have 50 or fewer characters
            </p>
        )
    }
}

interface PathDescriptionWarningMessageProps {
    pathDescription: string
}
const PathDescriptionWarningMessage: React.FC<PathDescriptionWarningMessageProps> = (props) => {
    let valid: boolean = validatePathDescription(props.pathDescription)

    if(valid === true)
    {
        return <></>
    }
    else
    {
        return (
            <p className='limn-warning limn-margin-10'>
                The path description must have 200 or fewer characters
            </p>
        )
    }
}

interface TruckPair {
    truck: Truck,
    assignment: boolean
}

interface PathPreviewProps {
    pathPreview: PathPreview|undefined,
    patternTransitions: Array<PatternTransitions>
    setPatternTransitions: (val: Array<PatternTransitions>) => void,
    defaultTransitionColor: string,
    warnOfModification: boolean,
    referencePath: EncodedPath|undefined
}

const PathPreviewMap: React.FC<PathPreviewProps> = (props) => {
    let modificationWarning = <></>
    if(props.warnOfModification)
    {
         modificationWarning = (
            <div>
                <p className="limn-warning limn-div-centered"> You have modified the P.A.S. transitions. Pressing the save button will apply these changes which can be downloaded upon your system's next sync. </p>
            </div>
         )
    }

    if(props.pathPreview === undefined)
    {
        return <></>
    }
    else
    {
        let refPath: Array<EncodedPath> = []
        let showAutomation: boolean = false
        if(props.referencePath !== undefined)
        {
            showAutomation = true
            refPath.push(props.referencePath)
        }

        return (
            <div>
                <div className='limn-div-centered limn-padded-vert-10px'>
                    <MapOverlay
                        encodedPaths={[{
                            path_list: props.pathPreview.path_list,
                            precision: props.pathPreview.precision,
                        }]}
                        patternTransitionReference={refPath}
                        patternTransitions={props.patternTransitions}
                        setPatternTransitions={(val: Array<PatternTransitions>) =>
                        {
                            props.setPatternTransitions(val)
                        }}
                        defaultTransitionColor={props.defaultTransitionColor}
                        transitionEditable={true}
                        showPatternAutomation={showAutomation}
                        loaded={ true }
                    />
                </div>
                <div>
                    { modificationWarning }
                    <hr className='limn-padded-vert-10px limn-form-separator-80'/>
                </div>
            </div>
        )
    }
}


interface TruckAssignmentProps {
    truckList: Array<TruckPair>|undefined
    setTruckList: (val: Array<TruckPair>|undefined) => void
}

const TruckAssignmentTable: React.FC<TruckAssignmentProps> = (props) => {
    if(props.truckList === undefined || props.truckList.length === 0)
    {
        return <></>
    }
    else
    {
        const onCheck = (e: React.ChangeEvent<HTMLInputElement>): void =>
        {
            if(props.truckList !== undefined)
            {
                const { name, checked } = e.target
                let newList = [...props.truckList]
                newList[parseInt(name)].assignment = checked
                props.setTruckList(newList)
            }
        }

        let rowMap = props.truckList.map(
            (obj, idx) =>
        {
            return (
                <tr key={idx}>
                    <td> { obj.truck.customer_name } </td>
                    <td> { obj.truck.limntech_name } </td>
                    <td>
                        <input
                            type='checkbox'
                            key={idx}
                            name={idx.toString()}
                            checked={obj.assignment}
                            onChange={onCheck}
                        />
                    </td>
                </tr>
            )
        })

        return (
            <div>
                <div className='limn-padded-10p limn-padded-vert-5p'>
                    <h3> Truck Assignment List </h3>
                    <Table hover className='limn-bigfont'>
                        <thead>
                            <tr>
                                <td>
                                    Truck Name
                                </td>
                                <td>
                                    LimnTech Truck Number
                                </td>
                                <td>
                                    Assign Path
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            { rowMap }
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }
}

export interface PathFormSubmitObject {
    truckList: Array<TruckPair>|undefined,
    pathName: string,
    pathDescription: string,
    transitionChanges: Array<PatternTransitions>|undefined,
}

interface PathFormProps {
    truckList: Array<TruckPair>|undefined,
    pathPreview: PathPreview|undefined,
    patternTransitions: Array<PatternTransitions>|undefined,
    referencePath: EncodedPath|undefined,
    pathName: string,
    pathDescription: string,
    submitButtonName: string,
    onSubmit: (obj: PathFormSubmitObject) => void,
}

const PathForm: React.FC<PathFormProps> = (props) => {
    const [truckList, setTruckList] = useState<Array<TruckPair>|undefined>(props.truckList)
    const [pathName, setPathName] = useState<string>(props.pathName)
    const [pathDescription, setPathDescription] = useState<string>(props.pathDescription)
    const [warnOfModification, setWarnOfModification] = useState<boolean>(false)
    const [modifiedTransitions, setModifiedTransitions] = useState<Array<PatternTransitions>>(() =>
    {
        if(props.patternTransitions === undefined)
        {
            return []
        }
        else
        {
            return props.patternTransitions
        }
    })
    const [defaultTransitionColor] = useState<string>(() =>
    {
        if(props.patternTransitions === undefined)
        {
            return "unknown"
        }
        else if(props.patternTransitions.length === 0)
        {
            return "unknown"
        }
        else
        {
            return props.patternTransitions[0].color
        }
    })

    const onSubmit = (e: React.FormEvent) =>
    {
        e.preventDefault()

        let validatedPathName: boolean = validatePathName(pathName)
        if(validatedPathName === false)
        {
            alert("The path name entered is invalid")
            return
        }

        let validatedPathDescription: boolean = validatePathDescription(pathDescription)
        if(validatedPathDescription === false)
        {
            alert("The path description entered is invalid")
            return
        }

        let transitionChanges: Array<PatternTransitions>|undefined = undefined
        if(warnOfModification === true)
        {
            transitionChanges = modifiedTransitions
        }

        props.onSubmit({
            truckList: truckList,
            pathName: pathName,
            pathDescription: pathDescription,
            transitionChanges: transitionChanges
        })
    }

    return (
        <div className='limn-padded-top-50px'>
            <form onSubmit={onSubmit}>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='path-name'> Path Name: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='path-name'
                        name='path-name'
                        placeholder={'You must enter a path name...'}
                        value={pathName}
                        onChange={(e) => { setPathName(e.target.value) }}
                        required
                    />
                </div>
                <PathNameWarningMessage
                    pathName={pathName}
                />
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor="path-description"> Path Description: </label>
                    <textarea
                        className="limn-input"
                        id="path-description"
                        name="path-description"
                        placeholder={"Optionally, please enter a description of the new path..."}
                        value={pathDescription}
                        onChange={(e) => { setPathDescription(e.target.value) }} 
                    />
                </div>
                <PathDescriptionWarningMessage
                    pathDescription={pathDescription}
                />
                <TruckAssignmentTable
                    truckList={truckList}
                    setTruckList={setTruckList}
                />
                <PathPreviewMap
                    pathPreview={props.pathPreview}
                    referencePath={props.referencePath}
                    patternTransitions={modifiedTransitions}
                    setPatternTransitions={(obj: Array<PatternTransitions>) => {
                        if(warnOfModification === false)
                        {
                            setWarnOfModification(true)
                        }
                        setModifiedTransitions(obj)
                    }}
                    warnOfModification={warnOfModification}
                    defaultTransitionColor={defaultTransitionColor}
                />
                <div className='limn-width-50 limn-margin-25 limn-div-centered'>
                    <button
                        className='limn-button limn-width-90 limn-padded-vert-10px'
                        type='submit'>
                        { props.submitButtonName }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default PathForm
