import React, { useState, useEffect } from 'react'

import { Navigate, useParams } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { CompanyAdminPrivilege, LifemarkUserModel, ProcessedPathDetail, PathPreview, AuthToken, Company } from 'web_common/tsx/api/db/Models'
import { PatternTransitionResponse, service_api } from 'web_common/tsx/api/service/Api'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import { urls } from 'InternalUrls'
import PathForm, { PathFormSubmitObject } from 'components/paths/PathForm'

interface ArchivedPathEditProps {

}

const ArchivedPathEdit: React.FC<ArchivedPathEditProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [showLoading, setShowLoading]  = useState<boolean>(false)
    const [editSuccess, setEditSuccess] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [company, setCompany] = useState<Company|undefined>(undefined)
    const [archivedPath, setArchivedPath] = useState<ProcessedPathDetail|undefined>(undefined)
    const [previewPath, setPreviewPath] = useState<PathPreview|undefined>(undefined)
    const [userToken, setUserToken] = useState<AuthToken|undefined>(undefined)
    const [patternTransitions, setPatternTransitions] = useState<PatternTransitionResponse|undefined>(undefined)

    const { id } = useParams()

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() => {
        if(id !== undefined)
        {
            const onArchivedPath = (response: ProcessedPathDetail|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setArchivedPath(response)
                }
            }

            db.processed_path_detail(id, onArchivedPath)
        }
    }, [id])

    useEffect(() => {
        if(lifemarkUser !== undefined)
        {
            const onCompany = (response: Company|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setCompany(response)
                }
            }

            db.company_detail(lifemarkUser.company, onCompany)
        }
    }, [lifemarkUser])


    useEffect(() => {
        if(archivedPath !== undefined)
        {
            const onPreview = (response: PathPreview|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setPreviewPath(response)
                }
            }

            db.preview_processed_path(
                archivedPath.preview_url,
                onPreview)
        }
    }, [archivedPath])

    useEffect(() => {
        if(lifemarkUser !== undefined)
        {
            let onResponse = (response: AuthToken|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setUserToken(response)
                }
            }

            db.auth_token(onResponse)
        }
    }, [lifemarkUser])

    useEffect(() => {
        if(archivedPath !== undefined && userToken !== undefined && company !== undefined)
        {
            const onTransition = (response: PatternTransitionResponse) =>
            {
                if(response.error !== undefined)
                {
                    console.log(response.error)
                    setApiError(true)
                }
                else
                {
                    setPatternTransitions(response)
                }
            }

            /*
             * Make sure that pattern coding is unlocked before trying
             * to invoke the lambda
             */
            if(company.pattern_coding_unlock === true)
            {
                service_api.preview_transitions(
                    userToken.token,
                    archivedPath.id,
                    onTransition)
            }
            else
            {
                setPatternTransitions({
                    transitions: [],
                    reference_path: undefined,
                    version: undefined,
                    error: undefined
                })
            }
        }
    }, [archivedPath, userToken, company])


    const editArchivedPath = (obj: PathFormSubmitObject) =>
    {
        let truckList: Array<string> = []
        const onCompletion = (results: boolean) =>
        {
            if(results === false)
            {
                setShowLoading(false)
                setApiError(true)
            }
            else
            {
                setShowLoading(false)
                setEditSuccess(true)
            }
        }

        if(archivedPath !== undefined)
        {
            if(obj.transitionChanges !== undefined)
            {
                alert("Pattern transitions will not be updated on the server in this WIP mode")
            }

            setShowLoading(true)
            db.patch_processed_path(
                archivedPath.id,
                obj.pathName,
                obj.pathDescription,
                truckList,
                false,
                onCompletion)
        }
    }

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(editSuccess === true)
    {
        return (
            <Navigate
                to={urls.getArchivedPaths()}
            />
        )
    }
    else if(showLoading === true)
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Edit Archived Path </h1>
                <Loading/>
            </div>
        )
    }
    else if(lifemarkUser !== undefined)
    {
        if(lifemarkUser.privilege === CompanyAdminPrivilege.NONE)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(lifemarkUser.privilege === CompanyAdminPrivilege.USER)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(lifemarkUser.privilege === CompanyAdminPrivilege.ADMIN)
        {
            if(archivedPath !== undefined && previewPath !== undefined && patternTransitions !== undefined)
            {
                return (
                    <div className='limn-form-container'>
                        <h1 className='limn-header'> Edit Archived Path </h1>
                        <PathForm
                            truckList={undefined}
                            pathPreview={previewPath}
                            referencePath={patternTransitions.reference_path}
                            patternTransitions={patternTransitions.transitions}
                            pathName={archivedPath.path_name}
                            pathDescription={archivedPath.path_description}
                            submitButtonName={'Save Archived Path'}
                            onSubmit={editArchivedPath}
                        />
                    </div>
                )
            }
            else
            {
                return (
                    <div className='limn-form-container'>
                        <h1 className='limn-header'> Edit Archived Path </h1>
                        <Loading/>
                    </div>
                )
            }
        }
        else
        {
           return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Edit Archived Path </h1>
                    <Loading/>
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Edit Archived Path </h1>
                <Loading/>
            </div>
        )
    }
}

export default ArchivedPathEdit
