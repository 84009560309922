export default function exampleUploadPath() : string
{
    return (
        "40.52748474,-76.22362968,399.339606\n" +
        "40.52775241,-76.22370965,398.4806185\n" +
        "40.52802482,-76.22375326,397.358771\n" +
        "40.52829955,-76.22376119,395.9948741\n" +
        "40.52857371,-76.22373171,394.3459018\n" +
        "40.52884373,-76.22366252,392.263241\n" +
        "40.52910533,-76.22355722,389.9100601\n" +
        "40.52935792,-76.22341673,387.4006195\n" +
        "40.52959721,-76.22324225,384.863057\n" +
        "40.52982132,-76.22303653,382.3052484\n" +
        "40.53002855,-76.22280103,379.9762925\n" +
        "40.53021523,-76.22253637,377.6420498\n" +
        "40.53038212,-76.22225171,375.0384209\n" +
        ",,\n" +
        "40.53091065,-76.22138162,367.5932447\n" +
        "40.53112457,-76.22115684,365.2244305\n" +
        "40.53135781,-76.22096755,362.9645086\n" +
        "40.53160668,-76.22081692,360.9262149\n" +
        "40.53186722,-76.22070524,358.7411054\n" +
        "40.53197291,-76.22067131,357.8682265\n"
    )
}
