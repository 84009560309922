import React from 'react'

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Link } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { ReconciledNtripInfo } from 'web_common/tsx/api/db/Util'
import BigTable from 'web_common/tsx/components/misc/BigTable'

import { urls } from 'InternalUrls'

interface TruckAdminTableProps {
    reconciledNtripData: Array<ReconciledNtripInfo>,
}

const TruckAdminTable: React.FC<TruckAdminTableProps> = (props) => {
    let headers = [
        {
            title: "Truck Name",
            field: "customer_name",
            dateFilterable: false,
            width: 1000,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "LimnTech Truck Number",
            field: "limntech_name",
            dateFilterable: false,
            width: 1000,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "RTK Service",
            field: "rtk_service",
            dateFilterable: false,
            width: 1000,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "Assign Paths",
            field: "assign",
            dateFilterable: false,
            width: 200,
            minWidth: 200,
            sortable: false,
            searchable: false,
            customStringify: undefined,
            customSort: undefined
        },
        {
            title: "Manage RTK Service",
            field: "rtk",
            dateFilterable: false,
            width: 200,
            minWidth: 200,
            sortable: false,
            searchable: false,
            customStringify: undefined,
            customSort: undefined
        },
        {
            title: "Edit Truck Info",
            field: "edit",
            dateFilterable: false,
            width: 200,
            minWidth: 200,
            sortable: false,
            searchable: false,
            customStringify: undefined,
            customSort: undefined
        },
    ]

    let rows = props.reconciledNtripData.map(
        (obj, idx) =>
        {
            let rtk_service: string = "Unassigned"
            if(obj.activeRtkServiceIdx !== undefined &&
                obj.activeNtripCredentialIdx !== undefined)
            {
                rtk_service =
                    obj.ntripInfo[obj.activeRtkServiceIdx].rtkService.name + ': ' +
                    obj.ntripInfo[obj.activeRtkServiceIdx].ntripCredentials[
                        obj.activeNtripCredentialIdx].region
            }

            let assign_link = urls.getTrucksAssignPaths(obj.truck.id)
            let assign_icon = (
                <div className='limn-div-row limn-div-centered'>
                    <Link to={assign_link}>
                        <ModeEditIcon/>
                    </Link>
                </div>
            )

            let rtk_link = urls.getTrucksAssignNtrip(obj.truck.id)
            let rtk_icon = (
                <div className='limn-div-row limn-div-centered'>
                    <Link to={rtk_link}>
                        <ModeEditIcon/>
                    </Link>
                </div>
            )

            let edit_link = urls.getTrucksEdit(obj.truck.id)
            let edit_icon = (
                <div className='limn-div-row limn-div-centered'>
                    <Link to={edit_link}>
                        <ModeEditIcon/>
                    </Link>
                </div>
            )

            return {
                key: obj.truck.id,
                customer_name: obj.truck.customer_name,
                limntech_name: obj.truck.limntech_name,
                rtk_service: rtk_service,
                assign: assign_icon,
                rtk: rtk_icon,
                edit: edit_icon,
            }
        }
    )

    return (
        <div>
            <div>
                <BigTable
                    title={"Truck List"}
                    headers={headers}
                    rows={rows}
                    defaultSort={{
                        key: 'column-0',
                        order: 'desc'
                    }}
                    onVisibilityChange={(keys:Array<string>)=>{}}
                    showDateFilter={false}
                />
            </div>
        </div>
    )
}

export default TruckAdminTable
