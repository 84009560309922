import React, { useState, useEffect, useCallback } from 'react'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { ProcessedPathInfo } from 'web_common/tsx/api/db/Models'
import BigTable, { HeaderDescription }  from 'web_common/tsx/components/misc/BigTable'
import { util } from 'web_common/tsx/components/misc/Util'

interface AssignPathTableProps {
    truckId: string,
    processedPathList: Array<ProcessedPathInfo>,
    assignmentList: Array<boolean>,
    onRequestPending: () => void,
    onRequestSuccess: () => void,
    onRequestFailure: () => void,
}

/*
 * To make this work with the checkboxes and visbility changes we must consider
 * a few things,
 *
 * First, the rows and headers must be state variables because if they are
 * generated on every render that will cause the big table to rerender
 *
 * Next, the checkboxes must be dynamically checked / unchecked in an effect
 * to match the archived path list.
 *
 * Making a mistake here may cause infinite rerenders
 */
const AssignPathTable: React.FC<AssignPathTableProps> = (props) => {
    const [visiblePaths, setVisiblePaths] = useState<Array<string>>([])
    const [assignmentList, setAssignmentList] = useState<Array<boolean>>(props.assignmentList)

    const onSelectAll = (): void =>
    {
        setAssignmentList(
            props.processedPathList.map(
                (obj, idx) =>
            {
                let fcn = (e: string) => {
                    return e === obj.id
                }

                if(visiblePaths.find(fcn) !== undefined)
                {
                    return true
                }
                else
                {
                    return assignmentList[idx]
                }
            })
        )
    }
    const onUnselectAll = (): void =>
    {
        setAssignmentList(
            props.processedPathList.map(
                (obj, idx) =>
            {
                let fcn = (e: string) => {
                    return e === obj.id
                }

                if(visiblePaths.find(fcn) !== undefined)
                {
                    return false
                }
                else
                {
                    return assignmentList[idx]
                }
            })
        )
    }
    const onAssign = (): void =>
    {
        let assignedTruckList: Array<string> = props.processedPathList.filter(
            (obj, idx) =>
        {
            return assignmentList[idx] === true
        }).map(
            (obj, idx) =>
        {
            return obj.id
        })

        const onResponse = (success: boolean) =>
        {
            if(success === true)
            {
                props.onRequestSuccess()
            }
            else
            {
                props.onRequestFailure()
            }
        }

        props.onRequestPending()
        db.patch_truck_paths(
            props.truckId,
            assignedTruckList,
            onResponse)
    }

    const onCheck = useCallback((e: React.ChangeEvent<HTMLInputElement>): void =>
    {
        const { checked, name } = e.target

        let updatedList: Array<boolean> = [...assignmentList]
        updatedList[parseInt(name)] = checked
        setAssignmentList(updatedList)
    }, [assignmentList])

    const generateRows = useCallback((): Array<any> =>
    {
        return props.processedPathList.map((obj, idx) =>
        {
            let select_box = (
                <div className='limn-div-row limn-div-centered'>
                    <input
                        type="checkbox"
                        key={idx}
                        name={idx.toString()}
                        checked={assignmentList[idx]}
                        onChange={onCheck}
                    />
                </div>
            )

            return {
                key: obj.id,
                path_name: obj.path_name,
                path_description: obj.path_description,
                date_created: obj.date_created,
                assign: select_box
            }
        })
    }, [assignmentList, onCheck, props.processedPathList])

    const [headers] = useState<Array<HeaderDescription>>(
        [
            {
                title: "Path Name",
                field: "path_name",
                dateFilterable: false,
                width: 2000,
                minWidth: 300,
                sortable: true,
                searchable: true,
                customStringify: undefined,
                customSort: (a: string, b: string) => {
                    return a.localeCompare(b)
                }
            },
            {
                title: "Path Description",
                field: "path_description",
                dateFilterable: false,
                width: 2000,
                minWidth: 300,
                sortable: true,
                searchable: true,
                customStringify: undefined,
                customSort: (a: string, b: string) => {
                    return a.localeCompare(b)
                }
            },
            {
                title: "Date Created",
                field: "date_created",
                dateFilterable: true,
                width: 1000,
                minWidth: 300,
                sortable: true,
                searchable: false,
                customStringify: (d: Date) => {
                    return util.formatDateString(d)
                },
                customSort: (a: Date, b: Date) => {
                    return b.getTime() - a.getTime()
                },
            },
            {
                title: "Assign",
                field: "assign",
                dateFilterable: false,
                width: 150,
                minWidth: 150,
                sortable: false,
                searchable: false,
                customStringify: undefined,
                customSort: undefined
            },
        ]
    )
    const [rows, setRows] = useState<Array<any>>(generateRows())

    useEffect(() => {
        setRows(generateRows())
    }, [assignmentList, generateRows])

    return (
        <div>
            <div>
                <BigTable
                    title={"Assign Path List"}
                    headers={headers}
                    rows={rows}
                    defaultSort={{
                        key: 'column-2',
                        order: 'desc'
                    }}
                    onVisibilityChange={setVisiblePaths}
                    showDateFilter={true}
                />
            </div>
            <hr className='limn-form-separator-100'/>
            <div className='limn-div-wrapper limn-padded-vert-10px'>
                <button
                    onClick={onAssign}
                    className='limn-button-padded-5 limn-button'>
                    Assign Paths
                </button>
                <span className='limn-whitespace' />
                <button
                    onClick={onSelectAll}
                    className='limn-button-padded-5 limn-button'>
                    Select All
                </button>
                <button
                    onClick={onUnselectAll}
                    className='limn-button-padded-5 limn-button'>
                    Unselect All
                </button>
            </div>
        </div>
    )
}

export default AssignPathTable
