import React, { useState } from 'react'

export interface TruckEditFormSubmitData {
    name: string,
}

interface TruckEditFormProps {
    name: string,
    submitButtonName: string,
    onSubmit: (obj: TruckEditFormSubmitData) => void
}

const TruckEditForm: React.FC<TruckEditFormProps> = (props) => {
    const [name, setName] = useState<string>(props.name)

    const onSubmit = (e: React.FormEvent) =>
    {
        e.preventDefault()

        props.onSubmit({
            name: name,
        })
    }

    return (
        <div className='limn-padded-top-50px'>
            <form onSubmit={onSubmit}>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='name'> Truck Name: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='name'
                        name='name'
                        placeholder={'You must enter a truck name...'}
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                        required
                    />
                </div>
                <div className='limn-width-50 limn-margin-25 limn-div-centered'>
                    <button
                        className='limn-button limn-width-90 limn-padded-vert-10px'
                        type='submit'>
                        { props.submitButtonName }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default TruckEditForm
