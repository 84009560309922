import React, { useState, useEffect } from 'react'

import { Navigate, useParams } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { CompanyAdminPrivilege, LifemarkUserModel, Truck, ProcessedPathDetail, PathPreview, AuthToken, Company } from 'web_common/tsx/api/db/Models'
import { PatternTransitionResponse, EditTransitionsResponse, service_api } from 'web_common/tsx/api/service/Api'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import { urls } from 'InternalUrls'
import PathForm, { PathFormSubmitObject } from 'components/paths/PathForm'

interface ActivePathEditProps {

}

const ActivePathEdit: React.FC<ActivePathEditProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [showLoading, setShowLoading]  = useState<boolean>(false)
    const [editSuccess, setEditSuccess] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [company, setCompany] = useState<Company|undefined>(undefined)
    const [trucks, setTrucks] = useState<Array<Truck>|undefined>(undefined)
    const [activePath, setActivePath] = useState<ProcessedPathDetail|undefined>(undefined)
    const [previewPath, setPreviewPath] = useState<PathPreview|undefined>(undefined)
    const [userToken, setUserToken] = useState<AuthToken|undefined>(undefined)
    const [patternTransitions, setPatternTransitions] = useState<PatternTransitionResponse|undefined>(undefined)

    const { id } = useParams()

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() =>{
        if(lifemarkUser !== undefined)
        {
            const onTrucks = (response: Array<Truck>|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setTrucks(response)
                }
            }

            db.truck_list(lifemarkUser.company, onTrucks)
        }
    }, [lifemarkUser])

    useEffect(() => {
        if(lifemarkUser !== undefined)
        {
            const onCompany = (response: Company|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setCompany(response)
                }
            }

            db.company_detail(lifemarkUser.company, onCompany)
        }
    }, [lifemarkUser])

    useEffect(() => {
        if(id !== undefined)
        {
            const onActivePath = (response: ProcessedPathDetail|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setActivePath(response)
                }
            }

            db.processed_path_detail(id, onActivePath)
        }
    }, [id])

    useEffect(() => {
        if(activePath !== undefined)
        {
            const onPreview = (response: PathPreview|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setPreviewPath(response)
                }
            }

            db.preview_processed_path(
                activePath.preview_url,
                onPreview)
        }
    }, [activePath])

    useEffect(() => {
        if(lifemarkUser !== undefined)
        {
            let onResponse = (response: AuthToken|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setUserToken(response)
                }
            }

            db.auth_token(onResponse)
        }
    }, [lifemarkUser])

    useEffect(() => {
        if(activePath !== undefined && userToken !== undefined && company !== undefined)
        {
            const onTransition = (response: PatternTransitionResponse) =>
            {
                if(response.error !== undefined)
                {
                    console.log(response.error)
                    setApiError(true)
                }
                else
                {
                    setPatternTransitions(response)
                }
            }

            /*
             * To avoid continuously worrying about the lambda invoke, lets
             * selectively invoke the lambda
             */
            if(company.pattern_coding_unlock === true)
            {
                service_api.preview_transitions(
                    userToken.token,
                    activePath.id,
                    onTransition)
            }
            else
            {
                setPatternTransitions({
                    transitions: [],
                    reference_path: undefined,
                    version: undefined,
                    error: undefined
                })
            }
        }
    }, [activePath, userToken, company])

    const editActivePath = (obj: PathFormSubmitObject) =>
    {
        let truckList: Array<string> = []
        if(obj.truckList !== undefined)
        {
            truckList = obj.truckList.filter(
                (obj) =>
            {
                return obj.assignment === true
            }).map(
                (obj, idx) =>
            {
                return obj.truck.id
            })
        }

        const onCompletion = (results: boolean) =>
        {
            if(results === false)
            {
                setShowLoading(false)
                setApiError(true)
            }
            else
            {
                setShowLoading(false)
                setEditSuccess(true)
            }
        }

        if(activePath !== undefined)
        {
            if(obj.transitionChanges !== undefined && patternTransitions !== undefined && patternTransitions.version !== undefined && userToken !== undefined)
            {
                const onPatternTransitionEdit = (response: EditTransitionsResponse) => {
                    if(response.success === true)
                    {
                        db.patch_processed_path(
                            activePath.id,
                            obj.pathName,
                            obj.pathDescription,
                            truckList,
                            false,
                            onCompletion)
                    }
                    else
                    {
                        setApiError(true)
                    }
                }

                setShowLoading(true)
                service_api.edit_transitions(
                    userToken.token,
                    activePath.id,
                    obj.transitionChanges,
                    patternTransitions.version,
                    onPatternTransitionEdit)
            }
            else
            {
                db.patch_processed_path(
                    activePath.id,
                    obj.pathName,
                    obj.pathDescription,
                    truckList,
                    false,
                    onCompletion)
            }
        }
    }

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(editSuccess === true)
    {
        return (
            <Navigate
                to={urls.getActivePaths()}
            />
        )
    }
    else if(showLoading === true)
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Edit Active Path </h1>
                <Loading/>
            </div>
        )
    }
    else if(lifemarkUser !== undefined)
    {
        if(lifemarkUser.privilege === CompanyAdminPrivilege.NONE)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(lifemarkUser.privilege === CompanyAdminPrivilege.USER)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(lifemarkUser.privilege === CompanyAdminPrivilege.ADMIN)
        {
            if(trucks !== undefined && activePath !== undefined && previewPath !== undefined && patternTransitions !== undefined)
            {
                let truckList = trucks.map(
                    (obj, idx) =>
                {
                    let assigned: boolean = activePath.assigned_truck_list.findIndex(
                        (truck_id) =>
                    {
                        return obj.id === truck_id
                    }) >= 0

                    return {
                        truck: obj,
                        assignment: assigned
                    }
                })

                return (
                    <div className='limn-form-container'>
                        <h1 className='limn-header'> Edit Active Path </h1>
                        <PathForm
                            truckList={truckList}
                            pathPreview={previewPath}
                            patternTransitions={patternTransitions.transitions}
                            referencePath={patternTransitions.reference_path}
                            pathName={activePath.path_name}
                            pathDescription={activePath.path_description}
                            submitButtonName={'Save Active Path'}
                            onSubmit={editActivePath}
                        />
                    </div>
                )
            }
            else
            {
                return (
                    <div className='limn-form-container'>
                        <h1 className='limn-header'> Edit Active Path </h1>
                        <Loading/>
                    </div>
                )
            }
        }
        else
        {
           return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Edit Active Path </h1>
                    <Loading/>
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Edit Active Path </h1>
                <Loading/>
            </div>
        )
    }
}

export default ActivePathEdit
