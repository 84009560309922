import moment from 'moment'

const formatDateString = (d: Date) =>
{
    return moment(d).format('MMMM Do YYYY - hh:mm:ss A').toString()
}

export const util = {
    formatDateString
}
