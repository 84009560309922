import React, { useState, useEffect, useCallback } from 'react'

import { Navigate, useParams } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import {
    CompanyAdminPrivilege,
    LifemarkUserModel,
    Truck,
} from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import { urls } from 'InternalUrls'
import TruckEditForm, { TruckEditFormSubmitData } from 'components/trucks/edit/TruckEditForm'

interface EditTruckInfoProps {

}

const EditTruckInfo: React.FC<EditTruckInfoProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [truck, setTruck] = useState<Truck|undefined>(undefined)

    const { id } = useParams()

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])
    useEffect(() => {
        if(id !== undefined)
        {
            const onTruck = (response: Truck|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setTruck(response)
                }
            }

            db.get_truck(id, onTruck)
        }
    }, [id])

    const onSubmit = useCallback((obj: TruckEditFormSubmitData) =>
    {
        if(id !== undefined)
        {
            const onResponse = (success: boolean) =>
            {
                if(success === false)
                {
                    setShowLoading(false)
                    setApiError(true)
                }
                else
                {
                    setShowLoading(false)
                    setSuccess(true)
                }
            }

            setShowLoading(true)
            db.patch_truck(
                id,
                obj.name,
                onResponse)
        }
    }, [setSuccess, setApiError, setShowLoading, id])

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(showLoading === true)
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Edit Truck Info </h1>
                <Loading/>
            </div>
        )
    }
    else if(success === true)
    {
        return (
            <Navigate
                to={urls.getTrucks()}
            />
        )
    }
    else if(lifemarkUser !== undefined && truck !== undefined)
    {
        if(lifemarkUser.privilege !== CompanyAdminPrivilege.ADMIN)
        {
            return (
                <PermissionDenied/>
            )
        }
        else
        {
            return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Edit Truck Info </h1>
                    <TruckEditForm
                        name={truck.customer_name}
                        onSubmit={onSubmit}
                        submitButtonName={'Edit Truck'}
                    />
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Edit Truck Info </h1>
                <Loading/>
            </div>
        )
    }
}

export default EditTruckInfo
