import React, { useState, useCallback } from 'react'

import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import PublicIcon from '@material-ui/icons/Public'
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { LifemarkUserModel } from 'web_common/tsx/api/db/Models'
import { ReconciledNtripInfo } from 'web_common/tsx/api/db/Util'

import { urls } from 'InternalUrls'

interface NtripButtonsProps {
    onSave: () => void,
    onCreate: () => void,
    user: LifemarkUserModel
}

const NtripButtons: React.FC<NtripButtonsProps> = (props) => {
    if(props.user.company_admin_superuser === true)
    {
        return (
            <div className='limn-div-centered limn-div-row limn-width-90'>
                <button
                    onClick={props.onCreate}
                    className='limn-button limn-width-30 limn-button-padded-5'
                >
                    Add Ntrip Credentials
                </button>
                <button
                    onClick={props.onSave}
                    className='limn-button limn-width-30 limn-button-padded-5'
                >
                    Save Ntrip Credentials
                </button>
            </div>
        )
    }
    else
    {
        return (
            <div className='limn-div-centered limn-width-90'>
                <button
                    onClick={props.onSave}
                    className='limn-button limn-width-50'
                >
                    Save Ntrip Credentials
                </button>
            </div>
        )
    }
}

interface NtripTableProps {
    ntripInfo: ReconciledNtripInfo,
    user: LifemarkUserModel,
    onSubmit: (rtk: number|undefined, ntrip: number|undefined) => void,
    onCreate: () => void
}

const NtripTable: React.FC<NtripTableProps> = (props) => {
    const { user } = props

    const [selectedServiceIdx, setSelectedServiceIdx] = useState<number|undefined>(props.ntripInfo.activeRtkServiceIdx)
    const [selectedNtripCredentials, setSelectedNtripCredentials] = useState<Array<number>>(props.ntripInfo.ntripInfo.map(
        (obj, idx) =>
    {
        if(idx === props.ntripInfo.activeRtkServiceIdx && props.ntripInfo.activeNtripCredentialIdx)
        {
            return props.ntripInfo.activeNtripCredentialIdx
        }
        else
        {
            return 0
        }
    }))

    const handleRtkServiceChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        setSelectedServiceIdx(parseInt(e.target.value))
    }
    const handleNtripCredentialChange = (rtkIdx: number, e: React.ChangeEvent<HTMLSelectElement>) =>
    {
        const selectedCredentials: Array<number> = [...selectedNtripCredentials]
        selectedCredentials[rtkIdx] = parseInt(e.target.value)

        setSelectedNtripCredentials(selectedCredentials)
    }

    const onSave = () =>
    {
        if(selectedServiceIdx !== undefined)
        {
            props.onSubmit(selectedServiceIdx, selectedNtripCredentials[selectedServiceIdx])
        }
        else
        {
            props.onSubmit(undefined, undefined)
        }
    }

    const rows = props.ntripInfo.ntripInfo.map(
        (obj, idx) =>
    {
        let superuser_row = <></>
        if(user.company_admin_superuser === true)
        {
            let ntrip_id = props.ntripInfo.ntripInfo[idx].ntripCredentials[
                selectedNtripCredentials[idx]].id
            let edit_link = urls.getTrucksNtripEdit(props.ntripInfo.truck.id, ntrip_id)

            superuser_row = (
                <td>
                    <Link to={edit_link}>
                        <ModeEditIcon/>
                    </Link>
                </td>
            )
        }

        return (
            <tr key={idx}>
                <td>
                    { obj.rtkService.name }
                </td>
                <td>
                    <select
                        className='limn-select-box-mini'
                        value={selectedNtripCredentials[idx]}
                        onChange={(e)=>{handleNtripCredentialChange(idx, e)}}
                    >
                        {
                            obj.ntripCredentials.map(
                                (ntrip, ntrip_idx) =>
                            {
                                return (
                                    <option
                                        key={ntrip_idx}
                                        value={ntrip_idx}>
                                        { ntrip.region }
                                    </option>
                                )
                            })
                        }
                    </select>
                </td>
                <td>
                    <PublicIcon
                        className='limn-link'
                        onClick={() => {window.open(obj.rtkService.map_url, '_blank', 'noopener noreferrer')}}/>
                </td>
                <td>
                    <input
                        type='radio'
                        name='radio-group'
                        value={idx}
                        checked={idx === selectedServiceIdx}
                        onChange={handleRtkServiceChange}
                    />
                </td>
                { superuser_row }
            </tr>
        )
    })

    const generateSuperuserHeader = useCallback(() => {
        if(user.company_admin_superuser)
        {
            return (
                <td><b> Edit </b></td>
            )
        }
        else
        {
            return <></>
        }
    }, [user])

    return (
        <div className='limn-added-top-50px limn-margin-10 limn-margin-right-10'>
            <h3> Ntrip Selection List </h3>
            <Table hover className='limn-bigfont'>
                <thead>
                    <tr>
                        <td><b> Rtk Service </b></td>
                        <td><b> Region </b></td>
                        <td><b> Coverage Map </b></td>
                        <td><b> Active </b></td>
                        { generateSuperuserHeader() }
                    </tr>
                </thead>
                <tbody>
                    { rows }
                </tbody>
            </Table>
            <NtripButtons
                onSave={onSave}
                onCreate={props.onCreate}
                user={props.user}
            />
        </div>
    )
}

export default NtripTable
