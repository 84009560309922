import { requests } from 'web_common/tsx/api/Request'
import {
    CompanyAdminPrivilege,
    LifemarkUserModel,
    Company,
    NtripCredential,
    PathPreview,
    ProcessedPathInfo,
    ProcessedPathExportInfo,
    ProcessedPathSample,
    ProcessedPathDetail,
    ProposedPathDetail,
    Truck,
    RtkService,
    ImportedPath,
    AuthToken,
    HighResolutionPathData,
 } from 'web_common/tsx/api/db/Models'
import { util } from 'web_common/tsx/api/db/Util'

import moment from 'moment'

const BASE_URL = process.env.REACT_APP_DATABASE_URL

/*
 * **********************************
 *          Authentication
 * **********************************
 */
const login = (
    username: string,
    password: string,
    onCompletion: (success: boolean) => void): void =>
{
    let postData = {
        username: username,
        password: password
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 202)
        {
            onCompletion(true)
        }
        else
        {
            console.log("Login failed. Unexpected HTTP response status")
            onCompletion(false)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(false)
    }

    requests.post(
        BASE_URL + 'session_login/',
        postData,
        true,
        onResponse,
        onError)
}

const logout = (
    onCompletion: (success: boolean) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(true)
        }
        else
        {
            console.log("Login failed. Unexpected HTTP response status")
            onCompletion(false)
        }
    }

    let onError = (error: any) =>
    {
        console.log("Logout failed. Unexpected HTTP response status")
        onCompletion(false)
    }

    requests.get(
        BASE_URL + 'session_logout/',
        true,
        onResponse,
        onError)
}

const change_password = (
    oldPassword: string,
    newPassword1: string,
    newPassword2: string,
    onCompletion: (success: boolean, error: Array<string>) => void): void =>
{
    let postData = {
        old_password: oldPassword,
        new_password1: newPassword1,
        new_password2: newPassword2
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(true, [])
        }
        else
        {
            console.log("Login failed. Unexpected HTTP response status")
            onCompletion(false, ['Failed changing password'])
        }
    }

    let onError = (error: any) =>
    {
        let errorMsg: Array<string> = []
        console.log(error)

        if(error.response && error.response.data)
        {
            if(error.response.data.old_password)
            {
                let old_password_errors: Array<string> = error.response.data.old_password
                errorMsg = errorMsg.concat(old_password_errors)
            }

            if(error.response.data.new_password1)
            {
                let errors: Array<string> = error.response.data.new_password1
                errorMsg = errorMsg.concat(errors)
            }

            if(error.response.data.new_password2)
            {
                let errors: Array<string> = error.response.data.new_password2
                errorMsg = errorMsg.concat(errors)
            }
        }

        onCompletion(false, errorMsg)
    }

    requests.post(
        BASE_URL + 'rest-auth/password/change/',
        postData,
        true,
        onResponse,
        onError)

}

const reset_password = (
    email: string,
    onCompletion: (success: boolean) => void): void =>
{
    let postObj = {
        email: email
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(true)
        }
        else
        {
            console.log("Password reset failed, unexpected http response")
            onCompletion(false)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(false)
    }

    requests.post(
        BASE_URL + 'rest-auth/password/reset/',
        postObj,
        true,
        onResponse,
        onError)
}

const reset_password_confirm = (
    pwd1: string,
    pwd2: string,
    uid: string,
    token: string,
    onCompletion: (success: boolean, error: Array<string>) => void): void =>
{
    let postData = {
        new_password1: pwd1,
        new_password2: pwd2,
        uid: uid,
        token: token
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(true, [])
        }
        else
        {
            console.log("Login failed. Unexpected HTTP response status")
            onCompletion(false, ['Failed changing password'])
        }
    }

    let onError = (error: any) =>
    {
        let errorMsg: Array<string> = []
        console.log(error)

        if(error.response && error.response.data)
        {
            if(error.response.data.uid)
            {
                errorMsg = errorMsg.concat(['Invalid parameter: uidb64'])
            }

            if(error.response.data.token)
            {
                errorMsg = errorMsg.concat(['Invalid token'])
            }

            if(error.response.data.new_password1)
            {
                let errors: Array<string> = error.response.data.new_password1
                errorMsg = errorMsg.concat(errors)
            }

            if(error.response.data.new_password2)
            {
                let errors: Array<string> = error.response.data.new_password2
                errorMsg = errorMsg.concat(errors)
            }
        }

        onCompletion(false, errorMsg)
    }

    requests.post(
        BASE_URL + 'rest-auth/password/reset/confirm/',
        postData,
        true,
        onResponse,
        onError)

}

/*
 * **********************************
 *          MISC
 * **********************************
 */
const company_trucks_size = (
    company_id: string,
    onCompletion: (response: number|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(parseInt(data.data.size))
        }
        else
        {
            console.log("Could not get the number of trucks")
            onCompletion(undefined)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        BASE_URL + 'truck/size/' + company_id,
        true,
        onResponse,
        onError)
}

const company_active_path_size = (
    company_id: string,
    onCompletion: (response: number|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(parseInt(data.data.size))
        }
        else
        {
            console.log("Could not get the number of active paths")
            onCompletion(undefined)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        BASE_URL + 'active_path/size/' + company_id,
        true,
        onResponse,
        onError)
}

const company_archived_path_size = (
    company_id: string,
    onCompletion: (response: number|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(parseInt(data.data.size))
        }
        else
        {
            console.log("Could not get the number of archived paths")
            onCompletion(undefined)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        BASE_URL + 'archived_path/size/' + company_id,
        true,
        onResponse,
        onError)
}

const company_proposed_path_size = (
    company_id: string,
    onCompletion: (response: number|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(parseInt(data.data.size))
        }
        else
        {
            console.log("Could not get the number of proposed paths")
            onCompletion(undefined)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        BASE_URL + 'proposed_path/size/' + company_id,
        true,
        onResponse,
        onError)
}

/*
 * **********************************
 *          LifeMark User Model
 * **********************************
 */
const current_user = (
    onCompletion: (response: LifemarkUserModel|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion({
                id: data.data.id,
                company: data.data.company,
                username: data.data.username,
                first_name: data.data.first_name,
                last_name: data.data.last_name,
                email: data.data.email,
                truck: data.data.truck,
                privilege: util.convertCompanyAdminPermission(data.data.privilege),
                company_admin_superuser: data.data.company_admin_superuser,
                solo_user: data.data.solo_user
            })
        }
        else
        {
            console.log("current user request failed, unexpected http response status")
            onCompletion(undefined)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        BASE_URL + 'lifemark_user_model/current_user/',
        true,
        onResponse,
        onError)
}

const list_users = (
    company_id: string|undefined,
    truck_id: string|undefined,
    onCompletion: (response: Array<LifemarkUserModel>|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let src: Array<any> = data.data
            let ret: Array<LifemarkUserModel> = src.map(
                (obj, idx) =>
            {
                return {
                    id: obj.id,
                    company: obj.company,
                    username: obj.username,
                    first_name: obj.first_name,
                    last_name: obj.last_name,
                    email: obj.email,
                    truck: obj.truck,
                    privilege: util.convertCompanyAdminPermission(obj.privilege),
                    company_admin_superuser: obj.company_admin_superuser,
                    solo_user: obj.solo_user
                }
            })

            onCompletion(ret)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    let qs_arr: Array<string> = [
        'is_active=True',
    ]

    if(company_id !== undefined)
    {
        qs_arr.push('company=' + company_id)
    }

    if(truck_id !== undefined)
    {
        qs_arr.push('truck=' + truck_id)
    }

    let qs = util.reconcileQsArray(qs_arr)
    requests.get(
        BASE_URL + "lifemark_user_model/" + qs,
        true,
        onResponse,
        onError)
}

const get_user = (
    user_id: string,
    onCompletion: (response: LifemarkUserModel|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let obj = data.data
            onCompletion({
                id: obj.id,
                company: obj.company,
                username: obj.username,
                first_name: obj.first_name,
                last_name: obj.last_name,
                email: obj.email,
                truck: obj.truck,
                privilege: util.convertCompanyAdminPermission(obj.privilege),
                company_admin_superuser: obj.company_admin_superuser,
                solo_user: obj.solo_user
            })
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        BASE_URL + 'lifemark_user_model/' + user_id + '/',
        true,
        onResponse,
        onError)
}

const patch_user_company = (
    user_id: number,
    company_id: string,
    onCompletion: (success: boolean) => void): void =>
{
    let patchObj = {
        company: company_id,
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(true)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(false)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(false)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(false)
    }

    requests.patch(
        BASE_URL + "lifemark_user_model/" + user_id + "/",
        patchObj,
        true,
        onResponse,
        onError)
}

const patch_user = (
    user_id: string,
    username: string,
    firstname: string,
    lastname: string,
    email: string,
    privilege: CompanyAdminPrivilege,
    onCompletion: (success: boolean, error_message: Array<string>) => void): void =>
{
    let patchObj = {
        username: username,
        first_name: firstname,
        last_name: lastname,
        email: email,
        privilege: privilege
    }

    let onResponse = (data: any) =>
    {
        console.log(data)
        if(data.status === 200)
        {
            onCompletion(true, [])
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(false, ['Permission Denied'])
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(false, ['Unknown Error'])
        }
    }
    let onError = (error: any) =>
    {
        let errorMsg: Array<string> = []
        console.log(error)

        if(error.response && error.response.data)
        {
            if(error.response.data.username)
            {
                let username_errors: Array<string> = error.response.data.username
                errorMsg = errorMsg.concat(username_errors)
            }

            if(error.response.data.first_name)
            {
                let first_name_errors: Array<string> = error.response.data.first_name
                errorMsg = errorMsg.concat(first_name_errors)
            }

            if(error.response.data.last_name)
            {
                let last_name_errors: Array<string> = error.response.data.last_name
                errorMsg = errorMsg.concat(last_name_errors)
            }

            if(error.response.data.email)
            {
                let email_errors: Array<string> = error.response.data.email
                errorMsg = errorMsg.concat(email_errors)
            }
        }

        if(errorMsg.length === 0)
        {
            errorMsg = ['Unknown Error']
        }
        onCompletion(false, errorMsg)
    }

    requests.patch(
        BASE_URL + "lifemark_user_model/" + user_id + "/",
        patchObj,
        true,
        onResponse,
        onError)
}

const delete_user = (
    user_id: string,
    onCompletion: (success: boolean, error_message: Array<string>) => void): void =>
{
    let patchObj = {
        is_active: false,
    }

    let onResponse = (data: any) =>
    {
        console.log(data)
        if(data.status === 200)
        {
            onCompletion(true, [])
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(false, ['Permission Denied'])
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(false, ['Unknown Error'])
        }
    }
    let onError = (error: any) =>
    {
        let errorMsg: Array<string> = []
        console.log(error)

        if(error.response !== undefined && error.response.status === 403)
        {
            errorMsg = ['Your user account does not have permission to delete this user']
        }

        if (errorMsg.length === 0)
        {
            errorMsg = ['Unknown Error']
        }
        onCompletion(false, errorMsg)
    }

    requests.patch(
        BASE_URL + "lifemark_user_model/delete2/" + user_id + "/",
        patchObj,
        true,
        onResponse,
        onError)
}

const create_user = (
    company_id: string,
    username: string,
    firstname: string,
    lastname: string,
    email: string,
    privilege: CompanyAdminPrivilege,
    onCompletion: (success: boolean, error_message: Array<string>) => void): void =>
{
    let postObj = {
        username: username,
        first_name: firstname,
        last_name: lastname,
        email: email,
        company: company_id,
        privilege: privilege
    }

    let onResponse = (data: any) =>
    {
        console.log(data)
        if(data.status === 201)
        {
            onCompletion(true, [])
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(false, ['Permission Denied'])
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(false, ['Unknown Error'])
        }
    }
    let onError = (error: any) =>
    {
        let errorMsg: Array<string> = []
        console.log(error)

        if(error.response && error.response.data)
        {
            if(error.response.data.username)
            {
                let username_errors: Array<string> = error.response.data.username
                errorMsg = errorMsg.concat(username_errors)
            }

            if(error.response.data.first_name)
            {
                let first_name_errors: Array<string> = error.response.data.first_name
                errorMsg = errorMsg.concat(first_name_errors)
            }

            if(error.response.data.last_name)
            {
                let last_name_errors: Array<string> = error.response.data.last_name
                errorMsg = errorMsg.concat(last_name_errors)
            }

            if(error.response.data.email)
            {
                let email_errors: Array<string> = error.response.data.email
                errorMsg = errorMsg.concat(email_errors)
            }
        }

        if(errorMsg.length === 0)
        {
            errorMsg = ['Unknown Error']
        }
        onCompletion(false, errorMsg)
    }

    requests.post(
        BASE_URL + "lifemark_user_model/create/",
        postObj,
        true,
        onResponse,
        onError)
}

/*
 * **********************************
 *         Company
 * **********************************
 */
const company_list = (
    solo_permission: boolean|undefined,
    onCompletion: (response: Array<Company>|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let arr: Array<any> = data.data
            onCompletion(arr.map(
                (obj, idx) =>
            {
                return {
                    id: obj.id,
                    name: obj.name,
                    is_active: obj.is_active,
                    solo_permission: obj.solo_permission,
                    pattern_coding_unlock: obj.pattern_coding_unlock,
                    high_res_data_subscription: obj.high_res_data_subscription,
                    subscription_remaining_dl_milage: obj.subscription_remaining_dl_milage,
                    persistent_remaining_dl_milage: obj.persistent_remaining_dl_milage,
                }
            }))
        }
        else
        {
            console.log("company request failed, unexpected http response status")
            onCompletion(undefined)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    let qs_arr: Array<string> = [
    ]

    if(solo_permission !== undefined)
    {
        qs_arr.push('solo_permission='+solo_permission.toString())
    }

    let qs = util.reconcileQsArray(qs_arr)
    requests.get(
        BASE_URL + 'company/' + qs,
        true,
        onResponse,
        onError)
}

const company_detail = (
    id: string,
    onCompletion: (response: Company|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion({
                id: data.data.id,
                name: data.data.name,
                is_active: data.data.is_active,
                solo_permission: data.data.solo_permission,
                pattern_coding_unlock: data.data.pattern_coding_unlock,
                high_res_data_subscription: data.data.high_res_data_subscription,
                subscription_remaining_dl_milage: data.data.subscription_remaining_dl_milage,
                persistent_remaining_dl_milage: data.data.persistent_remaining_dl_milage,
            })
        }
        else
        {
            console.log("company request failed, unexpected http response status")
            onCompletion(undefined)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        BASE_URL + 'company/' + id + '/',
        true,
        onResponse,
        onError)
}

const high_resolution_path_data_list = (
    path_id: string,
    onCompletion: (response: Array<HighResolutionPathData>|undefined)=>void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let src: Array<any> = data.data
            let ret: Array<HighResolutionPathData> = src.map(
                (obj, idx) =>
            {
                return {
                    id: obj.id,
                }
            })

            onCompletion(ret)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    let qs_arr: Array<string> = [
        'processed_path=' + path_id,
    ]

    let qs = util.reconcileQsArray(qs_arr)
    requests.get(
        BASE_URL + "high_resolution_path_data/" + qs,
        true,
        onResponse,
        onError)
}

const processed_path_list = (
    company_id: string,
    active_flag: boolean|undefined,
    onCompletion: (response: Array<ProcessedPathInfo>|undefined)=>void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let src: Array<any> = data.data
            let ret: Array<ProcessedPathInfo> = src.map(
                (obj, idx) =>
            {
                return {
                    id: obj.id,
                    path_name: obj.path_name,
                    path_description: obj.path_description,
                    date_created: util.convertDateNoOptional(obj.date_created),
                    path_length_meters: obj.path_length_meters,
                }
            })

            onCompletion(ret)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    let qs_arr: Array<string> = [
        'is_deleted=False',
        'company=' + company_id,
    ]

    if(active_flag !== undefined)
    {
        qs_arr.push('is_active='+active_flag.toString())
    }

    let qs = util.reconcileQsArray(qs_arr)
    requests.get(
        BASE_URL + "processed_path/list/info/" + qs,
        true,
        onResponse,
        onError)
}

interface PathIdContainer {
    id: string
}

const processed_path_export_list = (
    company_id: string,
    active_flag: boolean|undefined,
    onCompletion: (response: Array<ProcessedPathExportInfo>|undefined)=>void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let src: Array<any> = data.data
            let ret: Array<ProcessedPathExportInfo> = src.map(
                (obj, idx) =>
            {
                return {
                    id: obj.id,
                    path_name: obj.path_name,
                    path_description: obj.path_description,
                    date_created: util.convertDateNoOptional(obj.date_created),
                    path_length_meters: obj.path_length_meters,
                    exported: obj.exported,
                }
            })

            onCompletion(ret)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    let qs_arr: Array<string> = [
        'is_deleted=False',
        'company=' + company_id,
    ]

    if(active_flag !== undefined)
    {
        qs_arr.push('is_active='+active_flag.toString())
    }

    let qs = util.reconcileQsArray(qs_arr)
    requests.get(
        BASE_URL + "processed_path/list/export_info/" + qs,
        true,
        onResponse,
        onError)
}

const processed_path_id_list = (
    company_id: string,
    truck_id: string|undefined,
    active_flag: boolean|undefined,
    onCompletion: (response: Array<string>|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let ret: Array<string> = data.data.map(
                (obj: PathIdContainer) =>
            {
                return obj.id
            })
            onCompletion(ret)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    let qs_arr: Array<string> = [
        'is_deleted=False',
        'company='+company_id
    ]

    if(active_flag !== undefined)
    {
        qs_arr.push('is_active='+active_flag.toString())
    }
    if(truck_id !== undefined)
    {
        qs_arr.push('assigned_trucks='+truck_id)
    }

    let qs = util.reconcileQsArray(qs_arr)
    requests.get(
        BASE_URL + "processed_path/list/info/min/" + qs,
        true,
        onResponse,
        onError)

}

const processed_path_sample_list = (
    company_id: string,
    active_flag: boolean|undefined,
    start_date: Date|undefined,
    end_date: Date|undefined,
    onCompletion: (response: Array<ProcessedPathSample>|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(data.data.map(
                (obj: any) =>
            {
                return {
                    id: obj.id,
                    preview_url: obj.sampled_path_coords,
                }
            }))
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    let qs_arr: Array<string> = [
        'is_deleted=False',
        'company='+company_id
    ]

    if(active_flag !== undefined)
    {
        qs_arr.push('is_active='+active_flag.toString())
    }

    if(start_date !== undefined)
    {
        let dateStr = moment(start_date).format(
            'YYYY-MM-DD').toString()
        qs_arr.push('date_created_gte='+dateStr)
    }

    if(end_date !== undefined)
    {
        let dateStr = moment(end_date).format(
            'YYYY-MM-DD').toString()
        qs_arr.push('date_created_lte='+dateStr)
    }

    let qs = util.reconcileQsArray(qs_arr)
    requests.get(
        BASE_URL + "processed_path/list/sampled_coordinates/" + qs,
        true,
        onResponse,
        onError)

}

const set_path_activation = (
    path_ids: Array<string>,
    activation: boolean,
    assignedTrucks: Array<string>,
    onCompletion: (success: boolean) => void): void =>
{
    let onResponse = (data: any) =>
    {
        let error: boolean = false
        for(let i = 0; i < data.length; i++)
        {
            if(data[i].status !== 200)
            {
                error = true
            }
        }
        onCompletion(error === false)
    }

    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(false)
    }

    let patchObjs = path_ids.map(
        (obj, idx) =>
    {
        return {
            id: obj,
            is_active: activation,
            assigned_trucks: assignedTrucks,
        }
    })
    let urls: Array<string> = path_ids.map(
        (obj, idx) =>
    {
        return BASE_URL + 'processed_path/' + obj + '/'
    })

    requests.patchAll(
        urls,
        patchObjs,
        true,
        onResponse,
        onError)
}

const processed_path_detail = (
    path_id: string,
    onCompletion: (response: ProcessedPathDetail|undefined)=>void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion({
                id: data.data.id,
                path_name: data.data.path_name,
                path_description: data.data.path_description,
                date_created: util.convertDateNoOptional(data.data.date_created),
                preview_url: data.data.sampled_path_coords,
                assigned_truck_list: data.data.assigned_trucks,
                is_active: data.data.is_active,
                path_length_meters: data.data.path_length_meters,
            })
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        BASE_URL + 'processed_path/' + path_id + '/',
        true,
        onResponse,
        onError)
}

const patch_processed_path = (
    pathId: string,
    pathName: string,
    pathDescription: string,
    trucks: Array<string>,
    isDeleted: boolean,
    onCompletion: (response: boolean) => void): void =>
{
    let postObj = {
        assigned_trucks: trucks,
        path_name: pathName,
        path_description: pathDescription,
        is_deleted: isDeleted
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(true)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(false)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(false)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(false)
    }

    requests.patch(
        BASE_URL + "processed_path/" + pathId + "/",
        postObj,
        true,
        onResponse,
        onError)
}

const preview_processed_path = (
    preview_url: string,
    onCompletion: (response: PathPreview|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            /*
             * Some of the older paths on the server did not have
             * precision associated with it. The default is 6
             */
            if(data.data.precision === undefined)
            {
                Object.assign(data.data, { 'precision': 7 })
            }
            onCompletion(data.data)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission")
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        preview_url,
        false,
        onResponse,
        onError)
}

const proposed_path_id_list = (
    company_id: string,
    truck_id: string|undefined,
    onCompletion: (response: Array<string>|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let ret: Array<string> = data.data.map(
                (obj: PathIdContainer) =>
            {
                return obj.id
            })
            onCompletion(ret)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    let qs_arr: Array<string> = [
        'company='+company_id
    ]

    if(truck_id !== undefined)
    {
        qs_arr.push('assigned_trucks='+truck_id)
    }

    let qs = util.reconcileQsArray(qs_arr)
    requests.get(
        BASE_URL + "proposed_path/list/info/min/" + qs,
        true,
        onResponse,
        onError)
}

const proposed_path_list = (
    company_id: string,
    onCompletion: (response: Array<ProcessedPathInfo>|undefined)=>void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let src: Array<any> = data.data
            let ret: Array<ProcessedPathInfo> = src.map(
                (obj, idx) =>
            {
                return {
                    id: obj.id,
                    path_name: obj.path_name,
                    path_description: obj.path_description,
                    date_created: util.convertDateNoOptional(obj.date_created),
                    path_length_meters: null,
                }
            })

            onCompletion(ret)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    let qs_arr: Array<string> = [
        'company=' + company_id,
    ]

    let qs = util.reconcileQsArray(qs_arr)
    requests.get(
        BASE_URL + "proposed_path/list/info/" + qs,
        true,
        onResponse,
        onError)
}

const proposed_path_detail = (
    id: string,
    onCompletion: (response: ProposedPathDetail|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let obj = data.data
            onCompletion({
                    id: obj.id,
                    path_name: obj.path_name,
                    path_description: obj.path_description,
                    date_created: util.convertDateNoOptional(obj.date_created),
                    assigned_truck_list: obj.assigned_trucks,
                    company: obj.company,
            })
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        BASE_URL + 'proposed_path/' + id + '/',
        true,
        onResponse,
        onError)
}

const delete_proposed_path = (
    id: string,
    onCompletion: (success: boolean) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 204)
        {
            onCompletion(true)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(false)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(false)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(false)
    }

    requests.deleteReq(
        BASE_URL + 'proposed_path/' + id + '/',
        true,
        onResponse,
        onError)
}


const create_proposed_path = (
    pathName: string,
    pathDescription: string,
    companyId: string,
    trucks: Array<string>,
    onCompletion: (response: boolean) => void): void =>
{
    let postObj = {
        assigned_trucks: trucks,
        company: companyId,
        path_name: pathName,
        path_description: pathDescription,
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 201)
        {
            onCompletion(true)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(false)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(false)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(false)
    }

    requests.post(
        BASE_URL + "proposed_path/",
        postObj,
        true,
        onResponse,
        onError)

}

const patch_proposed_path = (
    pathId: string,
    pathName: string,
    pathDescription: string,
    trucks: Array<string>,
    onCompletion: (response: boolean) => void): void =>
{
    let postObj = {
        assigned_trucks: trucks,
        path_name: pathName,
        path_description: pathDescription,
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(true)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(false)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(false)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(false)
    }

    requests.patch(
        BASE_URL + "proposed_path/" + pathId + "/",
        postObj,
        true,
        onResponse,
        onError)

}


const truck_list = (
    company_id: string|undefined,
    onCompletion: (response: Array<Truck>|undefined)=>void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let src: Array<any> = data.data
            let ret: Array<Truck> = src.map(
                (obj, idx) =>
            {
                return {
                    id: obj.id,
                    customer_name: obj.customer_name,
                    limntech_name: obj.limntech_name,
                    truck_type: obj.truck_type,
                    is_active: obj.is_active,
                    configuration_update_channel: obj.configuration_update_channel,
                    lifemark_app_update_channel: obj.lifemark_app_update_channel,
                    lm75_update_channel: obj.lm75_update_channel,
                    company: obj.company
                }
            })

            onCompletion(ret)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    let qs_arr: Array<string> = [
        'is_active=true',
    ]

    if(company_id !== undefined)
    {
        qs_arr.push('company='+company_id)
    }

    let qs = util.reconcileQsArray(qs_arr)
    requests.get(
        BASE_URL + "truck/" + qs,
        true,
        onResponse,
        onError)
}

const get_truck = (
    id: string,
    onCompletion: (response: Truck|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let obj = data.data
            onCompletion({
                id: obj.id,
                customer_name: obj.customer_name,
                limntech_name: obj.limntech_name,
                truck_type: obj.truck_type,
                is_active: obj.is_active,
                configuration_update_channel: obj.configuration_update_channel,
                lifemark_app_update_channel: obj.lifemark_app_update_channel,
                lm75_update_channel: obj.lm75_update_channel,
                company: obj.company
            })
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        BASE_URL + "truck/" + id + "/",
        true,
        onResponse,
        onError)
}

const patch_truck = (
    id: string,
    customer_name: string,
    onCompletion: (success: boolean) => void): void =>
{
    let patchObj = {
        customer_name: customer_name
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(true)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(false)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(false)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(false)
    }

    requests.patch(
        BASE_URL + "truck/" + id + "/",
        patchObj,
        true,
        onResponse,
        onError)
}

const patch_truck_paths = (
    truck_id: string,
    path_list: Array<string>,
    onCompletion: (success: boolean) => void): void =>
{
    let patchObj = {
        'processedpath_set': path_list,
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(true)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(false)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(false)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(false)
    }

    requests.patch(
        BASE_URL + "truck/" + truck_id + "/",
        patchObj,
        true,
        onResponse,
        onError)
}

const patch_truck_admin = (
    truck_id: string,
    proc_path_list: Array<string>,
    prop_path_list: Array<string>,
    onCompletion: (success: boolean) => void): void =>
{
    let patchObj = {
        'processedpath_set': proc_path_list,
        'proposedpath_set': prop_path_list,
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(true)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(false)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(false)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(false)
    }

    requests.patch(
        BASE_URL + "truck/" + truck_id + "/",
        patchObj,
        true,
        onResponse,
        onError)

}

const create_ntrip_credential = (
    truck_id: string,
    rtk_service_id: string,
    username: string,
    password: string,
    host: string,
    port: number,
    stream: string,
    is_active: boolean,
    region: string,
    onCompletion: (response: NtripCredential|undefined) => void): void =>
{
    let postObj = {
        truck: truck_id,
        rtk_service: rtk_service_id,
        username: username,
        password: password,
        host: host,
        port: port,
        stream: stream,
        is_active: is_active,
        region: region
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 201)
        {
            let obj: any = data.data
            onCompletion({
                id: obj.id,
                truck: obj.truck,
                rtk_service: obj.rtk_service,
                username: obj.username,
                password: obj.password,
                host: obj.host,
                port: obj.port,
                stream: obj.stream,
                is_active: obj.is_active,
                region: obj.region
            })
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.post(
        BASE_URL + "ntrip_credentials/",
        postObj,
        true,
        onResponse,
        onError)

}

const patch_ntrip_credential = (
    id: string,
    rtk_service_id: string,
    username: string,
    password: string,
    host: string,
    port: number,
    stream: string,
    region: string,
    onCompletion: (response: NtripCredential|undefined) => void): void =>
{
    let patchObj = {
        rtk_service: rtk_service_id,
        username: username,
        password: password,
        host: host,
        port: port,
        stream: stream,
        region: region
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let obj: any = data.data
            onCompletion({
                id: obj.id,
                truck: obj.truck,
                rtk_service: obj.rtk_service,
                username: obj.username,
                password: obj.password,
                host: obj.host,
                port: obj.port,
                stream: obj.stream,
                is_active: obj.is_active,
                region: obj.region
            })
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.patch(
        BASE_URL + "ntrip_credentials/" + id + "/",
        patchObj,
        true,
        onResponse,
        onError)
}

const list_ntrip_credentials = (
    company_id: string,
    truck_id: string|undefined,
    onCompletion: (response: Array<NtripCredential>|undefined)=>void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let src: Array<any> = data.data
            let ret: Array<NtripCredential> = src.map(
                (obj, idx) =>
            {
                return {
                    id: obj.id,
                    truck: obj.truck,
                    rtk_service: obj.rtk_service,
                    username: obj.username,
                    password: obj.password,
                    host: obj.host,
                    port: obj.port,
                    stream: obj.stream,
                    is_active: obj.is_active,
                    region: obj.region
                }
            })

            onCompletion(ret)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    let qs_arr: Array<string> = [
        'company=' + company_id,
    ]

    if(truck_id !== undefined)
    {
        qs_arr.push('truck='+truck_id)
    }

    let qs = util.reconcileQsArray(qs_arr)

    requests.get(
        BASE_URL + "ntrip_credentials/" + qs,
        true,
        onResponse,
        onError)
}

const set_ntrip_active_status = (
    ntrip_id: string,
    is_active: boolean,
    onCompletion: (success: boolean)=>void): void =>
{
    let patchObj = {
        is_active: is_active
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion(true)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(false)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(false)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(false)
    }

    requests.patch(
        BASE_URL + "ntrip_credentials/" + ntrip_id + "/",
        patchObj,
        true,
        onResponse,
        onError)
}

const get_ntrip_credential = (
    id: string,
    onCompletion: (response: NtripCredential|undefined)=>void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let obj: any = data.data
            onCompletion({
                id: obj.id,
                truck: obj.truck,
                rtk_service: obj.rtk_service,
                username: obj.username,
                password: obj.password,
                host: obj.host,
                port: obj.port,
                stream: obj.stream,
                is_active: obj.is_active,
                region: obj.region
            })
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        BASE_URL + "ntrip_credentials/" + id + "/",
        true,
        onResponse,
        onError)
}

const create_rtk_service = (
    name: string,
    url: string,
    onCompletion: (response: RtkService|undefined) => void): void =>
{
    let postObj = {
        map_url: url,
        name: name
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 201)
        {
            let obj: any = data.data
            onCompletion({
                id: obj.id,
                name: obj.name,
                map_url: obj.map_url
            })
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.post(
        BASE_URL + "rtk_service/",
        postObj,
        true,
        onResponse,
        onError)

}

const list_rtk_services = (
    company_id: string|undefined,
    onCompletion: (response: Array<RtkService>|undefined)=>void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let src: Array<any> = data.data
            let ret: Array<RtkService> = src.map(
                (obj, idx) =>
            {
                return {
                    id: obj.id,
                    name: obj.name,
                    map_url: obj.map_url
                }
            })

            onCompletion(ret)
        }
        else if(data.status === 403)
        {
            console.log("User does not have permission");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    let qs_arr: Array<string> = []

    if(company_id !== undefined)
    {
        qs_arr.push('company='+ company_id)
    }

    let qs = util.reconcileQsArray(qs_arr)
    requests.get(
        BASE_URL + "rtk_service/" + qs,
        true,
        onResponse,
        onError)
}

const import_path = (
    path_name: string,
    path_description: string,
    company_id: string,
    upload_file: File|Blob,
    upload_filename: string,
    onCompletion: (response: ImportedPath|undefined) => void): void =>
{
    let formData = new FormData()
    formData.append("path_name", path_name)
    formData.append("path_description", path_description)
    formData.append("company", company_id)
    formData.append("imported_path", upload_file, upload_filename)

    let onResponse = (data: any) =>
    {
        if(data.status === 201)
        {
            onCompletion({
                id: data.data.id,
                path_name: data.data.path_name,
                path_description: data.data.path_description
            })
        }
        else if(data.status === 403)
        {
            console.log("User not logged in");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.postMultipart(
        BASE_URL + 'imported_path/',
        formData,
        true,
        onResponse,
        onError)
}

const imported_path_detail = (
    id: string,
    onCompletion: (response: ImportedPath|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            let obj = data.data
            onCompletion({
                id: obj.id,
                path_name: obj.path_name,
                path_description: obj.path_description,
            })
        }
        else if(data.status === 403)
        {
            console.log("User not logged in");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        BASE_URL + "imported_path/"+id+"/",
        true,
        onResponse,
        onError)

}

const auth_token = (
    onCompletion: (response: AuthToken|undefined) => void): void =>
{
    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            onCompletion({
                token: data.data.user_token
            })
        }
        else if(data.status === 403)
        {
            console.log("User not logged in");
            onCompletion(undefined)
        }
        else
        {
            console.log("Unexpected HTTP response status")
            onCompletion(undefined)
        }
    }
    let onError = (error: any) =>
    {
        console.log(error)
        onCompletion(undefined)
    }

    requests.get(
        BASE_URL + "lifemark_user_model/get_user_token/",
        true,
        onResponse,
        onError)
}


export const db =
{
    login,
    logout,
    reset_password,
    reset_password_confirm,
    change_password,

    company_trucks_size,
    company_active_path_size,
    company_archived_path_size,
    company_proposed_path_size,

    current_user,
    list_users,
    get_user,
    patch_user_company,
    patch_user,
    delete_user,
    create_user,

    company_list,
    company_detail,

    preview_processed_path,
    high_resolution_path_data_list,
    processed_path_list,
    processed_path_export_list,
    processed_path_id_list,
    processed_path_detail,
    processed_path_sample_list,
    patch_processed_path,
    proposed_path_id_list,
    proposed_path_list,
    create_proposed_path,
    patch_proposed_path,
    proposed_path_detail,
    delete_proposed_path,
    set_path_activation,

    truck_list,
    get_truck,
    patch_truck,
    patch_truck_admin,
    patch_truck_paths,

    create_rtk_service,
    list_rtk_services,
    create_ntrip_credential,
    patch_ntrip_credential,
    list_ntrip_credentials,
    set_ntrip_active_status,
    get_ntrip_credential,

    import_path,
    imported_path_detail,

    auth_token,
}
