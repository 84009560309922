const getRoot = (): string => {
    return '/'
}

const getLogin = (): string => {
    return '/login/'
}

const getPasswordReset = (): string => {
    return '/password_reset/'
}

const getPasswordResetSuccess = (): string => {
    return '/password_reset/success/'
}

const getPasswordResetConfirm = (): string => {
    return '/password_reset/confirm/'
}

const getTrucks = (): string => {
    return '/trucks/'
}

const getTrucksAssignPaths = (id: string): string => {
    return '/trucks/assign/' + id + '/'
}

const getTrucksAssignNtrip = (id: string): string => {
    return '/trucks/ntrip/' + id + '/'
}

const getTrucksNtripCreate = (id: string): string => {
    return '/trucks/ntrip/create/' + id + '/'
}

const getTrucksNtripEdit = (truck_id: string, ntrip_id: string): string => {
    return '/trucks/ntrip/edit/' + truck_id + "/" + ntrip_id + "/"
}

const getTrucksEdit = (id: string): string => {
    return '/trucks/edit/' + id + '/'
}

const getActivePaths = (): string => {
    return '/active_paths/'
}

const getActivePathsEdit = (id: string): string => {
    return '/active_paths/edit/' + id + '/'
}

const getActivePathsInfo = (id: string): string => {
    return '/active_paths/info/' + id + '/'
}

const getProposedPaths = (): string => {
    return '/proposed_paths/'
}

const getProposedPathsCreate = (): string => {
    return '/proposed_paths/create/'
}

const getProposedPathsEdit = (id: string): string => {
    return '/proposed_paths/edit/' + id + '/'
}

const getProposedPathsDelete = (id: string): string => {
    return '/proposed_paths/delete/' + id + '/'
}

const getArchivedPaths = (): string => {
    return '/archived_paths/'
}

const getArchivedPathsEdit = (id: string): string => {
    return '/archived_paths/edit/' + id + '/'
}

const getArchivedPathsDelete = (id: string): string => {
    return '/archived_paths/delete/' + id + '/'
}

const getArchivedPathsInfo = (id: string): string => {
    return '/archived_paths/info/' + id + '/'
}

const getImport = (): string => {
    return '/import/'
}

const getImportPreview = (company_id: string, import_id: string): string => {
    return '/import/preview/' + company_id + '/' + import_id + '/'
}

const getImportSuccess = (): string => {
    return '/import/success/'
}

const getImportFailure = (): string => {
    return '/import/failure/'
}

const getExport = (): string => {
    return '/export/'
}

const getExportPreviewUrl = (company: string, id: string): string => {
    return '/export/' + company + '/' + id + '/'
}

const getUserAccess = (): string => {
    return '/user_access/'
}

const getUserAccessEdit = (val: string): string => {
    return '/user_access/edit/' + val + '/'
}

const getUserAccessDelete = (val: string): string => {
    return '/user_access/delete/' + val + '/'
}

const getUserAccessCreate = (): string => {
    return '/user_access/create/'
}

const getChangePassword = (): string => {
    return '/change_password/'
}

const getChangePasswordSuccess = (): string => {
    return '/change_password/success/'
}

const getLogout = (): string => {
    return '/logout/'
}

const getAdmin = (): string => {
    return '/admin/'
}

export const urls = {
    getRoot,
    getLogin,
    getPasswordReset,
    getPasswordResetSuccess,
    getPasswordResetConfirm,
    getTrucks,
    getTrucksAssignPaths,
    getTrucksAssignNtrip,
    getTrucksNtripCreate,
    getTrucksNtripEdit,
    getTrucksEdit,
    getActivePaths,
    getActivePathsEdit,
    getActivePathsInfo,
    getProposedPaths,
    getProposedPathsCreate,
    getProposedPathsEdit,
    getProposedPathsDelete,
    getArchivedPaths,
    getArchivedPathsEdit,
    getArchivedPathsDelete,
    getArchivedPathsInfo,
    getImport,
    getImportPreview,
    getImportSuccess,
    getImportFailure,
    getExport,
    getExportPreviewUrl,
    getUserAccess,
    getUserAccessEdit,
    getUserAccessDelete,
    getUserAccessCreate,
    getChangePassword,
    getChangePasswordSuccess,
    getLogout,
    getAdmin,
}
