import React, { useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Link, Navigate } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { LifemarkUserModel } from 'web_common/tsx/api/db/Models'
import { util } from 'web_common/tsx/api/db/Util'
import BigTable from 'web_common/tsx/components/misc/BigTable'

import { urls } from 'InternalUrls'

interface UserAccessAdminTableProps {
    userList: Array<LifemarkUserModel>,
}

const UserAccessAdminTable: React.FC<UserAccessAdminTableProps> = (props) => {
    const [redirectToCreateUser, setRedirectToCreateUser] = useState<boolean>(false)

    let headers = [
        {
            title: "Username",
            field: "username",
            dateFilterable: false,
            width: 700,
            minWidth: 150,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "First Name",
            field: "first_name",
            dateFilterable: false,
            width: 700,
            minWidth: 150,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "Last Name",
            field: "last_name",
            dateFilterable: false,
            width: 700,
            minWidth: 150,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "Email",
            field: "email",
            dateFilterable: false,
            width: 850,
            minWidth: 200,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "Privilege",
            field: "privilege",
            dateFilterable: false,
            width: 700,
            minWidth: 150,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "Edit",
            field: "edit",
            dateFilterable: false,
            width: 100,
            minWidth: 100,
            sortable: false,
            searchable: false,
            customStringify: undefined,
            customSort: undefined
        },
        {
            title: "Delete",
            field: "delete",
            dateFilterable: false,
            width: 100,
            minWidth: 100,
            sortable: false,
            searchable: false,
            customStringify: undefined,
            customSort: undefined
        },
    ]

    let rows = props.userList.map(
        (obj, idx) =>
        {
            let edit_link = urls.getUserAccessEdit(obj.id.toString())
            let edit_icon = (
                <div className='limn-div-row limn-div-centered'>
                    <Link to={edit_link}>
                        <ModeEditIcon/>
                    </Link>
                </div>
            )

            let delete_link = urls.getUserAccessDelete(obj.id.toString())
            let delete_icon = (
                <div className='limn-div-row limn-div-centered'>
                    <Link to={delete_link}>
                        <DeleteIcon/>
                    </Link>
                </div>
            )

            return {
                key: obj.id,
                first_name: obj.first_name,
                last_name: obj.last_name,
                username: obj.username,
                email: obj.email,
                privilege: util.transcodeCompanyAdminPrivilege(obj.privilege),
                edit: edit_icon,
                delete: delete_icon,
            }
        }
    )

    if(redirectToCreateUser === true)
    {
        return (
            <Navigate
                to={urls.getUserAccessCreate()}
            />
        )
    }
    else
    {
        return (
            <div>
                <div>
                    <BigTable
                        title={"User List"}
                        headers={headers}
                        rows={rows}
                        defaultSort={{
                            key: 'column-0',
                            order: 'desc'
                        }}
                        onVisibilityChange={(val: Array<string>)=>{}}
                        showDateFilter={false}
                    />
                </div>
                <hr className='limn-form-separator-100'/>
                <div className='limn-div-row-nowrap limn-padded-vert-10px'>
                    <div className='limn-width-10'>
                        <button
                            onClick={()=>{setRedirectToCreateUser(true)}}
                            className='limn-button-padded-5 limn-button'>
                            Create User
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserAccessAdminTable
