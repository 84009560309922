import React, { useState, useEffect, useCallback } from 'react'

import { db } from 'web_common/tsx/api/db/Api'
import { requests } from 'web_common/tsx/api/Request'
import { LifemarkUserModel, Company, ProcessedPathExportInfo, AuthToken } from 'web_common/tsx/api/db/Models'
import { service_api, ProofOfWorkResponse } from 'web_common/tsx/api/service/Api'

import ApiError from 'web_common/tsx/components/misc/ApiError'
import Loading from 'web_common/tsx/components/misc/Loading'
import ProcessedPathTable from 'web_common/tsx/components/export/ProcessedPathTable'

interface ProcessedPathExportListProps {
    user: LifemarkUserModel,
    company: Company
    getExportPreviewUrl: (company_id: string, path_id: string) => string
}

const ProcessedPathExportList: React.FC<ProcessedPathExportListProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [noSubscriptionWarning, setNoSubscriptionWarning] = useState<string|undefined>(undefined)
    const [remainingMilageWarning, setRemainingMilageWarning] = useState<number|undefined>(undefined)
    const [processedPaths, setProcessedPaths] = useState<Array<ProcessedPathExportInfo>|undefined>(undefined)

    const { company } = props

    useEffect(() => {
        if(company.high_res_data_subscription === false)
        {
            let warning: string = company.name +
                " does not have a subscription to download high resolution data. " +
                "Please contact the LimnTech team if you would like to export high resolution data."

            setRemainingMilageWarning(undefined)
            setNoSubscriptionWarning(warning)
        }
        else
        {
            let remaining_mileage = company.subscription_remaining_dl_milage +
                company.persistent_remaining_dl_milage
            setNoSubscriptionWarning(undefined)
            setRemainingMilageWarning(remaining_mileage)
        }
    }, [company])

    useEffect(() => {
        let isCanceled = false

        const onCompletion = (response: Array<ProcessedPathExportInfo>|undefined) =>
        {
            if(isCanceled === false)
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setProcessedPaths(response)
                }
            }
        }

        db.processed_path_export_list(
            props.company.id,
            true,
            onCompletion)


        return () => {
            isCanceled = true
        }
    }, [props.company.id])

    const onProofOfWork = useCallback((obj: ProcessedPathExportInfo) =>
    {
        const onDownloadComplete = (success: boolean) =>
        {
            if(success === true)
            {
                setShowLoading(false)
            }
            else
            {
                setApiError(true)
            }
        }

        const onProofOfWorkResponse = (response: ProofOfWorkResponse) =>
        {
            if(response.error !== undefined || response.url === undefined)
            {
                alert("There was a problem preparing the path for download: " + response.error)
                setShowLoading(false)
            }
            else
            {
                requests.download_file(
                    response.url,
                    'proof_of_work.csv',
                    onDownloadComplete)
            }
        }

        const onAuthTokenResposne = (response: AuthToken|undefined): void =>
        {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                service_api.proof_of_work(
                    response.token,
                    obj.id,
                    onProofOfWorkResponse)
            }
        }

        setShowLoading(true)
        db.auth_token(onAuthTokenResposne)
    }, [setShowLoading, setApiError])

    if(apiError)
    {
        return (
            <ApiError/>
        )
    }
    else if(showLoading === true)
    {
        return (
            <div>
                <Loading/>
            </div>
        )
    }
    else if(processedPaths !== undefined)
    {
        if(noSubscriptionWarning !== undefined)
        {
            return (
                <div>
                    <div className='limn-div-centered'>
                        <p><b> { noSubscriptionWarning } </b></p>
                    </div>
                    <div>
                        <ProcessedPathTable
                            company={props.company}
                            processedPathList={processedPaths}
                            getExportPreviewUrl={props.getExportPreviewUrl}
                            exportLocked={true}
                            onProofOfWork={onProofOfWork}
                        />
                    </div>
                </div>
            )
        }
        else if(remainingMilageWarning !== undefined)
        {
            return (
                <div>
                    <div className='limn-div-centered'>
                        <p> { props.company.name } has <b> { remainingMilageWarning.toFixed(3) } remaining high resolution miles </b> available for download. If you would like more miles available for download, please contact the LimnTech team. </p>
                    </div>
                    <div>
                        <ProcessedPathTable
                            company={props.company}
                            processedPathList={processedPaths}
                            getExportPreviewUrl={props.getExportPreviewUrl}
                            exportLocked={false}
                            onProofOfWork={onProofOfWork}
                        />
                    </div>
                </div>
            )
        }
        else
        {
            return (
                <div>
                    <Loading/>
                </div>
            )
        }
    }
    else
    {
        return (
            <div>
                <Loading/>
            </div>
        )
    }
}

export default ProcessedPathExportList
