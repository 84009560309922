import React, { useState, useEffect, useCallback } from 'react'

import { Navigate } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import {
    Company,
    LifemarkUserModel,
    Truck,
} from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import Loading from 'web_common/tsx/components/misc/Loading'

import { urls } from 'InternalUrls'

interface ChangeTruckCompanyProps {
    user: LifemarkUserModel
}

const ChangeTruckCompany : React.FC<ChangeTruckCompanyProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [companyList, setCompanyList] = useState<Array<Company>|undefined>(undefined)
    const [selectedCompanyIdx, setSelectedCompanyIdx] = useState<number>(0)
    const [truckList, setTruckList] = useState<Array<Truck>|undefined>(undefined)
    const [selectedTruckIdx, setSelectedTruckIdx] = useState<number>(0)

    const { user } = props

    useEffect(() =>  {
        const onResponse = (response: Array<Company>|undefined) =>
        {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                response.sort(
                    (a: Company, b: Company) =>
                {
                    return a.name.localeCompare(b.name)
                })

                let idx: number = response.findIndex((e) =>
                {
                    return e.id === user.company
                })

                if(idx !== -1)
                {
                    setSelectedCompanyIdx(idx)
                }
                setCompanyList(response)
            }
        }

        db.company_list(undefined, onResponse)
    }, [setCompanyList, setSelectedCompanyIdx, user])

    useEffect(() => {
        const onResponse = (response: Array<Truck>|undefined) =>
        {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                response.sort(
                    (a: Truck, b: Truck) =>
                {
                    return a.limntech_name.localeCompare(b.limntech_name)
                })

                setTruckList(response)
            }
        }

        db.truck_list(undefined, onResponse)
    }, [setTruckList, user])

    const handleCompanyChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) =>
    {
        setSelectedCompanyIdx(parseInt(e.target.value))
    }, [setSelectedCompanyIdx])

    const handleTruckChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) =>
    {
        setSelectedTruckIdx(parseInt(e.target.value))
    }, [setSelectedTruckIdx])

    const onClick = useCallback(() =>
    {
        if(companyList === undefined || truckList === undefined)
        {
            return
        }

        let company: Company|undefined = companyList[selectedCompanyIdx]
        if(company === undefined)
        {
            return
        }

        let truck: Truck|undefined = truckList[selectedTruckIdx]
        if(truck === undefined)
        {
            return
        }

        let processedPathIds: Array<string> = []
        let proposedPathIds: Array<string> = []
        let userId: number|undefined = undefined

        const onFinalResponse = (success: boolean) =>
        {
            if(success === false)
            {
                setShowLoading(false)
                setApiError(true)
            }
            else
            {
                setShowLoading(false)
                setSuccess(true)
            }
        }

        const assignTruckPaths = (success: boolean) =>
        {
            if(success === false || truck === undefined)
            {
                setShowLoading(false)
                setApiError(true)
            }
            else
            {
                db.patch_truck_admin(
                    truck.id,
                    processedPathIds,
                    proposedPathIds,
                    onFinalResponse)
            }
        }

        const onChangeUserCompany = (success: boolean) =>
        {
            if(success === false || userId === undefined || company === undefined)
            {
                setShowLoading(false)
                setApiError(true)
            }
            else
            {
                db.patch_user_company(
                    userId,
                    company.id,
                    assignTruckPaths)
            }
        }

        const userIdResponse = (response: Array<LifemarkUserModel>|undefined) =>
        {
            if(response === undefined || response.length !== 1 || truck === undefined)
            {
                setShowLoading(false)
                setApiError(true)
            }
            else
            {
                userId = response[0].id
                db.patch_truck_admin(
                    truck.id,
                    [],
                    [],
                    onChangeUserCompany)
            }
        }

        const propPathIdResponse = (response: Array<string>|undefined) =>
        {
            if(response === undefined || truck === undefined)
            {
                setShowLoading(false)
                setApiError(true)
            }
            else
            {
                proposedPathIds = response
                db.list_users(
                    undefined,
                    truck.id,
                    userIdResponse)
            }
        }

        const procPathIdResponse = (response: Array<string>|undefined) =>
        {
            if(response === undefined || company === undefined)
            {
                setShowLoading(false)
                setApiError(true)
            }
            else
            {
                processedPathIds = response
                db.proposed_path_id_list(
                    company.id,
                    undefined,
                    propPathIdResponse)
            }
        }

        setShowLoading(true)
        db.processed_path_id_list(
            company.id,
            undefined,
            true,
            procPathIdResponse)
    }, [selectedCompanyIdx, companyList, truckList, selectedTruckIdx, setShowLoading, setSuccess, setApiError])

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(showLoading === true)
    {
        return (
            <Loading/>
        )
    }
    else if(success === true)
    {
        return (
            <Navigate
                to={urls.getRoot()}
            />
        )
    }
    else if(companyList !== undefined && truckList !== undefined)
    {
        return (
            <div>
                <div className='limn-margin-25 limn-width-50'>
                    <div className='limn-div-row'>
                        <label
                            className='limn-label-padded limn-width-200px'
                            htmlFor='truck-select'
                        >
                            Select Truck:
                        </label>
                        <select
                            className='limn-select-box'
                            id='truck-select'
                            name='truck-select'
                            value={selectedTruckIdx}
                            onChange={handleTruckChange}
                        >
                        {
                            truckList.map(
                                (obj, idx) =>
                            {
                                return (
                                    <option
                                        key={idx}
                                        value={idx}
                                    >
                                        { obj.limntech_name }
                                    </option>
                                )
                            })
                        }
                        </select>
                    </div>
                    <div className='limn-div-row'>
                        <label
                            className='limn-label-padded limn-width-200px'
                            htmlFor='company-select'
                        >
                            Select Company:
                        </label>
                        <select
                            className='limn-select-box'
                            id='company-select'
                            name='company-select'
                            value={selectedCompanyIdx}
                            onChange={handleCompanyChange}
                        >
                        {
                            companyList.map(
                                (obj, idx) =>
                            {
                                return (
                                    <option
                                        key={idx}
                                        value={idx}
                                    >
                                        { obj.name }
                                    </option>
                                )
                            })
                        }
                        </select>
                    </div>
                </div>
                <div className='limn-div-centered'>
                    <button
                        className='limn-button limn-width-40 limn-padded-vert-10px'
                        onClick={onClick}
                    >
                        Change Trucks's Company
                    </button>
                </div>
            </div>
        )
    }
    else
    {
        return (
            <Loading/>
        )
    }
}

export default ChangeTruckCompany
