import React, { useState, useEffect, useCallback } from 'react'

import { Navigate, useParams } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { util, ReconciledNtripInfo } from 'web_common/tsx/api/db/Util'
import {
    CompanyAdminPrivilege,
    LifemarkUserModel,
    Truck,
    RtkService,
    NtripCredential
} from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import { urls } from 'InternalUrls'
import NtripTable from 'components/trucks/ntrip/list/NtripTable'

interface NtripListProps {

}

const NtripList: React.FC<NtripListProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [redirectToCreateNtrip, setRedirectToCreateNtrip] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [truck, setTruck] = useState<Truck|undefined>(undefined)
    const [rtkServiceList, setRtkServiceList] = useState<Array<RtkService>|undefined>(undefined)
    const [ntripCredentialList, setNtripCredentialList] = useState<Array<NtripCredential>|undefined>(undefined)
    const [reconciledNtripInfo, setReconciledNtripInfo] = useState<Array<ReconciledNtripInfo>|undefined>(undefined)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    const { id } = useParams()

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() => {
        if(id !== undefined)
        {
            const onTruck = (response: Truck|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setTruck(response)
                }
            }

            db.get_truck(id, onTruck)
        }
    }, [id])

    useEffect(() => {
        if(lifemarkUser !== undefined)
        {
            const onRtkService = (response: Array<RtkService>|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setRtkServiceList(response)
                }
            }

            db.list_rtk_services(lifemarkUser.company, onRtkService)
        }
    }, [lifemarkUser])

    useEffect(() => {
        if(lifemarkUser !== undefined && id !== undefined)
        {
            const onNtripCredentials = (response: Array<NtripCredential>|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setNtripCredentialList(response)
                }
            }

            db.list_ntrip_credentials(
                lifemarkUser.company,
                id,
                onNtripCredentials)
        }
    }, [lifemarkUser, id])

    useEffect(() => {
        if(ntripCredentialList !== undefined &&
            rtkServiceList !== undefined &&
            truck !== undefined)
        {
            let info: Array<ReconciledNtripInfo> = util.reconcileNtripInfo(
                [truck],
                rtkServiceList,
                ntripCredentialList)

            setReconciledNtripInfo(info)
        }
    }, [ntripCredentialList, rtkServiceList, truck])

    const onSubmit = useCallback((rtk: number|undefined, ntrip: number|undefined) =>
    {
        /*
         * If the first req succeeds and the second req fails then we get into
         * a state where no ntrip credentials are assigned. This should happen
         * atomically, but that hasn't been built into the backend. Worst case
         * scenerio, we have no ntrip creds assigned and deal with that
         */
        if(rtk !== undefined && ntrip !== undefined && reconciledNtripInfo !== undefined && reconciledNtripInfo.length === 1)
        {
            let activeId: string = reconciledNtripInfo[0].ntripInfo[rtk].ntripCredentials[ntrip].id

            const onResponse2 = (success: boolean) =>
            {
                if(success === true)
                {
                    setShowLoading(false)
                    setSuccess(true)
                }
                else
                {
                    setShowLoading(false)
                    setApiError(true)
                }
            }

            const onResponse1 = (success: boolean) =>
            {
                if(success === true)
                {
                    db.set_ntrip_active_status(
                        activeId,
                        true,
                        onResponse2)
                }
                else
                {
                    setShowLoading(false)
                    setApiError(true)
                }
            }

            if(reconciledNtripInfo[0].activeRtkServiceIdx !== undefined && reconciledNtripInfo[0].activeNtripCredentialIdx !== undefined)
            {
                if(reconciledNtripInfo[0].activeRtkServiceIdx === rtk && reconciledNtripInfo[0].activeNtripCredentialIdx === ntrip)
                {
                    setSuccess(true)
                }
                else
                {
                    setShowLoading(true)
                    let inactiveId: string = reconciledNtripInfo[0].ntripInfo[reconciledNtripInfo[0].activeRtkServiceIdx].ntripCredentials[
                        reconciledNtripInfo[0].activeNtripCredentialIdx].id
                    db.set_ntrip_active_status(
                        inactiveId,
                        false,
                        onResponse1)
                }
            }
            else
            {
                setShowLoading(true)
                onResponse1(true)
            }
        }
    }, [setShowLoading, setApiError, setSuccess, reconciledNtripInfo])

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(redirectToCreateNtrip === true && id !== undefined)
    {
        return (
            <Navigate
                to={urls.getTrucksNtripCreate(id)}
            />
        )
    }
    else if(showLoading === true)
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Manage NTRIP Credentials </h1>
                <Loading/>
            </div>
        )
    }
    else if(success === true)
    {
        return (
            <Navigate
                to={urls.getTrucks()}
            />
        )
    }
    else if(lifemarkUser !== undefined)
    {
        if(lifemarkUser.privilege === CompanyAdminPrivilege.NONE)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(reconciledNtripInfo !== undefined && reconciledNtripInfo.length === 1 && truck !== undefined)
        {
            return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Manage NTRIP Credentials: { truck.customer_name } </h1>
                    <NtripTable
                        ntripInfo={reconciledNtripInfo[0]}
                        user={lifemarkUser}
                        onSubmit={onSubmit}
                        onCreate={() => setRedirectToCreateNtrip(true) }
                    />
                </div>
            )
        }
        else
        {
           return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Manage NTRIP Credentials </h1>
                    <Loading/>
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Manage NTRIP Credentials </h1>
                <Loading/>
            </div>
        )
    }
}

export default NtripList
