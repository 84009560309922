import React, { useState, useEffect } from 'react';
import 'App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { urls } from 'InternalUrls'
import Banner from 'components/Banner'

import { db } from 'web_common/tsx/api/db/Api'
import { LifemarkUserModel } from 'web_common/tsx/api/db/Models'

import Login from 'web_common/tsx/components/auth/Login'
import Logout from 'web_common/tsx/components/auth/Logout'
import PasswordReset from 'web_common/tsx/components/auth/PasswordReset'
import PasswordResetConfirm from 'web_common/tsx/components/auth/PasswordResetConfirm'
import ChangePassword from 'web_common/tsx/components/auth/ChangePassword'
import ChangePasswordSuccess from 'web_common/tsx/components/auth/ChangePasswordSuccess'

import HomePage from 'components/home/HomePage'

import ActivePathList from 'components/paths/active/list/ActivePathList'
import ActivePathEdit from 'components/paths/active/edit/ActivePathEdit'
import ActivePathInfo from 'components/paths/active/info/ActivePathInfo'
import ArchivedPathList from 'components/paths/archived/list/ArchivedPathList'
import ArchivedPathEdit from 'components/paths/archived/edit/ArchivedPathEdit'
import ArchivedPathDelete from 'components/paths/archived/delete/ArchivedPathDelete'
import ArchivedPathInfo from 'components/paths/archived/info/ArchivedPathInfo'
import ProposedPathList from 'components/paths/proposed/list/ProposedPathList'
import ProposedPathCreate from 'components/paths/proposed/create/ProposedPathCreate'
import ProposedPathEdit from 'components/paths/proposed/edit/ProposedPathEdit'
import ProposedPathDelete from 'components/paths/proposed/delete/ProposedPathDelete'

import TruckList from 'components/trucks/list/TruckList'
import EditTruckInfo from 'components/trucks/edit/EditTruckInfo'
import NtripList from 'components/trucks/ntrip/list/NtripList'
import NtripCreate from 'components/trucks/ntrip/create/NtripCreate'
import NtripEdit from 'components/trucks/ntrip/edit/NtripEdit'
import AssignTruckPaths from 'components/trucks/assign/AssignTruckPaths'

import UserList from 'components/user/list/UserList'
import CreateUser from 'components/user/create/CreateUser'
import EditUser from 'components/user/edit/EditUser'
import DeleteUser from 'components/user/delete/DeleteUser'

import Admin from 'components/admin/Admin'

import ImportPathUpload from 'components/import/ImportPathUpload'
import PreviewImportedPath from 'components/import/PreviewImportedPath'
import PathImportSuccess from 'web_common/tsx/components/import/PathImportSuccess'
import PathImportFailure from 'web_common/tsx/components/import/PathImportFailure'

import PathExport from 'components/export/PathExport'
import PathExportDetail from 'components/export/PathExportDetail'

import Loading from 'web_common/tsx/components/misc/Loading'
import PageNotFound from 'web_common/tsx/components/misc/PageNotFound'

function App() {
    const [loggedIn, setLoggedIn] = useState<boolean|undefined>(undefined)
    const [lifemarkUserModel, setLifemarkUserModel] = useState<LifemarkUserModel|undefined>(undefined)

    useEffect(() => {
        db.current_user(
            (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setLifemarkUserModel(undefined)
                setLoggedIn(false)
            }
            else
            {
                setLifemarkUserModel(response)
                setLoggedIn(true)
            }
        })
    }, [loggedIn])

    if(loggedIn === true)
    {
        return (
            <div>
                <Router>
                <Banner user={lifemarkUserModel}/>
                <div className='app-container'>
                    <div className='content-container'>
                        <Routes>
                            <Route path="*" element={<PageNotFound/>}/>
                            <Route path={urls.getRoot()} element={
                                <HomePage
                                />
                            }/>
                            <Route path={urls.getActivePaths()} element={
                                <ActivePathList
                                />
                            }/>
                            <Route path={urls.getActivePathsEdit(':id')} element={
                                <ActivePathEdit
                                />
                            }/>
                            <Route path={urls.getActivePathsInfo(':id')} element={
                                <ActivePathInfo
                                />
                            }/>
                            <Route path={urls.getArchivedPaths()} element={
                                <ArchivedPathList
                                />
                            }/>
                            <Route path={urls.getArchivedPathsEdit(':id')} element={
                                <ArchivedPathEdit
                                />
                            }/>
                            <Route path={urls.getArchivedPathsDelete(':id')} element={
                                <ArchivedPathDelete
                                />
                            }/>
                            <Route path={urls.getArchivedPathsInfo(':id')} element={
                                <ArchivedPathInfo
                                />
                            }/>
                            <Route path={urls.getProposedPaths()} element={
                                <ProposedPathList
                                />
                            }/>
                            <Route path={urls.getProposedPathsCreate()} element={
                                <ProposedPathCreate
                                />
                            }/>
                            <Route path={urls.getProposedPathsEdit(':id')} element={
                                <ProposedPathEdit
                                />
                            }/>
                            <Route path={urls.getProposedPathsDelete(':id')} element={
                                <ProposedPathDelete
                                />
                            }/>

                            <Route path={urls.getTrucks()} element={
                                <TruckList
                                />
                            }/>
                            <Route path={urls.getTrucksEdit(":id")} element={
                                <EditTruckInfo
                                />
                            }/>
                            <Route path={urls.getTrucksAssignNtrip(":id")} element={
                                <NtripList
                                />
                            }/>
                            <Route path={urls.getTrucksNtripCreate(":id")} element={
                                <NtripCreate
                                />
                            }/>
                            <Route path={urls.getTrucksNtripEdit(":truck_id", ":ntrip_id")} element={
                                <NtripEdit
                                />
                            }/>
                            <Route path={urls.getTrucksAssignPaths(":id")} element={
                                <AssignTruckPaths
                                />
                            }/>

                            <Route path={urls.getUserAccess()} element={
                                <UserList
                                />
                            }/>
                            <Route path={urls.getUserAccessCreate()} element={
                                <CreateUser
                                />
                            }/>
                            <Route path={urls.getUserAccessEdit(':id')} element={
                                <EditUser
                                />
                            }/>
                            <Route path={urls.getUserAccessDelete(':id')} element={
                                <DeleteUser
                                />
                            }/>

                            <Route path={urls.getImport()} element={
                                <ImportPathUpload
                                />
                            }/>
                            <Route path={urls.getImportPreview(":company_id", ":import_id")} element={
                                <PreviewImportedPath
                                />
                            }/>
                            <Route path={urls.getImportSuccess()} element={
                                <PathImportSuccess
                                />
                            }/>
                            <Route path={urls.getImportFailure()} element={
                                <PathImportFailure
                                />
                            }/>

                            <Route path={urls.getExport()} element={
                                <PathExport
                                />
                            }/>
                            <Route path={urls.getExportPreviewUrl(":company_id", ":path_id")} element={
                                <PathExportDetail
                                />
                            }/>

                            <Route path={urls.getAdmin()} element={
                                <Admin
                                />
                            }/>

                            <Route path={urls.getChangePassword()} element={
                                <ChangePassword
                                    changePasswordSuccessUrl={urls.getChangePasswordSuccess()}
                                />
                            }/>
                            <Route path={urls.getChangePasswordSuccess()} element={
                                <ChangePasswordSuccess
                                />
                            }/>
                            <Route path={urls.getLogout()} element={
                                <Logout
                                    redirectOnSuccess={urls.getRoot()}
                                    setLoggedIn={setLoggedIn}
                                />
                            }/>
                        </Routes>
                    </div>
                </div>
                </Router>
            </div>
        )
    }
    else if(loggedIn === false)
    {
        return (
            <div>
                <Router>
                <Banner user={lifemarkUserModel}/>
                <div className='app-container'>
                    <div className='content-container'>
                        <Routes>
                            <Route path="*" element={<PageNotFound/>}/>
                            <Route path={urls.getRoot()} element={
                                <Login
                                    forgotPasswordUrl={urls.getPasswordReset()}
                                    setLoggedIn={setLoggedIn}
                                />
                            }/>
                            <Route path={urls.getLogin()} element={
                                <Login
                                    forgotPasswordUrl={urls.getPasswordReset()}
                                    setLoggedIn={setLoggedIn}
                                />
                            }/>
                            <Route path={urls.getPasswordReset()} element={
                                <PasswordReset />
                            }/>
                            <Route path={urls.getPasswordResetConfirm()} element={
                                <PasswordResetConfirm
                                    passwordResetConfirmSuccessUrl={urls.getRoot()}
                                />
                            }/>
                        </Routes>
                    </div>
                </div>
                </Router>
            </div>
        )
    }
    else
    {
        return (
            <div>
                <Router>
                    <Banner user={lifemarkUserModel}/>
                    <Loading/>
                </Router>
            </div>
        )
    }
}

export default App;
