import React from 'react'

import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import LockIcon from '@mui/icons-material/Lock'
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { Link } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { Company, ProcessedPathExportInfo } from 'web_common/tsx/api/db/Models'
import BigTable from 'web_common/tsx/components/misc/BigTable'

import { util } from 'web_common/tsx/components/misc/Util'

interface ProcessedPathTableProps {
    exportLocked: boolean,
    company: Company,
    processedPathList: Array<ProcessedPathExportInfo>,
    getExportPreviewUrl: (company_id: string, path_id: string) => string,
    onProofOfWork: (path: ProcessedPathExportInfo) => void,
}

const ProcessedPathTable: React.FC<ProcessedPathTableProps> = (props) => {
    let headers = [
        {
            title: "Path Name",
            field: "path_name",
            dateFilterable: false,
            width: 1500,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            },
            customStringify: undefined,
        },
        {
            title: "Path Description",
            field: "path_description",
            dateFilterable: false,
            width: 1500,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            },
            customStringify: undefined,
        },
        {
            title: "Date Created",
            field: "date_created",
            dateFilterable: true,
            width: 1500,
            minWidth: 300,
            sortable: true,
            searchable: false,
            customSort: (a: Date, b: Date) => {
                return b.getTime() - a.getTime()
            },
            customStringify: (obj: Date) => {
                return util.formatDateString(obj)
            },
        },
        {
            title: "Path Length Miles",
            field: "length",
            dateFilterable: false,
            width: 200,
            minWidth: 200,
            sortable: false,
            searchable: false,
            customSort: undefined,
            customStringify: undefined,
        },
        {
            title: "Proof of Work",
            field: "pow",
            dateFilterable: false,
            width: 200,
            minWidth: 200,
            sortable: false,
            searchable: false,
            customSort: undefined,
            customStringify: undefined,
        },
        {
            title: "High Resolution Export",
            field: "export",
            dateFilterable: false,
            width: 300,
            minWidth: 300,
            sortable: false,
            searchable: false,
            customSort: undefined,
            customStringify: undefined,
        },
        {
            title: "Exported",
            field: "exported",
            dateFilterable: false,
            width: 150,
            minWidth: 150,
            sortable: false,
            searchable: false,
            customSort: undefined,
            customStringify: undefined,
        }
    ]

    const METER_TO_MILE: number = 0.000621371
    let rows = props.processedPathList.map(
        (obj, idx) =>
        {
            let pow_icon = (
                <CloudDownloadIcon
                    className='limn-blue limn-clickable'
                    onClick={()=>{props.onProofOfWork(obj)}}
                />
            )

            let export_icon = <></>
            if(props.exportLocked === false)
            {
                let allow_export = false
                if(obj.exported === true)
                {
                    allow_export = true
                }
                else if(obj.path_length_meters !== null)
                {
                    let length_miles = obj.path_length_meters * METER_TO_MILE
                    let remaining_length = props.company.subscription_remaining_dl_milage +
                        props.company.persistent_remaining_dl_milage

                    allow_export = remaining_length > length_miles
                }

                if(allow_export === false)
                {
                    export_icon = (
                        <div className='limn-div-row limn-div-centered'>
                            <LockIcon/>
                        </div>
                    )
                }
                else
                {
                    let link = props.getExportPreviewUrl(props.company.id, obj.id)
                    export_icon = (
                        <div className='limn-div-row limn-div-centered'>
                            <Link to={link}>
                                <CloudDownloadIcon className='limn-blue'/>
                            </Link>
                        </div>
                    )
                }
            }
            else
            {
                export_icon = (
                    <div className='limn-div-row limn-div-centered'>
                        <LockIcon/>
                    </div>
                )
            }

            let path_length = (
                <div className='limn-div-row limn-div-centered'>
                    <QuestionMarkIcon/>
                </div>
            )
            if(obj.path_length_meters !== null)
            {
                let length_miles = obj.path_length_meters * METER_TO_MILE
                path_length = (
                    <div className='limn-div-row limn-div-centered'>
                        <p> { length_miles.toFixed(3) } </p>
                    </div>
                )
            }

            let exported = <></>
            if(obj.exported === true)
            {
                exported = (
                    <div className='limn-div-row limn-div-centered limn-green'>
                        <FileDownloadDoneIcon/>
                    </div>
                )
            }

            return {
                key: idx,
                path_name: obj.path_name,
                path_description: obj.path_description,
                date_created: obj.date_created,
                pow: pow_icon,
                export: export_icon,
                length: path_length,
                exported: exported,
            }
        }
    )

    return (
        <BigTable
            title={"Export List (Active Paths)"}
            headers={headers}
            rows={rows}
            defaultSort={{
                key: 'column-2',
                order: 'desc'
            }}
            showDateFilter={true}
            onVisibilityChange={(val: Array<string>)=>{}}
        />
    )
}

export default ProcessedPathTable
