import React, { useState, useEffect } from 'react'

import { db } from 'web_common/tsx/api/db/Api'
import { Company, LifemarkUserModel } from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import Loading from 'web_common/tsx/components/misc/Loading'
import TotalMapOverlay from 'web_common/tsx/components/misc/TotalMapOverlay'

import PathCountTable from 'components/home/PathCountTable'

interface HomePageProps {

}

const HomePage: React.FC<HomePageProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [company, setCompany] = useState<Company|undefined>(undefined)
    const [truckSize, setTruckSize] = useState<number|undefined>(undefined)
    const [activePathSize, setActivePathSize] = useState<number|undefined>(undefined)
    const [proposedPathSize, setProposedPathSize] = useState<number|undefined>(undefined)
    const [archivedPathSize, setArchivedPathSize] = useState<number|undefined>(undefined)

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() => {
        if(lifemarkUser !== undefined)
        {
            const onCompany = (response: Company|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setCompany(response)
                }
            }

            db.company_detail(lifemarkUser.company, onCompany)
        }
    }, [lifemarkUser])

    useEffect(() => {
        if(company !== undefined)
        {
            const onTruckSize = (response: number|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setTruckSize(response)
                }
            }

            db.company_trucks_size(
                company.id,
                onTruckSize)
        }
    }, [company])

    useEffect(() => {
        if(company !== undefined)
        {
            const onPathSize = (response: number|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setActivePathSize(response)
                }
            }

            db.company_active_path_size(
                company.id,
                onPathSize)
        }
    }, [company])

    useEffect(() => {
        if(company !== undefined)
        {
            const onPathSize = (response: number|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setProposedPathSize(response)
                }
            }

            db.company_proposed_path_size(
                company.id,
                onPathSize)
        }
    }, [company])

    useEffect(() => {
        if(company !== undefined)
        {
            const onPathSize = (response: number|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setArchivedPathSize(response)
                }
            }

            db.company_archived_path_size(
                company.id,
                onPathSize)
        }
    }, [company])

    if(apiError === true)
    {
        return <ApiError />
    }
    else if(
        lifemarkUser !== undefined &&
        company !== undefined &&
        truckSize !== undefined &&
        activePathSize !== undefined &&
        proposedPathSize !== undefined &&
        archivedPathSize !== undefined)
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> { company.name } Administration </h1>
                <PathCountTable
                    trucks={truckSize}
                    activePaths={activePathSize}
                    proposedPaths={proposedPathSize}
                    archivedPaths={archivedPathSize}
                />
                <hr className='limn-form-separator-80'/>
                <div className='limn-padded-10p'>
                    <TotalMapOverlay
                        company={lifemarkUser.company}
                    />
                </div>
            </div>
        )
    }
    else
    {
        return (
            <Loading/>
        )
    }
}

export default HomePage
