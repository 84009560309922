import React from 'react'

import "web_common/css/LimnTech.css"
import { util } from 'web_common/tsx/api/db/Util'

import { UserEntryFormSubmitData } from 'components/user/UserEntryForm'

interface CreateUserSuccessProps {
    data: UserEntryFormSubmitData
}

const CreateUserSuccess: React.FC<CreateUserSuccessProps> = (props) => {
    return (
        <div className='limn-form-container'>
            <h2 className='limn-header'> Create User Success! </h2>
            <div className='limn-margin-10'>
                <p> You have successfully created the user listed below. The new user user should expect an email sent to them containing a temporary password to access this website. </p>
            </div>
            <div className='limn-margin-10'>
                <ul>
                    <li><b> Username: </b>{ props.data.username } </li>
                    <li><b> First Name: </b>{ props.data.firstname } </li>
                    <li><b> Last Name: </b>{ props.data.lastname } </li>
                    <li><b> Email: </b>{ props.data.email } </li>
                    <li><b> Privilege: </b>{ util.transcodeCompanyAdminPrivilege(props.data.privilege)} </li>
                </ul>
            </div>
            <div className='limn-margin-10'>
                <p> Please use the navigation buttons at the top of the page if you wish to continue doing work. </p>
            </div>
        </div>
    )
}

export default CreateUserSuccess
