import { requests } from 'web_common/tsx/api/Request'

const BASE_URL = process.env.REACT_APP_SERVICE_URL

interface EncodedPathEntry {
    encoded_path: string
}

interface EncodedPathContainer {
    path_list: Array<EncodedPathEntry>,
    precision: number,
}

export interface PatternTransitions {
    color: string,
    pattern1: string,
    pattern2: string,
    lat: number,
    lon: number,
    alt: number,
    sn: number,
    idx: number
}

export interface PatternTransitionResponse {
    transitions: Array<PatternTransitions>,
    reference_path: EncodedPathContainer|undefined,
    version: number|undefined,
    error: string|undefined
}

const preview_transitions = (
    token: string,
    path_id: string,
    onCompletion: (response: PatternTransitionResponse) => void): void =>
{
    let post_data = {
        login_token: token,
        processed_path_id: path_id
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            if(data.data.error !== undefined)
            {
                onCompletion({
                    transitions: [],
                    reference_path: undefined,
                    version: undefined,
                    error: data.data.error
                })
            }
            else
            {
                let transitions = data.data.transitions.map(
                    (obj: PatternTransitions, idx: any) =>
                {
                    return {
                        color: obj.color,
                        pattern1: obj.pattern1,
                        pattern2: obj.pattern2,
                        lat: Number(obj.lat),
                        lon: Number(obj.lon),
                        alt: Number(obj.alt),
                        sn: Number(obj.sn),
                        idx: Number(obj.idx)
                    }
                })

                onCompletion({
                    transitions: transitions,
                    reference_path: data.data.reference_path,
                    version: data.data.version,
                    error: undefined
                })
            }
        }
        else
        {
            console.log("Gathering transitions failed. Unexpected HTTP response status")
            let response: PatternTransitionResponse = {
                transitions: [],
                reference_path: undefined,
                version: undefined,
                error: "Unexpected HTTP Response"
            }

            if(data.data.error !== undefined)
            {
                response.error = data.data.error
            }

            onCompletion(response)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)

        let response: PatternTransitionResponse = {
            transitions: [],
            reference_path: undefined,
            version: undefined,
            error: "Unexpected HTTP Response"
        }

        if(error.response && error.response.data && error.response.data.error)
        {
            response.error = error.response.data.error
        }

        onCompletion(response)
    }

    requests.post(
        BASE_URL + 'path/pattern_transitions/preview/',
        post_data,
        false,
        onResponse,
        onError)
}

export interface EditTransitionsResponse {
    success: boolean,
}

const edit_transitions = (
    token: string,
    path_id: string,
    transitions: Array<PatternTransitions>,
    version: number,
    onCompletion: (response: EditTransitionsResponse) => void): void =>
{
    let post_data = {
        login_token: token,
        path_id: path_id,
        transitions: transitions,
        version: version
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            if(data.data.error !== undefined)
            {
                onCompletion({
                    success: false
                })
            }
            else
            {
                onCompletion({
                    success: true
                })
            }
        }
        else
        {
            console.log("Editing transitions failed. Unexpected HTTP response status")
            onCompletion({ success: false })
        }
    }

    let onError = (error: any) =>
    {
        onCompletion({ success: false })
    }

    requests.post(
        BASE_URL + 'path/pattern_transitions/edit/',
        post_data,
        false,
        onResponse,
        onError)
}

export interface PreviewResponse {
    transitions: Array<PatternTransitions>,
    reference_path: EncodedPathContainer|undefined,
    version: number|undefined,
    error: boolean,
    error_message: string|undefined
}

const preview = (
    token: string,
    path_id: string,
    onCompletion: (response: PreviewResponse) => void): void =>
{
    let post_data = {
        login_token: token,
        imported_path_id: path_id
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            if(data.data.error !== undefined)
            {
                onCompletion({
                    transitions: [],
                    reference_path: undefined,
                    version: undefined,
                    error: true,
                    error_message: data.data.error
                })
            }
            else
            {
                /*
                 * This is for backwards compatibility
                 */
                if(data.data.transitions !== undefined)
                {
                    let transitions = data.data.transitions.map(
                        (obj: PatternTransitions, idx: any) =>
                    {
                        return {
                            color: obj.color,
                            pattern1: obj.pattern1,
                            pattern2: obj.pattern2,
                            lat: Number(obj.lat),
                            lon: Number(obj.lon),
                            alt: Number(obj.alt),
                            sn: Number(obj.sn),
                            idx: Number(obj.idx)
                        }
                    })

                    /*
                     * TODO make this backwards compatible
                     */
                    onCompletion({
                        transitions: transitions,
                        reference_path: data.data.reference_path,
                        version: data.data.version,
                        error: false,
                        error_message: undefined
                    })
                }
                else
                {
                    console.log("Using backwards compatible old data format for path preview")
                    onCompletion({
                        transitions: [],
                        reference_path: {
                            precision: data.data.precision,
                            path_list: data.data.path_list
                        },
                        version: 0,
                        error: false,
                        error_message: undefined
                    })
                }
            }
        }
        else
        {
            console.log("Preview failed. Unexpected HTTP response status")
            let response: PreviewResponse = {
                transitions: [],
                reference_path: undefined,
                version: undefined,
                error: true,
                error_message: "Unexpected HTTP Response"
            }

            if(data.data.error !== undefined)
            {
                response.error_message = data.data.error
            }

            onCompletion(response)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)

        let response: PreviewResponse = {
            transitions: [],
            reference_path: undefined,
            version: undefined,
            error: true,
            error_message: "An error occurred trying to preview the path"
        }

        if(error.response && error.response.data && error.response.data.error)
        {
            response.error_message = error.response.data.error
        }
        onCompletion(response)
    }

    requests.post(
        BASE_URL + 'path/preview/',
        post_data,
        false,
        onResponse,
        onError)
}

export interface PublishResponse {
    id: string|undefined
    error: string|undefined,
}

const publish = (
    token: string,
    path_id: string,
    onCompletion: (response: PublishResponse) => void): void =>
{
    let post_data = {
        login_token: token,
        imported_path_id: path_id
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            if(data.data.error !== undefined)
            {
                onCompletion({
                    id: undefined,
                    error: data.data.error
                })
            }
            else
            {
                onCompletion({
                    id: data.data.id,
                    error: undefined
                })
            }
        }
        else
        {
            console.log("Publish failed. Unexpected HTTP response status")
            let response: PublishResponse = {
                id: undefined,
                error: 'Unexpected HTTP Response'
            }

            if(data.data.error !== undefined)
            {
                response.error = data.data.error
            }

            onCompletion(response)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)

        let response: PublishResponse = {
            id: undefined,
            error: "An error occurred trying to publish the path"
        }

        if(error.response && error.response.data && error.response.data.error)
        {
            response.error = error.response.data.error
        }
        onCompletion(response)
    }

    requests.post(
        BASE_URL + 'path/publish/',
        post_data,
        false,
        onResponse,
        onError)
}

export interface DownloadResponse {
    url: string|undefined
    error: string|undefined,
}

const download = (
    token: string,
    path_id: string,
    onCompletion: (response: DownloadResponse) => void): void =>
{
    let post_data = {
        login_token: token,
        path_id: path_id
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            if(data.data.error !== undefined)
            {
                onCompletion({
                    url: undefined,
                    error: data.data.error
                })
            }
            else
            {
                onCompletion({
                    url: data.data.url,
                    error: undefined
                })
            }
        }
        else
        {
            console.log("Download failed. Unexpected HTTP response status")
            let response: DownloadResponse = {
                url: undefined,
                error: 'Unexpected HTTP Response'
            }

            if(data.data.error !== undefined)
            {
                response.error = data.data.error
            }

            onCompletion(response)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)

        let response: DownloadResponse = {
            url: undefined,
            error: "An error occurred trying to download the path"
        }

        if(error.response && error.response.data && error.response.data.error)
        {
            response.error = error.response.data.error
        }
        onCompletion(response)
    }

    requests.post(
        BASE_URL + 'path/download/',
        post_data,
        false,
        onResponse,
        onError)
}

export interface ProofOfWorkResponse {
    url: string|undefined
    error: string|undefined,
}

const proof_of_work = (
    token: string,
    path_id: string,
    onCompletion: (response: ProofOfWorkResponse) => void): void =>
{
    let post_data = {
        login_token: token,
        path_id: path_id
    }

    let onResponse = (data: any) =>
    {
        if(data.status === 200)
        {
            if(data.data.error !== undefined)
            {
                onCompletion({
                    url: undefined,
                    error: data.data.error
                })
            }
            else
            {
                onCompletion({
                    url: data.data.url,
                    error: undefined
                })
            }
        }
        else
        {
            console.log("Proof of work failed. Unexpected HTTP response status")
            let response: ProofOfWorkResponse = {
                url: undefined,
                error: 'Unexpected HTTP Response'
            }

            if(data.data.error !== undefined)
            {
                response.error = data.data.error
            }

            onCompletion(response)
        }
    }

    let onError = (error: any) =>
    {
        console.log(error)

        let response: ProofOfWorkResponse = {
            url: undefined,
            error: "An error occurred trying to get the proof of work"
        }

        if(error.response && error.response.data && error.response.data.error)
        {
            response.error = error.response.data.error
        }
        onCompletion(response)
    }

    requests.post(
        BASE_URL + 'path/proof_of_work/',
        post_data,
        false,
        onResponse,
        onError)
}

export const service_api =
{
    preview,
    preview_transitions,
    edit_transitions,
    publish,
    download,
    proof_of_work,
}
