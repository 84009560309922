import React, { useState, useEffect } from 'react'

import { Navigate, useParams } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import {
    CompanyAdminPrivilege,
    LifemarkUserModel,
    Truck,
    ProcessedPathInfo
} from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import AssignPathTable from 'components/trucks/assign/AssignPathTable'
import { urls } from 'InternalUrls'

interface AssignTruckPathsProps {

}

const AssignTruckPaths: React.FC<AssignTruckPathsProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [truck, setTruck] = useState<Truck|undefined>(undefined)
    const [assignedPathIdList, setAssignedPathIdList] = useState<Object|undefined>(undefined)
    const [activePathList, setActivePathList] = useState<Array<ProcessedPathInfo>|undefined>(undefined)
    const [assignmentList, setAssignmentList] = useState<Array<boolean>|undefined>(undefined)

    const { id } = useParams()

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])
    useEffect(() => {
        if(id !== undefined)
        {
            const onTruck = (response: Truck|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setTruck(response)
                }
            }

            db.get_truck(id, onTruck)
        }
    }, [id])
    useEffect(() => {
        if(id !== undefined && lifemarkUser !== undefined)
        {
            const onResponse = (response: Array<string>|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    const lookupMap = Object.fromEntries(response.map((val) => [val, val]))
                    setAssignedPathIdList(lookupMap)
                }
            }

            db.processed_path_id_list(
                lifemarkUser.company,
                id,
                true,
                onResponse)
        }
    }, [id, lifemarkUser])
    useEffect(() => {
        if(id !== undefined && lifemarkUser !== undefined)
        {
            const onResponse = (response: Array<ProcessedPathInfo>|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setActivePathList(response)
                }
            }

            db.processed_path_list(
                lifemarkUser.company,
                true,
                onResponse)
        }
    }, [lifemarkUser, id])
    useEffect(() => {
        if(activePathList !== undefined && assignedPathIdList !== undefined)
        {
            let activePathMap = activePathList.map(
                (obj, idx) =>
            {
                return obj.id in assignedPathIdList
            })

            setAssignmentList(activePathMap)
        }
    }, [activePathList, assignedPathIdList])

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(showLoading === true)
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Assign Paths to Truck </h1>
                <Loading/>
            </div>
        )
    }
    else if(success === true)
    {
        return (
            <Navigate
                to={urls.getTrucks()}
            />
        )
    }
    else if(lifemarkUser !== undefined && truck !== undefined && activePathList !== undefined && assignmentList !== undefined && id !== undefined)
    {
        if(lifemarkUser.privilege !== CompanyAdminPrivilege.ADMIN)
        {
            return (
                <PermissionDenied/>
            )
        }
        else
        {
            return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Assign Paths to Truck: { truck.customer_name } </h1>
                    <AssignPathTable
                        truckId={id}
                        processedPathList={activePathList}
                        assignmentList={assignmentList}
                        onRequestPending={()=>
                        {
                            setShowLoading(true)
                        }}
                        onRequestSuccess={()=>
                        {
                            setShowLoading(false)
                            setSuccess(true)
                        }}
                        onRequestFailure={()=>
                        {
                            setShowLoading(false)
                            setApiError(true)
                        }}
                    />
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Assign Paths to Truck </h1>
                <Loading/>
            </div>
        )
    }
}

export default AssignTruckPaths
