import React from 'react'

import 'web_common/css/LimnTech.css'
import { LifemarkUserModel } from 'web_common/tsx/api/db/Models'
import { util } from 'web_common/tsx/api/db/Util'
import BigTable from 'web_common/tsx/components/misc/BigTable'

interface UserAccessUserTableProps {
    userList: Array<LifemarkUserModel>,
}

const UserAccessUserTable: React.FC<UserAccessUserTableProps> = (props) => {
    let headers = [
        {
            title: "First Name",
            field: "first_name",
            dateFilterable: false,
            width: 1000,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "Last Name",
            field: "last_name",
            dateFilterable: false,
            width: 1000,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "Username",
            field: "username",
            dateFilterable: false,
            width: 1000,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "Privilege",
            field: "privilege",
            dateFilterable: false,
            width: 1000,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
    ]

    let rows = props.userList.map(
        (obj, idx) =>
        {
            return {
                key: obj.id,
                first_name: obj.first_name,
                last_name: obj.last_name,
                username: obj.username,
                privilege: util.transcodeCompanyAdminPrivilege(obj.privilege),
            }
        }
    )

    return (
        <div>
            <div>
                <BigTable
                    title={"User Info"}
                    headers={headers}
                    rows={rows}
                    defaultSort={{
                        key: 'column-0',
                        order: 'desc'
                    }}
                    onVisibilityChange={(val: Array<string>)=>{}}
                    showDateFilter={false}
                />
            </div>
        </div>
    )
}

export default UserAccessUserTable
