import React from 'react'

import "web_common/css/LimnTech.css"

interface PathImportSuccessProps {

}

const PathImportSuccess: React.FC<PathImportSuccessProps> = (props) => {
    return (
        <div className='limn-form-container'>
            <h2 className='limn-header'> Path Import Success! </h2>
            <div className='limn-div-centered limn-bigfont'>
                <p className='limn-tiny-p'> You have successfully imported the path! Please use the navigation buttons at the top of the page if you wish to continue performing work. </p>
            </div>
        </div>
    )
}

export default PathImportSuccess
