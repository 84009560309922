import React, { useState } from 'react'

import { Truck, NtripCredential, RtkService } from 'web_common/tsx/api/db/Models'
import { validators } from 'web_common/tsx/regex/validators'

interface PortWarningProps {
    port: string
}

const PortWarningMessage: React.FC<PortWarningProps> = (props) => {
    let valid: boolean = validators.isPositiveInteger(props.port) ||
        props.port.length === 0

    if(valid === true)
    {
        return <></>
    }
    else
    {
        return (
            <p className='limn-warning limn-margin-10'>
                The port number must be a positive integer
            </p>
        )
    }
}


interface RtkFormProps {
    show: boolean
    name: string,
    url: string,
    setName: (val: string) => void,
    setUrl: (val: string) => void,
}

const RtkForm: React.FC<RtkFormProps> = (props) => {
    if(props.show === true)
    {
        return (
            <div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='name'> RTK Service Name: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='name'
                        name='name'
                        placeholder={'You must enter an rtk service name...'}
                        value={props.name}
                        onChange={(e) => { props.setName(e.target.value) }}
                        required
                    />
                </div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='url'> RTK Service Map URL: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='url'
                        name='url'
                        placeholder={'You must enter an rtk service map url...'}
                        value={props.url}
                        onChange={(e) => { props.setUrl(e.target.value) }}
                        required
                    />
                </div>
            </div>
        )
    }
    else
    {
        return (
            <></>
        )
    }
}

export interface NtripFormSubmitObject {
    truck: Truck,
    rtkService: RtkService,
    createNewService: boolean,

    username: string,
    password: string,
    host: string,
    port: number,
    stream: string,
    region: string,
}

interface NtripFormProps {
    truck: Truck,
    currentCredentials: NtripCredential|undefined,
    rtkList: Array<RtkService>,
    onSubmit: (obj: NtripFormSubmitObject) => void,
}

const NtripForm: React.FC<NtripFormProps> = (props) => {
    const [newRtkServiceName, setNewRtkServiceName] = useState<string>('')
    const [newRtkServiceUrl, setNewRtkServiceUrl] = useState<string>('')
    const [rtkService, setRtkService] = useState<string>(() =>
    {
        if(props.currentCredentials === undefined)
        {
            return ''
        }
        else
        {
            return props.currentCredentials.rtk_service
        }
    })
    const [username, setUsername] = useState<string>(() =>
    {
        if(props.currentCredentials === undefined)
        {
            return ''
        }
        else
        {
            return props.currentCredentials.username
        }
    })
    const [password, setPassword] = useState<string>(() =>
    {
        if(props.currentCredentials === undefined)
        {
            return ''
        }
        else
        {
            return props.currentCredentials.password
        }
    })
    const [host, setHost] = useState<string>(() =>
    {
        if(props.currentCredentials === undefined)
        {
            return ''
        }
        else
        {
            return props.currentCredentials.host
        }
    })
    const [port, setPort] = useState<string>(() =>
    {
        if(props.currentCredentials === undefined)
        {
            return ''
        }
        else
        {
            return props.currentCredentials.port.toString()
        }
    })
    const [stream, setStream] = useState<string>(() =>
    {
        if(props.currentCredentials === undefined)
        {
            return ''
        }
        else
        {
            return props.currentCredentials.stream
        }
    })
    const [region, setRegion] = useState<string>(() =>
    {
        if(props.currentCredentials === undefined)
        {
            return ''
        }
        else
        {
            return props.currentCredentials.region
        }
    })

    const onSubmit = (e: React.FormEvent) =>
    {
        e.preventDefault()

        if(validators.isPositiveInteger(port) === false)
        {
            alert("You need to enter a valid port number!")
            return
        }

        let assignedService: RtkService|undefined = props.rtkList.find((e) =>
        {
            return e.id === rtkService
        })

        let createNewService: boolean = assignedService === undefined
        if(assignedService === undefined)
        {
            assignedService = {
                id: '',
                name: newRtkServiceName,
                map_url: newRtkServiceUrl,
            }
        }

        let submitObj: NtripFormSubmitObject = {
            truck: props.truck,
            rtkService: assignedService,
            createNewService: createNewService,
            username: username,
            password: password,
            host: host,
            port: parseInt(port),
            stream: stream,
            region: region,
        }

        props.onSubmit(submitObj)
    }

    return (
        <div className='limn-padded-top-50px'>
            <form onSubmit={onSubmit}>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px'> Truck: </label>
                    <p className='limn-label-padded limn-width-200px'><b> { props.truck.customer_name } -- { props.truck.limntech_name } </b></p>
                </div>
               <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='rtk-service'> RTK Service: </label>
                    <select
                        className='limn-select-box'
                        id='rtk-select'
                        name='rtk-select'
                        value={rtkService}
                        onChange={(e) => { setRtkService(e.target.value) }}
                    >
                        <option
                            key={0}
                            value={''}
                        >
                            -- Create New RTK Service --
                        </option>
                        {
                            props.rtkList.map(
                                (obj, idx) =>
                            {
                                return (
                                    <option
                                        key={idx + 1}
                                        value={obj.id}
                                    >
                                        { obj.name }
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
                <RtkForm
                    show={rtkService === ''}
                    name={newRtkServiceName}
                    setName={setNewRtkServiceName}
                    url={newRtkServiceUrl}
                    setUrl={setNewRtkServiceUrl}
                />
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='username'> Username: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='username'
                        name='username'
                        placeholder={'You must enter a username...'}
                        value={username}
                        onChange={(e) => { setUsername(e.target.value) }}
                        required
                    />
                </div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='passowrd'> Password: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='password'
                        name='password'
                        placeholder={'You must enter a password...'}
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }}
                        required
                    />
                </div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='host'> Host: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='host'
                        name='host'
                        placeholder={'You must enter a host (e.g. pa.smartnetna.com)...'}
                        value={host}
                        onChange={(e) => { setHost(e.target.value) }}
                        required
                    />
                </div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='port'> Port: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='port'
                        name='port'
                        placeholder={'You must enter a port number...'}
                        value={port}
                        onChange={(e) => { setPort(e.target.value) }}
                        required
                    />
                </div>
                <PortWarningMessage port={port} />
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='stream'> Stream: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='stream'
                        name='stream'
                        placeholder={'You must enter a stream (e.g. RTCM3_IMAX)...'}
                        value={stream}
                        onChange={(e) => { setStream(e.target.value) }}
                        required
                    />
                </div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='region'> Region: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='region'
                        name='region'
                        placeholder={'You must enter a region (e.g. PA)...'}
                        value={region}
                        onChange={(e) => { setRegion(e.target.value) }}
                        required
                    />
                </div>
                <div className='limn-div-centered limn-width-90 limn-padded-bottom-10px'>
                    <button
                        onSubmit={onSubmit}
                        className='limn-button limn-width-50'
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    )
}

export default NtripForm
