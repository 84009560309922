import React, { useState, useEffect } from 'react'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { Company, CompanyAdminPrivilege, LifemarkUserModel, ProcessedPathInfo } from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import ActivePathAdminTable from 'components/paths/active/list/ActivePathAdminTable'
import ActivePathUserTable from 'components/paths/active/list/ActivePathUserTable'

interface ActivePathListProps {

}

const ActivePathList: React.FC<ActivePathListProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [company, setCompany] = useState<Company|undefined>(undefined)
    const [processedPaths, setProcessedPaths] = useState<Array<ProcessedPathInfo>|undefined>(undefined)
    const [archivePending, setArchivePending] = useState<boolean>(false)

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() => {
        if(lifemarkUser !== undefined)
        {
            const onCompany = (response: Company|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setCompany(response)
                }
            }

            db.company_detail(lifemarkUser.company, onCompany)
        }
    }, [lifemarkUser])

    useEffect(() => {
        if(lifemarkUser !== undefined && processedPaths === undefined)
        {
            const onCompletion = (response: Array<ProcessedPathInfo>|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setProcessedPaths(response)
                }
            }

            db.processed_path_list(
                lifemarkUser.company,
                true,
                onCompletion)
        }
    }, [lifemarkUser, processedPaths])

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(archivePending === true)
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Active Paths </h1>
                <Loading/>
            </div>
        )
    }
    else if(lifemarkUser !== undefined)
    {
        if(lifemarkUser.privilege === CompanyAdminPrivilege.NONE)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(company !== undefined && processedPaths !== undefined)
        {
            if(lifemarkUser.privilege === CompanyAdminPrivilege.ADMIN)
            {
                return (
                    <div className='limn-form-container'>
                        <h1 className='limn-header'> Active Paths </h1>
                        <ActivePathAdminTable
                            processedPathList={processedPaths}
                            onArchivePending={()=>{
                                setArchivePending(true)
                            }}
                            onArchiveFailure={()=>{
                                setApiError(true)
                                setArchivePending(false)
                            }}
                            onArchiveSuccess={()=>{
                                setProcessedPaths(undefined)
                                setArchivePending(false)
                            }}
                        />
                    </div>
                )
            }
            else
            {
                return (
                    <div className='limn-form-container'>
                        <h1 className='limn-header'> Active Paths </h1>
                        <ActivePathUserTable
                            processedPathList={processedPaths}
                        />
                    </div>
                )
            }
        }
        else
        {
           return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Active Paths </h1>
                    <Loading/>
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Active Paths </h1>
                <Loading/>
            </div>
        )
    }
}

export default ActivePathList
