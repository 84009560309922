import React, { useState, useEffect } from 'react';

import { Navigate } from 'react-router-dom'

import { db } from 'web_common/tsx/api/db/Api'
import ApiError from "web_common/tsx/components/misc/ApiError"
import Loading from "web_common/tsx/components/misc/Loading"

interface LogoutProps {
    /*
     * This is the url where we will redirect on logout success
     */
    redirectOnSuccess: string

    /*
     * This will get triggered on a successful log out
     */
    setLoggedIn: (loggedin: boolean) => void
}

const Logout: React.FC<LogoutProps> = (props) => {
    const [logoutSuccess, setLogoutSuccess] = useState<boolean|undefined>(undefined)

    useEffect(() => {
        db.logout(
            (response: boolean) =>
        {
            if(response === true)
            {
                setLogoutSuccess(true)
            }
            else
            {
                setLogoutSuccess(false)
            }
        })
    }, [])

    if(logoutSuccess === true)
    {
        props.setLoggedIn(false)
        return (
            <Navigate to={props.redirectOnSuccess} />
        )
    }
    else if(logoutSuccess === false)
    {
        return (
            <div>
                <ApiError />
            </div>
        )
    }
    else
    {
        return (
            <Loading/>
        )
    }
}

export default Logout
