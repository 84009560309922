import React, { useState } from 'react'

import { CompanyAdminPrivilege } from 'web_common/tsx/api/db/Models'
import { util } from 'web_common/tsx/api/db/Util'
import { validators } from 'web_common/tsx/regex/validators'

interface EmailWarningProps {
    email: string
}

const EmailWarningMessage: React.FC<EmailWarningProps> = (props) => {
    let valid: boolean = validators.isValidEmail(props.email) ||
        props.email.length === 0

    if(valid === true)
    {
        return <></>
    }
    else
    {
        return (
            <p className='limn-warning limn-margin-10'>
                You must enter a valid email address
            </p>
        )
    }
}

export interface UserEntryFormSubmitData {
    username: string,
    firstname: string,
    lastname: string,
    email: string,
    privilege: CompanyAdminPrivilege,
}

interface UserEntryFormProps {
    username: string,
    firstname: string,
    lastname: string,
    email: string,
    privilege: CompanyAdminPrivilege,
    submitButtonName: string,
    onSubmit: (obj: UserEntryFormSubmitData) => void
}

const UserEntryForm: React.FC<UserEntryFormProps> = (props) => {
    const [username, setUsername] = useState<string>(props.username)
    const [firstname, setFirstname] = useState<string>(props.firstname)
    const [lastname, setLastname] = useState<string>(props.lastname)
    const [email, setEmail] = useState<string>(props.email)
    const [privilege, setPrivilege] = useState<CompanyAdminPrivilege>(props.privilege)

    const onSubmit = (e: React.FormEvent) =>
    {
        e.preventDefault()

        if(validators.isValidEmail(email) === false)
        {
            alert("You must enter a valid email")
            return
        }
        props.onSubmit({
            username: username,
            firstname: firstname,
            lastname: lastname,
            email: email,
            privilege: privilege
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    {
        setPrivilege(
            util.convertCompanyAdminPermission(
                parseInt(
                    e.target.value)
            )
        )
    }

    return (
        <div className='limn-padded-top-50px'>
            <form onSubmit={onSubmit}>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='username'> Username: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='username'
                        name='username'
                        placeholder={'You must enter a username...'}
                        value={username}
                        onChange={(e) => { setUsername(e.target.value) }}
                        required
                    />
                </div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='firstname'> First Name: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='firstname'
                        name='firstname'
                        placeholder={'You must enter a first name...'}
                        value={firstname}
                        onChange={(e) => { setFirstname(e.target.value) }}
                        required
                    />
                </div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='lastname'> Last Name: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='lastname'
                        name='lastname'
                        placeholder={'You must enter a last name...'}
                        value={lastname}
                        onChange={(e) => { setLastname(e.target.value) }}
                        required
                    />
                </div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='email'> Email: </label>
                    <input
                        className='limn-input'
                        type='text'
                        id='email'
                        name='email'
                        placeholder={'You must enter an email...'}
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        required
                    />
                </div>
                <EmailWarningMessage
                    email={email}
                />
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='privilege'> Privilege: </label>
                    <select
                        className='limn-select-box'
                        id='select-privilege'
                        name='select-privilege'
                        value={privilege}
                        onChange={handleChange}
                    >
                        <option
                            key={CompanyAdminPrivilege.USER}
                            value={CompanyAdminPrivilege.USER}
                        >
                            USER
                        </option>
                        <option
                            key={CompanyAdminPrivilege.ADMIN}
                            value={CompanyAdminPrivilege.ADMIN}
                        >
                            ADMIN
                        </option>
                    </select>
                </div>
                <div className='limn-width-50 limn-margin-25 limn-div-centered'>
                    <button
                        className='limn-button limn-width-90 limn-padded-vert-10px'
                        type='submit'>
                        { props.submitButtonName }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default UserEntryForm
