import React from 'react'

import "web_common/css/LimnTech.css"

interface PermissionDeniedProps {

}

const PermissionDenied: React.FC<PermissionDeniedProps> = (props) => {
    return (
        <div>
            <h1 className='limn-warning limn-header'> Permission Denied </h1>
            <p className='limn-tiny-p'> Your user account does not have permission to access this site! </p>
        </div>
    )
}

export default PermissionDenied;
