import React from 'react'

import { Table } from 'react-bootstrap'

import { CompanyAdminPrivilege } from 'web_common/tsx/api/db/Models'
import { util } from 'web_common/tsx/api/db/Util'

interface UserDetailProps {
    username: string,
    firstname: string,
    lastname: string,
    email: string,
    privilege: CompanyAdminPrivilege,
    submitButtonName: string,
    onSubmit: () => void
}

const UserDetail: React.FC<UserDetailProps> = (props) => {
    return (
        <div className='limn-padded-top-50px limn-margin-10 limn-margin-right-10'>
            <h4> User Detail </h4>
            <Table hover>
                <thead>
                </thead>
                <tbody>
                    <tr>
                        <td><b> Username </b></td>
                        <td> { props.username} </td>
                    </tr>
                    <tr>
                        <td><b> First Name </b></td>
                        <td> { props.firstname} </td>
                    </tr>
                    <tr>
                        <td><b> Last Name </b></td>
                        <td> { props.lastname} </td>
                    </tr>
                    <tr>
                        <td><b> Email </b></td>
                        <td> { props.email} </td>
                    </tr>
                    <tr>
                        <td><b> Privilege </b></td>
                        <td> { util.transcodeCompanyAdminPrivilege(props.privilege) } </td>
                    </tr>
                </tbody>
            </Table>
            <div
                className='limn-div-centered limn-padded-top-50px limn-padded-bottom-10px'
                onClick={props.onSubmit}>
                <button
                    className='limn-button limn-width-50'
                >
                    { props.submitButtonName }
                </button>
            </div>
        </div>
    )
}

export default UserDetail
