import React, { useState, useEffect, useCallback } from 'react'

import { Navigate, useParams } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import {
    CompanyAdminPrivilege,
    LifemarkUserModel,
    Truck,
    RtkService,
    NtripCredential
} from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import { urls } from 'InternalUrls'
import NtripForm, { NtripFormSubmitObject } from 'components/trucks/ntrip/NtripForm'

interface NtripEditProps {

}

const NtripEdit: React.FC<NtripEditProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [truck, setTruck] = useState<Truck|undefined>(undefined)
    const [ntripCredential, setNtripCredential] = useState<NtripCredential|undefined>(undefined)
    const [rtkServiceList, setRtkServiceList] = useState<Array<RtkService>|undefined>(undefined)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    const { truck_id, ntrip_id } = useParams()

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() => {
        if(truck_id !== undefined)
        {
            const onTruck = (response: Truck|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setTruck(response)
                }
            }

            db.get_truck(truck_id, onTruck)
        }
    }, [truck_id])

    useEffect(() => {
        if(ntrip_id !== undefined)
        {
            const onNtrip = (response: NtripCredential|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setNtripCredential(response)
                }
            }

            db.get_ntrip_credential(ntrip_id, onNtrip)
        }
    }, [ntrip_id])

    useEffect(() => {
        const onRtkService = (response: Array<RtkService>|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setRtkServiceList(response)
            }
        }

        db.list_rtk_services(undefined, onRtkService)
    }, [])

    const onCreate = useCallback((obj: NtripFormSubmitObject) => {
        if(ntrip_id === undefined)
        {
            console.log("programing error... ntrip_id undefined")
            return
        }

        const onResponse = (response: NtripCredential|undefined) =>
        {
            if(response === undefined)
            {
                setShowLoading(false)
                setApiError(true)
            }
            else
            {
                setShowLoading(false)
                setSuccess(true)
            }
        }

        const onCreatedService = (response: RtkService|undefined) =>
        {
            if(response === undefined)
            {
                setShowLoading(false)
                setApiError(true)
            }
            else
            {
                db.patch_ntrip_credential(
                    ntrip_id,
                    response.id,
                    obj.username,
                    obj.password,
                    obj.host,
                    obj.port,
                    obj.stream,
                    obj.region,
                    onResponse)
            }
        }

        setShowLoading(true)
        if(obj.createNewService === true)
        {
            db.create_rtk_service(
                obj.rtkService.name,
                obj.rtkService.map_url,
                onCreatedService)
        }
        else
        {
            db.patch_ntrip_credential(
                ntrip_id,
                obj.rtkService.id,
                obj.username,
                obj.password,
                obj.host,
                obj.port,
                obj.stream,
                obj.region,
                onResponse)
        }
    }, [setShowLoading, setApiError, setSuccess, ntrip_id])

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(showLoading === true)
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Edit NTRIP Credentials </h1>
                <Loading/>
            </div>
        )
    }
    else if(success === true)
    {
        let navId: string = typeof truck_id === 'string' ? truck_id : ''

        return (
            <Navigate
                to={urls.getTrucksAssignNtrip(navId)}
            />
        )
    }
    else if(lifemarkUser !== undefined)
    {
        if(lifemarkUser.privilege !== CompanyAdminPrivilege.ADMIN || lifemarkUser.company_admin_superuser !== true)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(truck !== undefined && rtkServiceList !== undefined && ntripCredential !== undefined)
        {
            return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Edit NTRIP Credentials: { truck.limntech_name } </h1>
                    <NtripForm
                        truck={truck}
                        currentCredentials={ntripCredential}
                        rtkList={rtkServiceList}
                        onSubmit={onCreate}
                    />
                </div>
            )
        }
        else
        {
           return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Edit NTRIP Credentials </h1>
                    <Loading/>
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Edit NTRIP Credentials </h1>
                <Loading/>
            </div>
        )
    }
}

export default NtripEdit
