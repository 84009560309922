const isValidEmail = (val: string): boolean =>
{
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return regex.test(val)
}

const isPositiveInteger = (val: string): boolean =>
{
    let regex: RegExp = /^\d+$/

    return regex.test(val)
}

export const validators = {
    isValidEmail,
    isPositiveInteger,
}
