import React, { useState, useEffect } from 'react'

import { Navigate, useParams } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { CompanyAdminPrivilege, LifemarkUserModel, Truck, ProposedPathDetail } from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import { urls } from 'InternalUrls'
import PathDetail from 'components/paths/PathDetail'

interface ProposedPathDeleteProps {

}

const ProposedPathDelete: React.FC<ProposedPathDeleteProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [showLoading, setShowLoading]  = useState<boolean>(false)
    const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [trucks, setTrucks] = useState<Array<Truck>|undefined>(undefined)
    const [proposedPath, setProposedPath] = useState<ProposedPathDetail|undefined>(undefined)

    const { id } = useParams()

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() =>{
        if(lifemarkUser !== undefined)
        {
            const onTrucks = (response: Array<Truck>|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setTrucks(response)
                }
            }

            db.truck_list(lifemarkUser.company, onTrucks)
        }
    }, [lifemarkUser])

    useEffect(() => {
        if(id !== undefined)
        {
            const onProposedPath = (response: ProposedPathDetail|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setProposedPath(response)
                }
            }

            db.proposed_path_detail(id, onProposedPath)
        }
    }, [id])

    const deleteProposedPath = () =>
    {
        const onCompletion = (results: boolean) =>
        {
            if(results === false)
            {
                setShowLoading(false)
                setApiError(true)
            }
            else
            {
                setShowLoading(false)
                setDeleteSuccess(true)
            }
        }

        if(proposedPath !== undefined)
        {
            setShowLoading(true)
            db.delete_proposed_path(
                proposedPath.id,
                onCompletion)
        }
    }

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(deleteSuccess === true)
    {
        return (
            <Navigate
                to={urls.getProposedPaths()}
            />
        )
    }
    else if(showLoading === true)
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Delete Proposed Path </h1>
                <Loading/>
            </div>
        )
    }
    else if(lifemarkUser !== undefined)
    {
        if(lifemarkUser.privilege === CompanyAdminPrivilege.NONE)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(lifemarkUser.privilege === CompanyAdminPrivilege.USER)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(lifemarkUser.privilege === CompanyAdminPrivilege.ADMIN)
        {
            if(trucks !== undefined && proposedPath !== undefined)
            {
                let truckList = trucks.map(
                    (obj, idx) =>
                {
                    let assigned: boolean = proposedPath.assigned_truck_list.findIndex(
                        (truck_id) =>
                    {
                        return obj.id === truck_id
                    }) >= 0

                    return {
                        truck: obj,
                        assignment: assigned
                    }
                })

                return (
                    <div className='limn-form-container'>
                        <h1 className='limn-header'> Delete Proposed Path </h1>
                        <PathDetail
                            truckList={truckList}
                            pathPreview={undefined}
                            patternTransitions={undefined}
                            referencePath={undefined}
                            pathName={proposedPath.path_name}
                            pathDescription={proposedPath.path_description}
                            submitButtonName={'Delete Proposed Path'}
                            onSubmit={deleteProposedPath}
                        />
                    </div>
                )
            }
            else
            {
                return (
                    <div className='limn-form-container'>
                        <h1 className='limn-header'> Delete Proposed Path </h1>
                        <Loading/>
                    </div>
                )
            }
        }
        else
        {
           return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Delete Proposed Path </h1>
                    <Loading/>
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Delete Proposed Path </h1>
                <Loading/>
            </div>
        )
    }
}

export default ProposedPathDelete
