import React, { useState } from 'react';

import "web_common/css/LimnTech.css"
import { db } from 'web_common/tsx/api/db/Api'
import Loading from 'web_common/tsx/components/misc/Loading'
import { validators } from 'web_common/tsx/regex/validators'

interface PasswordResetProps {
}

const Login: React.FC<PasswordResetProps> = (props) => {
    const [email, setEmail] = useState<string>('')
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [resetRequestSuccess, setResetRequestSuccess] = useState<boolean|undefined>(undefined)

    const showWarning = () => {
        if(resetRequestSuccess === false)
        {
            return <p className='limn-warning'> There was a problem sending the password reset request </p>
        }
        else if(email.length === 0 || validators.isValidEmail(email))
        {
            return <></>
        }
        else
        {
            return <p className='limn-warning'> You must enter a valid email! </p>
        }
    }

    const onResponse = (success: boolean) => {
        setResetRequestSuccess(success)
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        if(validators.isValidEmail(email) === false)
        {
            alert("You must enter a valid email address")
            return
        }

        db.reset_password(
            email,
            onResponse)
        setShowLoading(true)
    }

    if(resetRequestSuccess === true)
    {
        return (
            <div className="limn-login-container">
                <h2 className="limn-header">Password Reset</h2>
                <hr className='limn-form-separator-100'/>
                <div>
                    <p> An email has been sent to { email } which contains instructions on how to complete the password reset process </p>
                </div>
            </div>
        );

    }
    else if(showLoading === true)
    {
        return (
            <div className="limn-login-container">
                <h2 className="limn-header">Password Reset</h2>
                <Loading/>
            </div>
        );
    }
    else
    {
        return (
            <div className="limn-login-container">
                <h2 className="limn-header">Password Reset</h2>
                <form className='limn-column-form' onSubmit={handleSubmit}>
                    <label className="limn-label-nopadd" htmlFor="email">Email:</label>
                    <input className="limn-input"
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)} required />
                    { showWarning() }
                    <button className="limn-button" type="submit">Reset Password</button>
                </form>
            </div>
        );
    }
};

export default Login;
