import React, { useState, useEffect } from 'react'

import BaseTable, { AutoResizer, SortOrder } from 'react-base-table'
import { Grid, TextField } from '@material-ui/core'
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@mui/icons-material/Search'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-base-table/styles.css'
import styled from '@emotion/styled'

import 'web_common/css/LimnTech.css'

interface DatePickerProps {
    filterStartDate: Date,
    setFilterStartDate: (d: Date) => void,
    filterEndDate: Date,
    setFilterEndDate: (d: Date) => void,
    showDateFilter: boolean
}

const TableDatePicker: React.FC<DatePickerProps> = (props) => {
    if(props.showDateFilter === false)
    {
        return <div></div>
    }
    else
    {
        return (
            <div>
                <div className='limn-div-row'>
                    <label className='limn-label-padded limn-width-300px'> Filter above data from start date: </label>
                    <DatePicker
                        wrapperClassName='date_picker'
                        className='limn-input'
                        selected={props.filterStartDate}
                        onChange={props.setFilterStartDate}
                    />
                </div>
                <div className='limn-div-row'>
                    <label className='limn-label-padded limn-width-300px'> Filter above data from end date: </label>
                    <DatePicker
                        wrapperClassName='date_picker'
                        className='limn-input'
                        selected={props.filterEndDate}
                        onChange={props.setFilterEndDate}
                    />
                </div>
            </div>
        )
    }
}

const Container = styled.div`
  height: 45vh;

  .BaseTable__header-row {
    background: #f4f4fa;
  }
`

export interface HeaderDescription {
    title: string,
    field: string,
    dateFilterable: boolean,
    width: number,
    minWidth: number,
    sortable: boolean,
    searchable: boolean
    customSort: ((a: any, b: any) => number)|undefined,
    customStringify: ((obj: any) => string)|undefined
}

const generateColumns = (
  column_desc: Array<HeaderDescription>,
  prefix: string = "column-"
) =>
{
  return new Array(column_desc.length).fill(0).map((column, columnIndex) => ({
    key: `${prefix}${columnIndex}`,
    dataKey: `${prefix}${columnIndex}`,
    title: column_desc[columnIndex].title,
    width: column_desc[columnIndex].width,
    field: column_desc[columnIndex].field,
    minWidth: column_desc[columnIndex].minWidth,
    sortable: column_desc[columnIndex].sortable,
    customSort: column_desc[columnIndex].customSort,
    customStringify: column_desc[columnIndex].customStringify,
    fixed: false
  }));
}

const generateData = (
  columns: ReturnType<typeof generateColumns>,
  data: Array<any>,
  prefix = "row-"
) =>
{
  return new Array(data.length).fill(0).map((row, rowIndex) => {
    return columns.reduce(
      (rowData: Record<string, any>, column, columnIndex) => {
        let obj_ = data[rowIndex];
        if(column.customStringify !== undefined)
        {
            rowData[column.dataKey] = column.customStringify(obj_[column.field]);
            return rowData;
        }
        else
        {
            rowData[column.dataKey] = obj_[column.field];
            return rowData;
        }
      },
      {
        id: `${prefix}${rowIndex}`
      }
    );
  });
}

interface DefaultSort {
    key: string,
    order: SortOrder
}

interface BigTableProps {
    title: string,
    headers: Array<HeaderDescription>
    rows: Array<any>
    defaultSort: DefaultSort
    showDateFilter: boolean,
    onVisibilityChange: (keys: Array<string>) => void
}

const BigTable: React.FC<BigTableProps> = (props) => {
    /*
     * Search State
     */
    const [searchValue, setSearchValue] = useState<string>("")
    const [filterStartDate, setFilterStartDate] = useState<Date>(new Date('2016-01-02'))
    const [filterEndDate, setFilterEndDate] = useState<Date>(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))

    /*
     * Sort info
     */
    const [sortBy, setSortBy] = useState(props.defaultSort)

    /*
     * Display Variable
     */
    const [filteredData, setFilteredData] = useState<Array<any>>([])

    const { headers, rows, onVisibilityChange } = props
    useEffect(() => {
        let filtered = rows.filter((obj) => {
            for(let i = 0; i < headers.length; i++)
            {
                if(headers[i].searchable === true)
                {
                    if(obj[headers[i].field] === null)
                    {
                        continue
                    }

                    if(obj[headers[i].field].toString === undefined)
                    {
                        continue
                    }

                    let val1 = obj[headers[i].field].toString().toLowerCase()
                    let val2 = searchValue.toString().toLowerCase()
                    if(val1.includes(val2) === true)
                    {
                        return true
                    }
                }
            }
            return false
        })

        filtered = filtered.filter((obj) => {
            for(let i = 0; i < headers.length; i++)
            {
                if(headers[i].dateFilterable === true)
                {
                    if(obj[headers[i].field] === undefined)
                    {
                        continue
                    }

                    let d = new Date(obj[headers[i].field])
                    if(d.getTime() < filterStartDate.getTime())
                    {
                        return false
                    }

                    if(d.getTime() > filterEndDate.getTime())
                    {
                        return false
                    }
                }
            }
            return true
        })

        let sortIdx = parseInt(sortBy.key.split('-')[1])
        if(headers[sortIdx] !== undefined)
        {
            if(headers[sortIdx].sortable === true)
            {
                const compareFcn = (a: any, b: any) =>
                {
                    let compVal = 0
                    let sortFcn = headers[sortIdx].customSort
                    let val1 = a[headers[sortIdx].field]
                    let val2 = b[headers[sortIdx].field]

                    if(sortFcn !== undefined && val1 !== undefined && val2 !== undefined)
                    {
                        compVal = sortFcn(val1, val2)
                    }

                    if(sortBy.order === 'asc')
                    {
                        return compVal * -1
                    }
                    else
                    {
                        return compVal * 1
                    }
                }

                filtered.sort(compareFcn)
            }
        }

        setFilteredData(filtered)
    }, [searchValue, filterStartDate, filterEndDate, sortBy, headers, rows])

    useEffect(() => {
        if(filteredData.length > 0)
        {
            if(filteredData[0].key === undefined)
            {
                console.log("You must defined a data key for your table if you want to register changed")
            }
            else
            {
                let keys = filteredData.map(
                    (obj, idx) =>
                {
                    return obj.key
                })

                onVisibilityChange(keys)
            }
        }
        else
        {
            onVisibilityChange(filteredData)
        }
    }, [filteredData, onVisibilityChange])

    const onColumnSort = (data: any) =>
    {
        setSortBy(data)
    }

    const columns = generateColumns(props.headers)
    const data = generateData(columns, filteredData)

    return (
        <div className='limn-padded-20'>
        <div style={{height: "50vh"}}>
            <Container>
                <Grid container>
                    <Grid item xs={10}>
                        <h4> { props.title } </h4>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            id='search'
                            label='search'
                            value={searchValue}
                            onChange={(e) => { setSearchValue(e.target.value)}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <AutoResizer>
                    {({width, height}) => (
                        <BaseTable
                            width={width}
                            height={height}
                            columns={columns}
                            data={data}
                            sortBy={sortBy}
                            onColumnSort={onColumnSort}
                            style={{fontSize:'16px'}}
                        />
                    )}
                </AutoResizer>
            </Container>
        </div>
        <span>&ensp;</span>
        <span>&ensp;</span>
        <span>&ensp;</span>
        <span>&ensp;</span>
        <div className='limn-div-right'>
            <TableDatePicker
                filterStartDate={filterStartDate}
                setFilterStartDate={setFilterStartDate}
                filterEndDate={filterEndDate}
                setFilterEndDate={setFilterEndDate}
                showDateFilter={props.showDateFilter}
            />
        </div>
        </div>
    )
}

export default BigTable
