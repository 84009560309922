import React, { useState, useEffect, useCallback } from 'react'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { Company, CompanyAdminPrivilege, LifemarkUserModel } from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import UserEntryForm, { UserEntryFormSubmitData } from 'components/user/UserEntryForm'
import CreateUserSuccess from 'components/user/create/CreateUserSuccess'

interface ErrorMessageProps {
    msg: Array<string>
}

const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
    if(props.msg.length === 0)
    {
        return <></>
    }
    else
    {
        return (
            <div className='limn-warning limn-margin-10'>
                <p> User Creation Failed! </p>
                <ul>
                    {
                        props.msg.map(
                            (obj, idx) =>
                        {
                            return <li key={idx}> { obj } </li>
                        })
                    }
                </ul>
            </div>
        )
    }
}

interface CreateUserProps {

}

const CreateUser: React.FC<CreateUserProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [success, setSuccess] = useState<UserEntryFormSubmitData|undefined>(undefined)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [company, setCompany] = useState<Company|undefined>(undefined)
    const [errorMessage, setErrorMessage] = useState<Array<string>>([])

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() => {
        if(lifemarkUser !== undefined)
        {
            const onCompany = (response: Company|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setCompany(response)
                }
            }

            db.company_detail(lifemarkUser.company, onCompany)
        }
    }, [lifemarkUser])

    const onSubmit = useCallback((obj: UserEntryFormSubmitData) => {
        const onResponse = (response: boolean, msg: Array<string>) =>
        {
            if(response === true)
            {
                setSuccess(obj)
                setErrorMessage(msg)
                setShowLoading(false)
            }
            else
            {
                setErrorMessage(msg)
                setShowLoading(false)
            }
        }

        setShowLoading(true)
        if(company !== undefined)
        {
            db.create_user(
                company.id,
                obj.username,
                obj.firstname,
                obj.lastname,
                obj.email,
                obj.privilege,
                onResponse)
        }
    }, [company])

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(showLoading === true)
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Create User </h1>
                <Loading/>
            </div>
        )
    }
    else if(success !== undefined)
    {
        return (
            <CreateUserSuccess
                data={success}
            />
        )
    }
    else if(lifemarkUser !== undefined)
    {
        if(lifemarkUser.privilege !== CompanyAdminPrivilege.ADMIN)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(company !== undefined)
        {
            return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Create User </h1>
                    <UserEntryForm
                        username={''}
                        firstname={''}
                        lastname={''}
                        email={''}
                        privilege={CompanyAdminPrivilege.USER}
                        submitButtonName={'Create User'}
                        onSubmit={onSubmit}
                    />
                    <ErrorMessage
                        msg={errorMessage}
                    />
                </div>
            )
        }
        else
        {
           return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Create User </h1>
                    <Loading/>
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Create User </h1>
                <Loading/>
            </div>
        )
    }
}

export default CreateUser
