import React from 'react'

import "web_common/css/LimnTech.css"

interface PageNotFoundProps {

}

const PageNotFound: React.FC<PageNotFoundProps> = (props) => {
    return (
        <div>
            <h1 className="limn-warning limn-header"> Page Not Found </h1>
            <p className="limn-tiny-p"> We could not find the URL you are looking for! Try using the navigation buttons at the top to navigate to the correct page. If you are still experiencing problems, please contact the LimnTech team! </p>
        </div>
    )
}

export default PageNotFound;
