import React from 'react';

import "web_common/css/LimnTech.css"

interface ChangePasswordSuccessProps {
}

const ChangePasswordSuccess: React.FC<ChangePasswordSuccessProps> = (props) => {
    return (
        <div className="limn-login-container">
            <h2 className="limn-header">Change Password Success!</h2>
            <p> You have successfully changed your password! Please use the navigation buttons at the top of the page if you wish to continue using the website! </p>
        </div>
    );
};

export default ChangePasswordSuccess;
