import React, { useState } from 'react';

import { Link } from 'react-router-dom'

import "web_common/css/LimnTech.css"
import { db } from 'web_common/tsx/api/db/Api'
import Loading from 'web_common/tsx/components/misc/Loading'

interface LoginProps {
    /*
     * If provided, this will provide the ability to
     * internally link to a forgot password url form
     */
    forgotPasswordUrl: string|undefined

    /*
     * This will get triggered on a successful login
     */
    setLoggedIn: (loggedin: boolean) => void
}

const Login: React.FC<LoginProps> = (props) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [credentialError, setCredentialError] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    const showCredentialError = () =>
    {
        if(credentialError)
        {
            return (
                <>
                <p className='limn-warning'> There was a problem with your provided credentials.</p>
                </>
            )
        }
        else
        {
            return <></>
        }
    }
    const showForgotPasswordUrl = () =>
    {
        if(props.forgotPasswordUrl !== undefined)
        {
            return (
                <div className='limn-div-right limn-padded-top-10px'>
                    <Link to={props.forgotPasswordUrl}> Forgot Password </Link>
                </div>
            )
        }
        else
        {
            return <></>
        }
    }

    const handleLogin = (e: React.FormEvent) =>
    {
        e.preventDefault()

        let onResponse = (response: boolean) =>
        {
            if(response)
            {
                props.setLoggedIn(true)
            }
            else
            {
                setShowLoading(false)
                setCredentialError(true)
            }
        }

        db.login(username, password, onResponse)
        setShowLoading(true)
    };

    if(showLoading)
    {
        return (
            <div>
                <Loading/>
            </div>
        )
    }
    else
    {
        return (
            <div className="limn-login-container">
                <h2 className="limn-header">Login</h2>
                <form className='limn-column-form' onSubmit={handleLogin}>
                    <label className="limn-label-nopadd" htmlFor="username">Username:</label>
                    <input className="limn-input" type="text" id="username" name="username" value={username} onChange={(e)=>setUsername(e.target.value)} required />
                    <label className="limn-label-nopad" htmlFor="password">Password:</label>
                    <input className="limn-input" type="password" id="password" name="password" value={password} onChange={(e)=>setPassword(e.target.value)} required />
                    <button className="limn-button" type="submit">Login</button>
                </form>
                { showForgotPasswordUrl() }
                { showCredentialError() }
            </div>
        );
    }
};

export default Login;
