import React, { useState } from 'react';

import { Navigate } from 'react-router-dom'

import "web_common/css/LimnTech.css"
import { db } from 'web_common/tsx/api/db/Api'
import Loading from 'web_common/tsx/components/misc/Loading'

interface ChangePasswordProps {
    changePasswordSuccessUrl: string
}

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {
    const [currentPassword, setCurrentPassword] = useState<string>('')
    const [newPassword1, setNewPassword1] = useState<string>('')
    const [newPassword2, setNewPassword2] = useState<string>('')
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [passwordErrors, setPasswordErrors] = useState<Array<string>>([])
    const [passwordChangeSuccess, setPasswordChangeSuccess] = useState<boolean>(false)

    const showPasswordErrors = () =>
    {
        if(passwordErrors.length > 0)
        {
            return (
                <div className="limn-warning">
                    <p> Password change failed! </p>
                    <ul>
                        {
                            passwordErrors.map(
                                (obj, idx) =>
                            {
                                return <li key={idx}> { obj } </li>
                            })
                        }
                    </ul>
                </div>
            )
        }
        else
        {
            return <></>
        }
    }

    const handleSubmit = (e: React.FormEvent) =>
    {
        e.preventDefault()

        const onResponse = (success: boolean, error: Array<string>) =>
        {
            if(success === true)
            {
                setPasswordChangeSuccess(true)
            }
            else
            {
                setPasswordErrors(error)
            }
            setShowLoading(false)
        }

        setShowLoading(true)
        db.change_password(
            currentPassword,
            newPassword1,
            newPassword2,
            onResponse)
    };

    if(showLoading)
    {
        return (
            <div>
                <Loading/>
            </div>
        )
    }
    else if(passwordChangeSuccess)
    {
        return <Navigate to={props.changePasswordSuccessUrl}/>
    }
    else
    {
        return (
            <div className="limn-login-container">
                <h2 className="limn-header">Change Password</h2>
                <form className='limn-column-form' onSubmit={handleSubmit}>
                    <label className="limn-label-nopadd" htmlFor="old-password">Old Password:</label>
                    <input className="limn-input" type="password" id="old-password" name="old-password" value={currentPassword} onChange={(e)=>setCurrentPassword(e.target.value)} required />
                    <label className="limn-label-nopad" htmlFor="new-password">New Password:</label>
                    <input className="limn-input" type="password" id="new-password" name="new-password" value={newPassword1} onChange={(e)=>setNewPassword1(e.target.value)} required />
                    <label className="limn-label-nopad" htmlFor="new-password2">Re-enter New Password:</label>
                    <input className="limn-input" type="password" id="new-password2" name="new-password2" value={newPassword2} onChange={(e)=>setNewPassword2(e.target.value)} required />
                    { showPasswordErrors() }
                    <button className="limn-button" type="submit">Change Password</button>
                </form>
            </div>
        );
    }
};

export default ChangePassword;
