import React, { useState, useEffect } from 'react'

import { Navigate, useParams } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { CompanyAdminPrivilege, LifemarkUserModel, Truck, ProcessedPathDetail, PathPreview, AuthToken } from 'web_common/tsx/api/db/Models'
import { PatternTransitionResponse, service_api } from 'web_common/tsx/api/service/Api'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import { urls } from 'InternalUrls'
import PathDetail from 'components/paths/PathDetail'

interface ActivePathInfoProps {

}

const ActivePathInfo: React.FC<ActivePathInfoProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [infoSuccess, setInfoSuccess] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [trucks, setTrucks] = useState<Array<Truck>|undefined>(undefined)
    const [activePath, setActivePath] = useState<ProcessedPathDetail|undefined>(undefined)
    const [previewPath, setPreviewPath] = useState<PathPreview|undefined>(undefined)
    const [userToken, setUserToken] = useState<AuthToken|undefined>(undefined)
    const [patternTransitions, setPatternTransitions] = useState<PatternTransitionResponse|undefined>(undefined)

    const { id } = useParams()

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() =>{
        if(lifemarkUser !== undefined)
        {
            const onTrucks = (response: Array<Truck>|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setTrucks(response)
                }
            }

            db.truck_list(lifemarkUser.company, onTrucks)
        }
    }, [lifemarkUser])

    useEffect(() => {
        if(id !== undefined)
        {
            const onActivePath = (response: ProcessedPathDetail|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setActivePath(response)
                }
            }

            db.processed_path_detail(id, onActivePath)
        }
    }, [id])

    useEffect(() => {
        if(activePath !== undefined)
        {
            const onPreview = (response: PathPreview|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setPreviewPath(response)
                }
            }

            db.preview_processed_path(
                activePath.preview_url,
                onPreview)
        }
    }, [activePath])

    useEffect(() => {
        if(lifemarkUser !== undefined)
        {
            let onResponse = (response: AuthToken|undefined) =>
            {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setUserToken(response)
                }
            }

            db.auth_token(onResponse)
        }
    }, [lifemarkUser])

    useEffect(() => {
        if(activePath !== undefined && userToken !== undefined)
        {
            const onTransition = (response: PatternTransitionResponse) =>
            {
                if(response.error !== undefined)
                {
                    console.log(response.error)
                    setApiError(true)
                }
                else
                {
                    setPatternTransitions(response)
                }
            }

            service_api.preview_transitions(
                userToken.token,
                activePath.id,
                onTransition)
        }
    }, [activePath, userToken])

    const infoActivePath = () =>
    {
        setInfoSuccess(true)
    }

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(infoSuccess === true)
    {
        return (
            <Navigate
                to={urls.getActivePaths()}
            />
        )
    }
    else if(lifemarkUser !== undefined)
    {
        if(lifemarkUser.privilege === CompanyAdminPrivilege.NONE)
        {
            return (
                <PermissionDenied/>
            )
        }
        if(trucks !== undefined && activePath !== undefined && previewPath !== undefined && patternTransitions !== undefined)
        {
            let truckList = trucks.map(
                (obj, idx) =>
            {
                let assigned: boolean = activePath.assigned_truck_list.findIndex(
                    (truck_id) =>
                {
                    return obj.id === truck_id
                }) >= 0

                return {
                    truck: obj,
                    assignment: assigned
                }
            })

            return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Active Path Info </h1>
                    <PathDetail
                        truckList={truckList}
                        pathPreview={previewPath}
                        patternTransitions={patternTransitions.transitions}
                        referencePath={patternTransitions.reference_path}
                        pathName={activePath.path_name}
                        pathDescription={activePath.path_description}
                        submitButtonName={'Return to Active Paths'}
                        onSubmit={infoActivePath}
                    />
                </div>
            )
        }
        else
        {
           return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Active Path Info </h1>
                    <Loading/>
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Active Path Info </h1>
                <Loading/>
            </div>
        )
    }
}

export default ActivePathInfo
