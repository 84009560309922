import React, { useState } from 'react'

import { Table } from 'react-bootstrap'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { Truck, PathPreview } from 'web_common/tsx/api/db/Models'
import { PatternTransitions } from 'web_common/tsx/api/service/Api'
import MapOverlay, { EncodedPath } from 'web_common/tsx/components/misc/MapOverlay'

interface TruckPair {
    truck: Truck,
    assignment: boolean
}

interface TruckAssignmentProps {
    truckList: Array<TruckPair>|undefined
}

interface PathPreviewProps {
    pathPreview: PathPreview|undefined,
    patternTransitions: Array<PatternTransitions>|undefined,
    referencePath: EncodedPath|undefined
}

const PathPreviewMap: React.FC<PathPreviewProps> = (props) => {
    const [modifiedTransitions, setModifiedTransitions] = useState<Array<PatternTransitions>>(() =>
    {
        if(props.patternTransitions === undefined)
        {
            return []
        }
        else
        {
            return props.patternTransitions
        }
    })

    if(props.pathPreview === undefined)
    {
        return <></>
    }
    else
    {
        let showAutomation: boolean = false
        let refPath: Array<EncodedPath> = []
        if(props.referencePath !== undefined)
        {
            showAutomation = true
            refPath.push(props.referencePath)
        }

        return (
            <div>
                <div className='limn-div-centered limn-padded-vert-10px'>
                    <MapOverlay
                        encodedPaths={[{
                            path_list: props.pathPreview.path_list,
                            precision: props.pathPreview.precision,
                        }]}
                        patternTransitionReference={refPath}
                        patternTransitions={modifiedTransitions}
                        setPatternTransitions={setModifiedTransitions}
                        transitionEditable={false}
                        defaultTransitionColor={"unknown"}
                        showPatternAutomation={showAutomation}
                        loaded={ true }
                    />
                </div>
                <div>
                    <hr className='limn-padded-vert-10px limn-form-separator-80'/>
                </div>
            </div>
        )
    }
}


const TruckAssignmentTable: React.FC<TruckAssignmentProps> = (props) => {
    if(props.truckList === undefined || props.truckList.length === 0)
    {
        return <></>
    }
    else
    {
        let rowMap = props.truckList.map(
            (obj, idx) =>
        {
            let assigned = <></>
            if(obj.assignment === true)
            {
                assigned = <CheckCircleIcon/>
            }

            return (
                <tr key={idx}>
                    <td> { obj.truck.customer_name } </td>
                    <td> { obj.truck.limntech_name } </td>
                    <td> {assigned } </td>
                </tr>
            )
        })

        return (
            <div>
                <div className='limn-padded-10p limn-padded-vert-5p'>
                    <h3> Truck Assignment List </h3>
                    <Table hover className='limn-bigfont'>
                        <thead>
                            <tr>
                                <td>
                                    Truck Name
                                </td>
                                <td>
                                    LimnTech Truck Number
                                </td>
                                <td>
                                    Truck Assignment
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            { rowMap }
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }
}

interface PathDetailProps {
    truckList: Array<TruckPair>|undefined,
    pathPreview: PathPreview|undefined,
    patternTransitions: Array<PatternTransitions>|undefined,
    referencePath: EncodedPath|undefined,
    pathName: string,
    pathDescription: string,
    submitButtonName: string,
    onSubmit: () => void,
}

const PathDetail: React.FC<PathDetailProps> = (props) => {
    return (
        <div className='limn-padded-top-50px'>
            <div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor='path-name'> Path Name: </label>
                    <p className='limn-input-ro'> { props.pathName } </p>
                </div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor="path-description"> Path Description: </label>
                    <p className='limn-input-ro'> { props.pathDescription } </p>
                </div>
                <TruckAssignmentTable
                    truckList={props.truckList}
                />
                <PathPreviewMap
                    pathPreview={props.pathPreview}
                    patternTransitions={props.patternTransitions}
                    referencePath={props.referencePath}
                />
                <div className='limn-width-50 limn-margin-25 limn-div-centered'>
                    <button
                        className='limn-button limn-width-90 limn-padded-vert-10px'
                        onClick={()=>{props.onSubmit()}}>
                        { props.submitButtonName }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PathDetail
