import React from 'react'

import { Table } from 'react-bootstrap'

interface PathCountTableProps {
    trucks: number,
    activePaths: number,
    proposedPaths: number,
    archivedPaths: number
}

const PathCountTable: React.FC<PathCountTableProps> = (props) => {
    return (
        <div className='limn-padded-10p limn-padded-vert-5p'>
            <Table hover>
                <thead>
                </thead>

                <tbody className='limn-bigfont'>
                    <tr>
                        <td> Trucks </td>
                        <td> { props.trucks } </td>
                    </tr>
                    <tr>
                        <td> Active Paths </td>
                        <td> { props.activePaths } </td>
                    </tr>
                    <tr>
                        <td> Archived Paths </td>
                        <td> { props.archivedPaths } </td>
                    </tr>
                    <tr>
                        <td> Proposed Paths </td>
                        <td> { props.proposedPaths } </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default PathCountTable
