import React, { useState, useEffect, useCallback } from 'react'

import { Navigate, useParams } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { CompanyAdminPrivilege, LifemarkUserModel } from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import { urls } from 'InternalUrls'
import UserDetail from 'components/user/UserDetail'

interface ErrorMessageProps {
    msg: Array<string>
}

const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
    if(props.msg.length === 0)
    {
        return <></>
    }
    else
    {
        return (
            <div className='limn-warning limn-margin-10'>
                <p> Delete User Failed! </p>
                <ul>
                    {
                        props.msg.map(
                            (obj, idx) =>
                        {
                            return <li key={idx}> { obj } </li>
                        })
                    }
                </ul>
            </div>
        )
    }
}

interface DeleteUserProps {

}

const DeleteUser: React.FC<DeleteUserProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [userToEdit, setUserToEdit] = useState<LifemarkUserModel|undefined>(undefined)
    const [errorMessage, setErrorMessage] = useState<Array<string>>([])

    const { id } = useParams()

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() => {
        if(id !== undefined)
        {
            let onResponse = (obj: LifemarkUserModel|undefined) =>
            {
                if(obj === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setUserToEdit(obj)
                }
            }

            db.get_user(
                id,
                onResponse)
        }
    }, [id])

    const onSubmit = useCallback(() => {
        if(userToEdit !== undefined && lifemarkUser !== undefined)
        {
            const onResponse = (success: boolean, error: Array<string>) =>
            {
                if(success === true)
                {
                    setSuccess(true)
                    setErrorMessage(error)
                    setShowLoading(false)
                }
                else
                {
                    setErrorMessage(error)
                    setShowLoading(false)
                }
            }

            db.delete_user(
                userToEdit.id.toString(),
                onResponse)
        }
    }, [userToEdit, lifemarkUser])

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(showLoading === true)
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Delete User </h1>
                <Loading/>
            </div>
        )
    }
    else if(success === true)
    {
        return (
            <Navigate
                to={urls.getUserAccess()}
            />
        )
    }
    else if(lifemarkUser !== undefined)
    {
        if(lifemarkUser.privilege !== CompanyAdminPrivilege.ADMIN)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(userToEdit !== undefined)
        {
            return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Delete User </h1>
                    <UserDetail
                        username={userToEdit.username}
                        firstname={userToEdit.first_name}
                        lastname={userToEdit.last_name}
                        email={userToEdit.email}
                        privilege={userToEdit.privilege}
                        submitButtonName={'Delete User'}
                        onSubmit={onSubmit}
                    />
                    <ErrorMessage
                        msg={errorMessage}
                    />
                </div>
            )
        }
        else
        {
           return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Delete User </h1>
                    <Loading/>
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Delete User </h1>
                <Loading/>
            </div>
        )
    }
}

export default DeleteUser
