import React, { useState, useEffect } from 'react'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { Company, CompanyAdminPrivilege, LifemarkUserModel } from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import ProcessedPathExportList from 'web_common/tsx/components/export/ProcessedPathExportList'

import { urls } from 'InternalUrls'

interface PathExportProps {

}

const PathExport: React.FC<PathExportProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [company, setCompany] = useState<Company|undefined>(undefined)

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() => {
        if(lifemarkUser !== undefined)
        {
            const onCompany = (response: Company|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setCompany(response)
                }
            }

            db.company_detail(lifemarkUser.company, onCompany)
        }
    }, [lifemarkUser])

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(lifemarkUser !== undefined)
    {
        if(lifemarkUser.privilege === CompanyAdminPrivilege.NONE)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(lifemarkUser.privilege === CompanyAdminPrivilege.USER)
        {
            return (
                <PermissionDenied/>
            )
        }
        else if(company !== undefined)
        {
            return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Path Export </h1>
                    <ProcessedPathExportList
                        company={company}
                        getExportPreviewUrl={urls.getExportPreviewUrl}
                        user={lifemarkUser}
                    />
                </div>
            )
        }
        else
        {
           return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Path Export </h1>
                    <Loading/>
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Path Export </h1>
                <Loading/>
            </div>
        )
    }
}

export default PathExport
