import React, { useState, useEffect, useCallback} from 'react'

import { Table } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { db } from 'web_common/tsx/api/db/Api'
import { ProcessedPathSample, PathPreview } from 'web_common/tsx/api/db/Models'
import MapOverlay, { EncodedPath } from 'web_common/tsx/components/misc/MapOverlay'

interface TotalMapProps {
    show: boolean,
    apiError: boolean,
    totalPathsToFetch: number|undefined
    encodedPaths: Array<EncodedPath>
}

const TotalMap: React.FC<TotalMapProps> = (props) =>
{
    const { totalPathsToFetch, encodedPaths } = props

    const getMessage = useCallback(() => {
        if(totalPathsToFetch === undefined)
        {
            return 'Loading Path Information...'
        }
        else if(encodedPaths.length < totalPathsToFetch)
        {
            return 'Loaded ' + encodedPaths.length.toString() + ' of ' +
                totalPathsToFetch.toString() + ' paths...'
        }
        else
        {
            return 'Map Overlay'
        }
    }, [totalPathsToFetch, encodedPaths])
    const getLoaded = useCallback(() => {
        if(totalPathsToFetch === undefined)
        {
            return false
        }

        return totalPathsToFetch === encodedPaths.length
    }, [totalPathsToFetch, encodedPaths])

    if(props.show === false)
    {
        return <></>
    }

    return (
        <div className='limn-padded-bottom-50px'>
            <div className='limn-div-centered'>
                <p> { getMessage() } </p>
            </div>
            <div className='limn-div-centered'>
                <MapOverlay
                    encodedPaths={props.encodedPaths}
                    patternTransitionReference={[]}
                    patternTransitions={[]}
                    setPatternTransitions={(val)=>{}}
                    defaultTransitionColor={"unknown"}
                    transitionEditable={false}
                    showPatternAutomation={false}
                    loaded={getLoaded()}
                />
            </div>
        </div>
    )
}

interface TotalMapOverlayProps {
    company: string
}

enum PathTypeEnum
{
    ACTIVE = 0,
    ARCHIVED = 1,
    ALL = 2
}

const TotalMapOverlay: React.FC<TotalMapOverlayProps> = (props) =>
{
    const { company } = props

    const [encodedPaths, setEncodedPaths] = useState<Array<EncodedPath>>([])
    const [showMap, setShowMap] = useState<boolean>(false)
    const [showButton, setShowButton] = useState<string|undefined>('Generate Path Map')
    const [apiError, setApiError] = useState<boolean>(false)
    const [fetchUrls, setFetchUrls] = useState<Array<string>|undefined>(undefined)
    const [pathTypeFilter, setPathTypeFilter] = useState<PathTypeEnum>(PathTypeEnum.ALL)
    const [filterStartDate, setFilterStartDate] = useState<Date>(() =>
    {
        let cachedStartDate = localStorage.getItem('home-page-path-filter-start-date')
        if(cachedStartDate === null)
        {
            return new Date('2016-01-02')
        }
        else
        {
            return new Date(cachedStartDate)
        }
    })
    const [filterEndDate, setFilterEndDate] = useState<Date>(() =>
    {
        let cachedEndDate = localStorage.getItem('home-page-path-filter-end-date')
        if(cachedEndDate === null)
        {
            return new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        }
        else
        {
            return new Date(cachedEndDate)
        }
    })

    const handlePathTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
    {
        setPathTypeFilter(parseInt(e.target.value))
    }, [setPathTypeFilter])

    const setStartDate = useCallback((d: Date) =>
    {
        setFilterStartDate(d)
    }, [setFilterStartDate])

    const setEndDate = useCallback((d: Date) =>
    {
        setFilterEndDate(d)
    }, [setFilterEndDate])

    const onSaveStartDate = useCallback(() =>
    {
        localStorage.setItem('home-page-path-filter-start-date', filterStartDate.toDateString())
        alert("Your current path filter start date is now saved to your browser!")
    }, [filterStartDate])

    const onResetStartDate = useCallback(() =>
    {
        localStorage.removeItem('home-page-path-filter-start-date')
        setFilterStartDate(new Date('2016-01-02'))
        alert("The saved path filter start date is now removed from your browser!")
    }, [setFilterStartDate])

    const onSaveEndDate = useCallback(() =>
    {
        localStorage.setItem('home-page-path-filter-end-date', filterEndDate.toDateString())
        alert("Your current path filter end date is now saved to your browser!")
    }, [filterEndDate])

    const onResetEndDate = useCallback(() =>
    {
        localStorage.removeItem('home-page-path-filter-end-date')
        setFilterEndDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
        alert("The saved path filter end date is now removed from your browser!")
    }, [setFilterEndDate])

    const getTotalPathsToFetch = useCallback(() =>
    {
        if(fetchUrls === undefined)
        {
            return undefined
        }
        else
        {
            return fetchUrls.length
        }
    }, [fetchUrls])


    useEffect(() =>
    {
        if(fetchUrls !== undefined)
        {
            let encodedPathArr: Array<EncodedPath> = [...encodedPaths]
            const onResponse = (response: PathPreview|undefined) =>
            {
                if(response === undefined)
                {
                    console.log("failure to load processed path")
                    setApiError(true)
                }
                else
                {
                    encodedPathArr.push({
                        path_list: response.path_list,
                        precision: response.precision
                    })

                    let arrCpy = [...encodedPathArr]
                    setEncodedPaths(arrCpy)
                }
            }

            if(encodedPaths.length % 100 === 0)
            {
                console.log("Requesting paths with starting index: " + String(encodedPaths.length))
                for(let i = encodedPaths.length; i < encodedPaths.length + 100; i++)
                {
                    if(i < fetchUrls.length)
                    {
                        db.preview_processed_path(
                            fetchUrls[i],
                            onResponse)
                    }
                }
            }
        }
    }, [fetchUrls, setEncodedPaths, encodedPaths])

    useEffect(() =>
    {
        if(showButton === undefined && fetchUrls !== undefined)
        {
            if(fetchUrls.length === encodedPaths.length)
            {
                setShowButton('Regenerate Path Map')
            }
        }
    }, [fetchUrls, encodedPaths, setShowButton, showButton])

    const onGenerate = useCallback(() =>
    {
        setShowButton(undefined)
        setShowMap(false)
        setApiError(false)
        setFetchUrls(undefined)
        setEncodedPaths([])
        setShowMap(true)

        const onResponse = (response: Array<ProcessedPathSample>|undefined) =>
        {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setFetchUrls(response.map(
                    (obj, idx) =>
                {
                    return obj.preview_url
                }))
            }
        }

        let active_flag: boolean|undefined = undefined
        if(pathTypeFilter === PathTypeEnum.ACTIVE)
        {
            active_flag = true
        }
        else if(pathTypeFilter === PathTypeEnum.ARCHIVED)
        {
            active_flag = false
        }

        db.processed_path_sample_list(
            company,
            active_flag,
            filterStartDate,
            filterEndDate,
            onResponse)
    }, [setFetchUrls, pathTypeFilter, company, filterStartDate, filterEndDate])

    const showButtonCb = useCallback(() =>
    {
        if(showButton !== undefined)
        {
            return (
                <div className='limn-div-centered'>
                    <button
                        onClick={onGenerate}
                        className='limn-button limn-width-90'
                    >
                        { showButton }
                    </button>
                </div>
            )
        }
        else
        {
            return <></>
        }
    }, [showButton, onGenerate])

    return (
        <div>
            <h4> Path Overlay Map </h4>
            <div className='limn-padded-vert-5p'>
                <Table hover>
                    <thead>
                    </thead>
                    <tbody className='limn-bigfont'>
                        <tr>
                            <td> All Paths </td>
                            <td>
                                <input
                                    type='radio'
                                    name='radio-group'
                                    value={PathTypeEnum.ALL}
                                    checked={pathTypeFilter===PathTypeEnum.ALL}
                                    onChange={handlePathTypeChange}
                                />
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td> Active Paths </td>
                            <td>
                                <input
                                    type='radio'
                                    name='radio-group'
                                    value={PathTypeEnum.ACTIVE}
                                    checked={pathTypeFilter===PathTypeEnum.ACTIVE}
                                    onChange={handlePathTypeChange}
                                />
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td> Archived Paths </td>
                            <td>
                                <input
                                    type='radio'
                                    name='radio-group'
                                    value={PathTypeEnum.ARCHIVED}
                                    checked={pathTypeFilter===PathTypeEnum.ARCHIVED}
                                    onChange={handlePathTypeChange}
                                />
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td> Filter For Recording Begin Date </td>
                            <td>
                                <DatePicker
                                    wrapperClassName='date_picker'
                                    className='limn-input'
                                    selected={filterStartDate}
                                    onChange={setStartDate}
                                />
                            </td>
                            <td>
                                <button
                                    className='limn-button'
                                    onClick={onSaveStartDate}
                                >
                                    Save
                                </button>
                            </td>
                            <td>
                                <button
                                    className='limn-button'
                                    onClick={onResetStartDate}
                                >
                                    Reset
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td> Filter For Recording End Date </td>
                            <td>
                                <DatePicker
                                    wrapperClassName='date_picker'
                                    className='limn-input'
                                    selected={filterEndDate}
                                    onChange={setEndDate}
                                />
                            </td>
                            <td>
                                <button
                                    onClick={onSaveEndDate}
                                    className='limn-button'
                                >
                                    Save
                                </button>
                            </td>
                            <td>
                                <button
                                    onClick={onResetEndDate}
                                    className='limn-button'
                                >
                                    Reset
                                </button>
                            </td>

                        </tr>
                    </tbody>
                </Table>
                { showButtonCb() }
            </div>
            <div>
                <TotalMap
                    show={showMap}
                    encodedPaths={encodedPaths}
                    totalPathsToFetch={getTotalPathsToFetch()}
                    apiError={apiError}
                />
            </div>
        </div>
    )
}

export default TotalMapOverlay
