import React from 'react';
import { Link, NavLink as NLink } from 'react-router-dom'
import { Navbar, NavLink, Nav } from 'react-bootstrap'

import 'web_common/bootstrap/css/bootstrap.css'
import 'web_common/css/Banner.css';
import limn_logo from 'web_common/img/limntech_logo.png'
import { LifemarkUserModel, CompanyAdminPrivilege } from 'web_common/tsx/api/db/Models'

import { urls } from 'InternalUrls'

interface LimnTechEmployeeBannerProps {

}

const LimnTechEmployeeBannerButtons: React.FC<LimnTechEmployeeBannerProps> = (props) => {
    return (
        <div className='mr-auto'>
            <Nav className='lts-nav-div'>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getRoot()}>
                        Home
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getTrucks()}>
                        Trucks
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getActivePaths()}>
                        Active Paths
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getArchivedPaths()}>
                        Archived Paths
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getProposedPaths()}>
                        Proposed Paths
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getImport()}>
                        Import Paths
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getExport()}>
                        Export Paths
                </NavLink>
            </Nav>

            <Nav className='lts-nav-div'>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-med lts-banner-link'
                    to={urls.getUserAccess()}>
                        User Access
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-med lts-banner-link'
                    to={urls.getAdmin()}>
                        Admin
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-med lts-banner-link'
                    to={urls.getChangePassword()}>
                        Change Password
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-med lts-banner-link'
                    to={urls.getLogout()}>
                        Logout
                </NavLink>
            </Nav>
        </div>
    )
}

interface AdministratorBannerProps {

}

const AdministratorBannerButtons: React.FC<AdministratorBannerProps> = (props) => {
    return (
        <div className='mr-auto'>
            <Nav className='lts-nav-div'>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getRoot()}>
                        Home
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getTrucks()}>
                        Trucks
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getActivePaths()}>
                        Active Paths
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getArchivedPaths()}>
                        Archived Paths
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getProposedPaths()}>
                        Proposed Paths
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getImport()}>
                        Import Paths
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getExport()}>
                        Export Paths
                </NavLink>
            </Nav>

            <Nav className='lts-nav-div'>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-big lts-banner-link'
                    to={urls.getUserAccess()}>
                        User Access
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-big lts-banner-link'
                    to={urls.getChangePassword()}>
                        Change Password
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-big lts-banner-link'
                    to={urls.getLogout()}>
                        Logout
                </NavLink>
            </Nav>
        </div>
    )
}

interface UserBannerProps {

}

const UserBannerButtons: React.FC<UserBannerProps> = (props) => {
    return (
        <div className='mr-auto'>
            <Nav className='lts-nav-div'>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getRoot()}>
                        Home
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getTrucks()}>
                        Trucks
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getActivePaths()}>
                        Active Paths
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getArchivedPaths()}>
                        Archived Paths
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getProposedPaths()}>
                        Proposed Paths
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getChangePassword()}>
                        Change Password
                </NavLink>
                <NavLink
                    as={NLink}
                    className='lts-nav-button lts-nav-button-small lts-banner-link'
                    to={urls.getLogout()}>
                        Logout
                </NavLink>
            </Nav>
        </div>
    )
}

interface BannerProps {
    user: LifemarkUserModel|undefined
}

const Banner: React.FC<BannerProps> = (props) => {
    let nav_buttons = <></>
    if(props.user !== undefined)
    {
        if(props.user.privilege === CompanyAdminPrivilege.USER)
        {
            nav_buttons = <UserBannerButtons/>
        }
        else if(props.user.privilege === CompanyAdminPrivilege.ADMIN)
        {
            if(props.user.company_admin_superuser === true)
            {
                nav_buttons = <LimnTechEmployeeBannerButtons/>
            }
            else
            {
                nav_buttons = <AdministratorBannerButtons/>
            }
        }
    }

    return (
        <Navbar expand='lg lts-banner'>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='mr-auto lts-img-container'>
                <Navbar.Brand as={Link} to={urls.getRoot()}>
                    <img className="lts-banner-img" src={limn_logo} alt={''}/>
                </Navbar.Brand>
            </Nav>
            { nav_buttons }
            <Nav className='nav-right lts-img-container'>
                <Navbar.Brand as={Link} to={urls.getRoot()}>
                    <img className="lts-banner-img" src={limn_logo} alt={''}/>
                </Navbar.Brand>
            </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Banner;
