import React, { useState, useEffect, useCallback } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Link } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { ProcessedPathInfo, Truck, Company } from 'web_common/tsx/api/db/Models'
import BigTable, { HeaderDescription }  from 'web_common/tsx/components/misc/BigTable'

import { util } from 'web_common/tsx/components/misc/Util'
import { urls } from 'InternalUrls'

interface ArchivedPathAdminTableProps {
    company: Company,
    processedPathList: Array<ProcessedPathInfo>,
    onActivatePending: () => void,
    onActivateSuccess: () => void,
    onActivateFailure: () => void,
}

/*
 * To make this work with the checkboxes and visbility changes we must consider
 * a few things,
 *
 * First, the rows and headers must be state variables because if they are
 * generated on every render that will cause the big table to rerender
 *
 * Next, the checkboxes must be dynamically checked / unchecked in an effect
 * to match the archived path list.
 *
 * Making a mistake here may cause infinite rerenders
 */
const ArchivedPathAdminTable: React.FC<ArchivedPathAdminTableProps> = (props) => {
    const [visiblePaths, setVisiblePaths] = useState<Array<string>>([])
    const [activePathList, setActivePathList] = useState<Array<boolean>>(
        props.processedPathList.map(
            (obj, idx) =>
        {
            return false
        }
    ))

    const onSelectAll = (): void =>
    {
        setActivePathList(
            props.processedPathList.map(
                (obj, idx) =>
            {
                let fcn = (e: string) => {
                    return e === obj.id
                }

                if(visiblePaths.find(fcn) !== undefined)
                {
                    return true
                }
                else
                {
                    return activePathList[idx]
                }
            })
        )
    }
    const onUnselectAll = (): void =>
    {
        setActivePathList(
            props.processedPathList.map(
                (obj, idx) =>
            {
                let fcn = (e: string) => {
                    return e === obj.id
                }

                if(visiblePaths.find(fcn) !== undefined)
                {
                    return false
                }
                else
                {
                    return activePathList[idx]
                }
            })
        )
    }
    const onActivate = (): void =>
    {
        let pathsToArchive: Array<string> = props.processedPathList.filter(
            (obj, idx) =>
        {
            return activePathList[idx] === true
        }).map(
            (obj, idx) =>
        {
            return obj.id
        })

        props.onActivatePending()

        const onTruckList = (response: Array<Truck>|undefined) =>
        {
            if(response === undefined)
            {
                props.onActivateFailure()
            }
            else
            {
                let truckList: Array<string> = response.map(
                    (obj, idx) =>
                {
                    return obj.id
                })

                db.set_path_activation(
                    pathsToArchive,
                    true,
                    truckList,
                    (success: boolean) =>
                {
                    if(success === true)
                    {
                        props.onActivateSuccess()
                    }
                    else
                    {
                        props.onActivateFailure()
                    }
                })
            }
        }
        db.truck_list(
            props.company.id,
            onTruckList)
    }

    const onCheck = useCallback((e: React.ChangeEvent<HTMLInputElement>): void =>
    {
        const { checked, name } = e.target

        let updatedList: Array<boolean> = [...activePathList]
        updatedList[parseInt(name)] = checked
        setActivePathList(updatedList)
    }, [activePathList])
    const generateRows = useCallback((): Array<any> =>
    {
        return props.processedPathList.map((obj, idx) =>
        {
            let edit_link = urls.getArchivedPathsEdit(obj.id)
            let edit_icon = (
                <div className='limn-div-row limn-div-centered'>
                    <Link to={edit_link}>
                        <ModeEditIcon/>
                    </Link>
                </div>
            )

            let select_box = (
                <div className='limn-div-row limn-div-centered'>
                    <input
                        type="checkbox"
                        key={idx}
                        name={idx.toString()}
                        checked={activePathList[idx]}
                        onChange={onCheck}
                    />
                </div>
            )

            let delete_link = urls.getArchivedPathsDelete(obj.id)
            let delete_icon = (
                <div className='limn-div-row limn-div-centered'>
                    <Link to={delete_link}>
                        <DeleteIcon/>
                    </Link>
                </div>
            )

            return {
                key: obj.id,
                path_name: obj.path_name,
                path_description: obj.path_description,
                date_created: obj.date_created,
                edit: edit_icon,
                activate: select_box,
                delete: delete_icon
            }
        })
    }, [activePathList, onCheck, props.processedPathList])

    const [headers] = useState<Array<HeaderDescription>>(
        [
            {
                title: "Path Name",
                field: "path_name",
                dateFilterable: false,
                width: 2000,
                minWidth: 300,
                sortable: true,
                searchable: true,
                customStringify: undefined,
                customSort: (a: string, b: string) => {
                    return a.localeCompare(b)
                }
            },
            {
                title: "Path Description",
                field: "path_description",
                dateFilterable: false,
                width: 2000,
                minWidth: 300,
                sortable: true,
                searchable: true,
                customStringify: undefined,
                customSort: (a: string, b: string) => {
                    return a.localeCompare(b)
                }
            },
            {
                title: "Date Created",
                field: "date_created",
                dateFilterable: true,
                width: 1000,
                minWidth: 300,
                sortable: true,
                searchable: false,
                customStringify: (d: Date) => {
                    return util.formatDateString(d)
                },
                customSort: (a: Date, b: Date) => {
                    return b.getTime() - a.getTime()
                },
            },
            {
                title: "Edit",
                field: "edit",
                dateFilterable: false,
                width: 150,
                minWidth: 150,
                sortable: false,
                searchable: false,
                customStringify: undefined,
                customSort: undefined
            },
            {
                title: "Activate",
                field: "activate",
                dateFilterable: false,
                width: 150,
                minWidth: 150,
                sortable: false,
                searchable: false,
                customStringify: undefined,
                customSort: undefined
            },
            {
                title: "Delete",
                field: "delete",
                dateFilterable: false,
                width: 150,
                minWidth: 150,
                sortable: false,
                searchable: false,
                customStringify: undefined,
                customSort: undefined
            },
        ]
    )
    const [rows, setRows] = useState<Array<any>>(generateRows())

    useEffect(() => {
        setRows(generateRows())
    }, [activePathList, generateRows])

    return (
        <div>
            <div>
                <BigTable
                    title={"Archived Path List"}
                    headers={headers}
                    rows={rows}
                    defaultSort={{
                        key: 'column-2',
                        order: 'desc'
                    }}
                    onVisibilityChange={setVisiblePaths}
                    showDateFilter={true}
                />
            </div>
            <hr className='limn-form-separator-100'/>
            <div className='limn-div-wrapper limn-padded-vert-10px'>
                    <button
                        onClick={onActivate}
                        className='limn-button-padded-5 limn-button'>
                        Activate Paths
                    </button>
                    <span className='limn-whitespace' />
                    <button
                        onClick={onSelectAll}
                        className='limn-button-padded-5 limn-button'>
                        Select All
                    </button>
                    <button
                        onClick={onUnselectAll}
                        className='limn-button-padded-5 limn-button'>
                        Unselect All
                    </button>
            </div>
        </div>
    )
}

export default ArchivedPathAdminTable
