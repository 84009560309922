import React from 'react'

import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { ProcessedPathInfo } from 'web_common/tsx/api/db/Models'
import BigTable from 'web_common/tsx/components/misc/BigTable'
import { util } from 'web_common/tsx/components/misc/Util'

import { urls } from 'InternalUrls'

interface ActivePathUserTableProps {
    processedPathList: Array<ProcessedPathInfo>,
}

const ActivePathUserTable: React.FC<ActivePathUserTableProps> = (props) => {
    let headers = [
        {
            title: "Path Name",
            field: "path_name",
            dateFilterable: false,
            width: 2000,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "Path Description",
            field: "path_description",
            dateFilterable: false,
            width: 2000,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "Date Created",
            field: "date_created",
            dateFilterable: true,
            width: 1000,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customStringify: (d: Date) => {
                return util.formatDateString(d)
            },
            customSort: (a: Date, b: Date) => {
                return b.getTime() - a.getTime()
            },
        },
        {
            title: "Info",
            field: "info",
            dateFilterable: false,
            width: 150,
            minWidth: 150,
            sortable: false,
            searchable: false,
            customStringify: undefined,
            customSort: undefined
        },
    ]

    let rows = props.processedPathList.map(
        (obj, idx) =>
        {
            let info_link = urls.getActivePathsInfo(obj.id)
            let info_icon = (
                <div className='limn-div-row limn-div-centered'>
                    <Link to={info_link}>
                        <InfoIcon/>
                    </Link>
                </div>
            )

            return {
                path_name: obj.path_name,
                path_description: obj.path_description,
                date_created: obj.date_created,
                info: info_icon,
            }
        }
    )

    return (
        <div>
            <div>
                <BigTable
                    title={"Active Path List"}
                    headers={headers}
                    rows={rows}
                    defaultSort={{
                        key: 'column-2',
                        order: 'desc'
                    }}
                    showDateFilter={true}
                    onVisibilityChange={(val)=>{}}
                />
            </div>
        </div>
    )
}

export default ActivePathUserTable
