import axios from 'axios'
import Cookies from 'universal-cookie'

let xsrfCookieName: string = ''
if(process.env.REACT_APP_XSRF_COOKIE_NAME === undefined)
{
    alert("Please add REACT_APP_XSRF_COOKIE_NAME to your environment")
}
else
{
    xsrfCookieName = process.env.REACT_APP_XSRF_COOKIE_NAME
}

const cookies = new Cookies(null, { path: '/' })

// No shame in stack overflow grabbing
// https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
const download_file = (
    file_url: string,
    file_name: string,
    onCompletion: (success: boolean) => void) =>
{
    axios({
        url: file_url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name);
        document.body.appendChild(link);
        link.click();
        onCompletion(true)
    })
    .catch(
        (error) => {
            onCompletion(false)
        });
}

const get = (
    url: string,
    auth: boolean,
    onResponse: (response: any) => void,
    onError: (error: any) => void) =>
{
    let config = {
        withCredentials: auth,
        headers: {

        }
    };

    axios.get(url, config)
        .then(
            (response) => {
                onResponse(response);
            })
        .catch(
            (error) => {
                onError(error);
            });
}

const post = (
    url: string,
    post_data: any,
    auth: boolean,
    onResponse: (response: any) => void,
    onError: (error: any) => void) =>
{
    let config = {
        withCredentials: auth,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    }
    if(auth)
    {
        Object.assign(config.headers, { 'x-csrftoken': cookies.get(xsrfCookieName) })
    }

    axios.post(url, post_data, config)
        .then(
            (response) => {
                onResponse(response)
            })
        .catch(
            (error) => {
                onError(error)
            })
}

const postMultipart = (
    url: string,
    formData: FormData,
    auth: boolean,
    onResponse: (response: any) => void,
    onError: (error: any) => void) =>
{
    let config = {
        withCredentials: auth,
        headers: {
            "Accept": "application/json",
            "Content-Type": "multipart/form-data",
        }
    }
    if(auth)
    {
        Object.assign(config.headers, { 'x-csrftoken': cookies.get(xsrfCookieName) })
    }

    axios.post(url, formData, config)
        .then(
            (response) => {
                onResponse(response)
            })
        .catch(
            (error) => {
                onError(error)
            });
}

const patch = (
    url: string,
    post_data: any,
    auth: boolean,
    onResponse: (response: any) => void,
    onError: (error: any) => void) =>
{
    let config = {
        withCredentials: auth,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    }
    if(auth)
    {
        Object.assign(config.headers, { 'x-csrftoken': cookies.get(xsrfCookieName) })
    }

    axios.patch(url, post_data, config)
        .then(
            (response) => {
                onResponse(response)
            })
        .catch(
            (error) => {
                onError(error)
            })
}

const patchAll = (
    urls: Array<string>,
    patch_data: Array<any>,
    auth: boolean,
    onResponses: (response: any) => void,
    onErrors: (error: any) => void) =>
{
    let config = {
        withCredentials: auth,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    };
    if(auth)
    {
        Object.assign(config.headers, { 'x-csrftoken': cookies.get(xsrfCookieName) })
    }

    let promises = []
    for(let i = 0; i < patch_data.length; i++)
    {
        promises.push(
            axios.patch(urls[i], patch_data[i], config));
    }

    axios.all(promises)
        .then(axios.spread((...responses) => {
            onResponses(responses);
        }))
        .catch(errors => {
            onErrors(errors);
        });
}

const deleteReq = (
    url: string,
    auth: boolean,
    onResponse: (response: any) => void,
    onError: (error: any) => void) =>
{
    let config = {
        withCredentials: auth,
        headers: {

        }
    };
    if(auth)
    {
        Object.assign(config.headers, { 'x-csrftoken': cookies.get(xsrfCookieName) })
    }

    axios.delete(url, config)
        .then(
            (response) => {
                onResponse(response);
            })
        .catch(
            (error) => {
                onError(error);
            });
}

export const requests =
{
    download_file,
    get,
    post,
    postMultipart,
    patch,
    patchAll,
    deleteReq,
}
