import {
    CompanyAdminPrivilege,
    Truck,
    NtripCredential,
    RtkService
} from 'web_common/tsx/api/db/Models'

import moment from 'moment'

const convertCompanyAdminPermission = (val: number|undefined|null): CompanyAdminPrivilege =>
{
    if(val === 1)
    {
        return CompanyAdminPrivilege.USER
    }
    else if(val === 2)
    {
        return CompanyAdminPrivilege.ADMIN
    }
    else
    {
        return CompanyAdminPrivilege.NONE
    }
}

const transcodeCompanyAdminPrivilege = (val: CompanyAdminPrivilege): string =>
{
    if(val === CompanyAdminPrivilege.USER)
    {
        return "User"
    }
    else if(val === CompanyAdminPrivilege.ADMIN)
    {
        return "Administrator"
    }
    else
    {
        return "None"
    }
}

const convertDate = (val: string|undefined|null): Date|undefined =>
{
    if(val === undefined || val === null)
    {
        return undefined
    }
    else
    {
        return moment(val).toDate()
    }
}

const convertDateNoOptional = (val: string|undefined|null): Date =>
{
    if(val === undefined || val === null)
    {
        console.log("Date no expected to be undefined or null")
        return new Date()
    }
    else
    {
        return moment(val).toDate()
    }

}

const reconcileQsArray = (val: Array<string>): string =>
{
    if(val.length === 0)
    {
        return ''
    }

    let qs: string = '?'
    for(let i = 0; i < val.length; i++)
    {
        qs = qs + val[i]
        if(i + 1 !== val.length)
        {
            qs = qs + '&'
        }
    }

    return qs
}

interface NtripInfo {
    rtkService: RtkService,
    ntripCredentials: Array<NtripCredential>
}

export interface ReconciledNtripInfo {
    truck: Truck,
    ntripInfo: Array<NtripInfo>
    activeRtkServiceIdx: number|undefined
    activeNtripCredentialIdx: number|undefined
}

const reconcileNtripInfo = (
    trucks: Array<Truck>,
    rtkServices: Array<RtkService>,
    ntripCredentials: Array<NtripCredential>): Array<ReconciledNtripInfo> =>
{
    let reconciledInfo: Array<ReconciledNtripInfo> = trucks.map(
        (truck, truck_idx) => {
        let reconciledInfo: ReconciledNtripInfo = {
            truck: truck,
            ntripInfo: [],
            activeRtkServiceIdx: undefined,
            activeNtripCredentialIdx: undefined,
        }

        let filteredNtripCredentials: Array<NtripCredential> = ntripCredentials.filter(
            (ntripCred) =>
        {
            return ntripCred.truck === truck.id
        })
        let filteredRtkServices: Array<RtkService> = rtkServices.filter(
            (rtkService) =>
        {
            return filteredNtripCredentials.find(
                (ntripCred) =>
            {
                return ntripCred.rtk_service === rtkService.id
            })
        })

        let activeRtkServiceIdx: number|undefined = undefined
        let activeNtripIdx: number|undefined = undefined
        reconciledInfo.ntripInfo = filteredRtkServices.map(
            (rtkService, idx) =>
        {
            let found: boolean = false

            let ntripInfo: NtripInfo = {
                rtkService: rtkService,
                ntripCredentials: filteredNtripCredentials.filter(
                    (ntripCredential, filteredNtripIdx) =>
                {
                    let ret = ntripCredential.rtk_service === rtkService.id

                    if(ntripCredential.is_active === true && ret === true)
                    {
                        activeRtkServiceIdx = idx
                        found = true
                    }

                    return ret
                })
            }

            if(found)
            {
                let idx = ntripInfo.ntripCredentials.findIndex(
                    (e: NtripCredential) =>
                {
                    return e.is_active === true
                })

                if(idx >= 0)
                {
                    activeNtripIdx = idx
                }
                else
                {
                    activeNtripIdx = undefined
                    activeRtkServiceIdx = undefined
                }
            }

            return ntripInfo
        })

        reconciledInfo.activeRtkServiceIdx = activeRtkServiceIdx
        reconciledInfo.activeNtripCredentialIdx = activeNtripIdx

        return reconciledInfo
    })

    return reconciledInfo
}

export const util = {
    convertCompanyAdminPermission,
    transcodeCompanyAdminPrivilege,
    convertDate,
    convertDateNoOptional,
    reconcileQsArray,
    reconcileNtripInfo
}
