import React, { useState, useEffect, useCallback } from 'react'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { CompanyAdminPrivilege, LifemarkUserModel } from 'web_common/tsx/api/db/Models'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import ChangeUserCompany from 'components/admin/ChangeUserCompany'
import ChangeTruckCompany from 'components/admin/ChangeTruckCompany'

enum Operation {
    CHANGE_USER_COMPANY = 0,
    CHANGE_TRUCK_COMPANY = 1,
}

interface ActionSelectorProps {
    user: LifemarkUserModel,
    operation: Operation,
}

const ActionSelector: React.FC<ActionSelectorProps> = (props) => {
    if(props.operation === Operation.CHANGE_USER_COMPANY)
    {
        return (
            <ChangeUserCompany
                user={props.user}
            />
        )
    }
    else
    {
        return (
            <ChangeTruckCompany
                user={props.user}
            />
        )
    }
}

interface AdminProps {

}

const Admin: React.FC<AdminProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [operation, setOperation] = useState<Operation>(Operation.CHANGE_USER_COMPANY)

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    const handleChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) =>
    {
        setOperation(parseInt(e.target.value))
    }, [setOperation])

    if(apiError === true)
    {
        return <ApiError/>
    }
    else if(lifemarkUser !== undefined)
    {
        if(lifemarkUser.privilege !== CompanyAdminPrivilege.ADMIN ||
            lifemarkUser.company_admin_superuser !== true)
        {
            return (
                <PermissionDenied/>
            )
        }
        else
        {
           return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Admin </h1>
                    <div className='limn-div-row limn-margin-25 limn-width-50'>
                        <label
                            className='limn-label-padded limn-width-200px'
                            htmlFor='operation'
                        >
                            Select Action:
                        </label>
                        <select
                            className='limn-select-box'
                            id='operation'
                            name='operation'
                            value={operation}
                            onChange={handleChange}
                        >
                            <option
                                key={0}
                                value={Operation.CHANGE_USER_COMPANY}
                            >
                                Change User's Company
                            </option>
                            <option
                                key={1}
                                value={Operation.CHANGE_TRUCK_COMPANY}
                            >
                                Change Truck's Company
                            </option>
                        </select>
                    </div>
                    <div>
                        <ActionSelector
                            user={lifemarkUser}
                            operation={operation}
                        />
                    </div>
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Admin </h1>
                <Loading/>
            </div>
        )
    }
}

export default Admin
