import React from 'react'

import { Circles } from 'react-loader-spinner'

import "web_common/css/LimnTech.css"

interface LoadingProps {

}

const Loading: React.FC<LoadingProps> = (props) => {
    return (
        <div className='limn-div-centered'>
            <Circles
                height="100%"
                width="100%"
                color="#87CEEB"
            />
        </div>
    )
}

export default Loading;
