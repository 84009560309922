import React, { useState, useEffect } from 'react'

import { Navigate } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { AuthToken, Company, ImportedPath } from 'web_common/tsx/api/db/Models'
import { service_api, PreviewResponse, PublishResponse } from 'web_common/tsx/api/service/Api'
import Loading from 'web_common/tsx/components/misc/Loading'
import MapOverlay, { MapOverlayProps } from 'web_common/tsx/components/misc/MapOverlay'

interface MapViewProps {
    mapOverlay: MapOverlayProps
    loaded: boolean
    onPublish: () => void
}

const MapView: React.FC<MapViewProps> = (props) => {
    let publishLogic = <div></div>
    if(props.loaded)
    {
        publishLogic = (
            <div>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <hr className='limn-form-separator-80'/>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <div className='limn-margin-10 limn-width-80'>
                    <p><b> Troubleshooting Guidelines </b></p>
                    <ul>
                        <li> Verify the provided coordinates are sorted correctly. </li>
                        <li> The coordinate locations may have high error due to being collected without an RTK fix. </li>
                        <li> The coordinates may have been provided in the wrong geodetic datum. </li>
                        <li> Latitude/Longitude/Altitude values may have been provided in scientific notation instead of decimal notation. </li>
                        <li> Latitude/Longitude values must be between <span>&#177;</span>90<span>&deg;</span> and <span>&#177;</span>180<span>&deg;</span> respectively. </li>
                        <li> Positive latitudes must be north of the equator and negative latitudes must be south of the equator. </li>
                        <li> Positive longitudes must be east of the Prime Meridian and negative longitudes must be west of the Prime Meridian. </li>
                        <li> Provided coordinates may be spaced too far apart to accurately create a path. </li>
                    </ul>
                    <span>&ensp;</span>
                    <span>&ensp;</span>
                    <span>&ensp;</span>
                    <span>&ensp;</span>
                    <div className='limn-div-row limn-div-centered'>
                        <button className='limn-button-padded-5 limn-button limn-width-75' onClick={props.onPublish}> Publish </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='limn-div-centered'>
                <MapOverlay
                    encodedPaths={props.mapOverlay.encodedPaths}
                    patternTransitionReference={props.mapOverlay.patternTransitionReference}
                    patternTransitions={props.mapOverlay.patternTransitions}
                    setPatternTransitions={props.mapOverlay.setPatternTransitions}
                    defaultTransitionColor={props.mapOverlay.defaultTransitionColor}
                    transitionEditable={props.mapOverlay.transitionEditable}
                    showPatternAutomation={props.mapOverlay.showPatternAutomation}
                    loaded={props.mapOverlay.loaded}
                />
            </div>
            { publishLogic }
        </div>
    )
}

interface PreviewPathFormProps {
    company: Company,
    path: ImportedPath,
    token: AuthToken,
    getImportSuccessUrl: () => string,
    getImportFailureUrl: () => string
}

const PreviewPathForm : React.FC<PreviewPathFormProps> = (props) => {
    const [encodedPath, setEncodedPath] = useState<MapOverlayProps>({
        encodedPaths: [ {
            path_list: [],
            precision: 0,
        } ],
        patternTransitionReference: [],
        setPatternTransitions: (val) => {},
        patternTransitions: [],
        defaultTransitionColor: "unknown",
        transitionEditable: false,
        showPatternAutomation: false,
        loaded: false
    })
    const [previewError, setPreviewError] = useState<string|undefined>(undefined)
    const [showLoading, setShowLoading] = useState<boolean|undefined>(undefined)
    const [publishResults, setPublishResults] = useState<boolean|undefined>(undefined)

    useEffect(() => {
        service_api.preview(
            props.token.token,
            props.path.id,
            (response: PreviewResponse) =>
        {
            if(response.error === true)
            {
                setPreviewError(response.error_message)
            }
            else
            {
                if(response.reference_path !== undefined)
                {
                    setEncodedPath({
                        encodedPaths: [response.reference_path],
                        patternTransitionReference: [response.reference_path],
                        patternTransitions: response.transitions,
                        setPatternTransitions: (val) => {},
                        defaultTransitionColor: "unknown",
                        transitionEditable: false,
                        showPatternAutomation: response.transitions.length > 0,
                        loaded: true
                    })
                }
                else
                {
                    setPreviewError("Did not receive correct data from the server")
                }
            }
        })
    }, [props.token.token, props.path.id])

    const onPublish = () =>
    {
        setShowLoading(true)
        service_api.publish(
            props.token.token,
            props.path.id,
            (response: PublishResponse) =>
        {
            if(response.error === undefined)
            {
                setPublishResults(true)
            }
            else
            {
                console.log("Publishing error: " + response.error)
                setPublishResults(false)
            }
        })
    }

    if(publishResults !== undefined)
    {
        if(publishResults === true)
        {
            return (
                <Navigate
                    to='/import/success/'
                />
            )
        }
        else
        {
            return (
                <Navigate
                    to='/import/failure/'
                />
            )
        }
    }
    else if(showLoading)
    {
        return <Loading/>
    }
    else
    {
        let map = (previewError === undefined) ?
        (
            <div>
                <MapView
                    mapOverlay={encodedPath}
                    loaded={encodedPath.loaded}
                    onPublish={onPublish}
                />
            </div>
            ) :
            (
                <p className='limn-warning limn-margin-10'> Error Previewing Path: { previewError } </p>
            )

        return (
            <div className='limn-bigfont'>
                <div className='limn-margin-10 limn-width-80'>
                    <p> Please verify the information below. If everything is correct, hit the button entitled <b> Publish </b> which will appear at the bottom of the page. </p>
                    <ul>
                        <li><b> Path Name:</b> { props.path.path_name } </li>
                        <li><b> Path Description:</b> { props.path.path_description } </li>
                        <li><b> Company Name:</b> { props.company.name } </li>
                    </ul>
                    <p> If the uploaded file is formatted correctly, an an overlay of the imported path on a map will be shown below. You can use this information to help verify that the path you imported was read in correctly by the LimnTech system. Otherwise, you can use the error message below to reformat the file and try again. </p>
                </div>
                { map }
            </div>
        )
    }
}

export default PreviewPathForm
