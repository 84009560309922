import React, { useState } from 'react'

import { Navigate } from 'react-router-dom'
import { Table } from 'react-bootstrap'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { Company, ImportedPath } from 'web_common/tsx/api/db/Models'
import exampleUploadPath from 'web_common/tsx/components/import/exampleUploadPath'
import exampleUploadPas from 'web_common/tsx/components/import/exampleUploadPas'
import Loading from 'web_common/tsx/components/misc/Loading'

interface ExampleCsvProps {

}

const LongLineExampleCsvLink: React.FC<ExampleCsvProps> = (props) => {
    const url = window.URL.createObjectURL(new Blob([exampleUploadPath()]))
    let link = <a href={url} download={'path_upload_example.csv'}>here</a>

    return link
}

const PasExampleCsvLink: React.FC<ExampleCsvProps> = (props) => {
    const url = window.URL.createObjectURL(new Blob([exampleUploadPas()]))
    let link = <a href={url} download={'pattern_transitions_example.csv'}>here</a>

    return link
}

const validatePathName = (pathName: string): boolean =>
{
    return pathName.length <= 50
}
const validatePathDescription = (pathDescription: string): boolean =>
{
    return pathDescription.length <= 200
}

interface PathNameWarningMessageProps {
    pathName: string
}
const PathNameWarningMessage: React.FC<PathNameWarningMessageProps> = (props) => {
    let valid: boolean = validatePathName(props.pathName)

    if(valid === true)
    {
        return <></>
    }
    else
    {
        return (
            <p className='limn-warning limn-margin-10'>
                The path name must have 50 or fewer characters
            </p>
        )
    }
}

interface PathDescriptionWarningMessageProps {
    pathDescription: string
}
const PathDescriptionWarningMessage: React.FC<PathDescriptionWarningMessageProps> = (props) => {
    let valid: boolean = validatePathDescription(props.pathDescription)

    if(valid === true)
    {
        return <></>
    }
    else
    {
        return (
            <p className='limn-warning limn-margin-10'>
                The path description must have 200 or fewer characters
            </p>
        )
    }
}

interface ImportGuidelinesProps {
    company: Company,
    hidePasImport: boolean
}

const ImportGuidelines: React.FC<ImportGuidelinesProps> = (props) => {
    if(props.company.pattern_coding_unlock === true && props.hidePasImport === false)
    {
        return (
            <>
            <p className='limn-form-description limn-margin-10 limn-bigfont'> Virtual Line Import Guidelines </p>
            <ul className='limn-margin-10 limn-bigfont'>
                <li> The imported files must be in CSV format, which is a file containing comma separated values. </li>
                <li> The imported file must contain columns of latitude, longitude, and (optionally but recommended) altitude. </li>
                <li> The coordinate system uses the <b> WGS84 </b> datum. </li>
                <li> Latitude and longitude units are in decimal degrees, and altitude units are in meters above the reference ellipsoid. </li>
                <li> Latitude and longitude must be reported to 8 or more decimal places, and altitude must be reported to 3 or more decimal places. </li>
                <li> The input points must be sorted consecutively along the direction of the roadway. </li>
                <li> Add an empty row to the CSV file if you wish to create a break between line/path segments. </li>
                <li> If you wish to see an example of a CSV file that follows these guidelines, please click on the link <LongLineExampleCsvLink/>. </li>
            </ul>
            <p className='limn-form-description limn-margin-10 limn-bigfont'> P.A.S. Import Guidelines </p>
            <ul className='limn-margin-10 limn-bigfont'>
                <li> The upload of this data is <b> optional</b>. If included, this will allow trucks equipped with P.A.S. to layout imported transitions. </li>
                <li> The imported files must be in CSV format, which is a file containing comma separated values. </li>
                <li> The imported file must contain columns of color, "Previous Pattern", "Next Pattern", latitude, longitude and (optionally but recommended) altitude. </li>
                <li> The coordinate system uses the <b> WGS84 </b> datum. </li>
                <li> The color field must be either <b> yellow </b> or <b> white </b>. </li>
                <li> The LLA coordinate is the location of the transition from "Previous Pattern" to "Next Pattern" along the direction of the points in the virtual line CSV. </li>
                <li> The encoding of "Previous Pattern" and "Next Pattern" can be found in the table below: </li>
                <div className='limn-margin-10'>
                <Table hover bordered striped className='limn-padded-vert-10px limn-width-50'>
                    <thead>
                        <tr>
                            <td><b> Pattern Encoding </b></td>
                            <td><b> Description </b></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td> D </td>
                            <td> Solid Double </td>
                        </tr>
                        <tr>
                            <td> S </td>
                            <td> Solid Single </td>
                        </tr>
                        <tr>
                            <td> PL </td>
                            <td> Pass Left </td>
                        </tr>
                        <tr>
                            <td> PR </td>
                            <td> Pass Right </td>
                        </tr>
                        <tr>
                            <td> SS </td>
                            <td> Single Skip </td>
                        </tr>
                        <tr>
                            <td> DMS </td>
                            <td> Double Mini Skip </td>
                        </tr>
                        <tr>
                            <td> SMS </td>
                            <td> Single Mini Skip </td>
                        </tr>
                        <tr>
                            <td> NL </td>
                            <td> No Line </td>
                        </tr>
                        <tr>
                            <td> UNK </td>
                            <td> Unknown (see below) </td>
                        </tr>
                    </tbody>
                </Table>
                </div>
                <li> Every transition coordinate must match a corresponding coordinate in the imported virtual line CSV. </li>
                <li> Only one line color is currently supported per path import. </li>
                <li> For transitions corresponding to each line segment in the imported virtual line, "Previous Pattern" of the first transition must be unknown. </li>
                <li> For transitions corresponding to each line segment in the imported virtual line, "Next Pattern" of the last transition must be unknown. </li>
                <li> For transitions corresponding to each line segment in the imported virtual line, "Previous Pattern" must be the same as "Next Pattern" of the previous row. </li>
                <li> If you wish to see an example of a CSV file that follows these guidelines, please click on the link <PasExampleCsvLink/>. </li>
            </ul>
            </>
        )
    }
    else
    {
        return (
            <>
            <p className='limn-form-description limn-margin-10 limn-bigfont'> Import Guidelines </p>
            <ul className='limn-margin-10 limn-bigfont'>
                <li> The imported file must be in CSV format, which is a file containing comma separated values. </li>
                <li> The imported file must contain columns of latitude, longitude, and (optionally but recommended) altitude. </li>
                <li> The coordinate system uses the <b> WGS84 </b> datum. </li>
                <li> Latitude and longitude units are in decimal degrees, and altitude units are in meters above the reference ellipsoid. </li>
                <li> Latitude and longitude must be reported to 8 or more decimal places, and altitude must be reported to 3 or more decimal places. </li>
                <li> The input points must be sorted consecutively along the direction of the roadway. </li>
                <li> Add an empty row to the CSV file if you wish to create a break between line/path segments. </li>
                <li> If you wish to see an example of a CSV file that follows these guidelines, please click on the link <LongLineExampleCsvLink/>. </li>
            </ul>
            </>
        )
    }
}

interface FileUploadProps {
    setUploadVirtualLineFile: (obj: File|undefined) => void,
    setUploadPasFile: (obj: File|undefined) => void,
    company: Company,
    hidePasImport: boolean
}

const FileUploadForm: React.FC<FileUploadProps> = (props) => {
    const onUploadedVirtualLineFile = (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        if(e.target.files !== null)
        {
            const { files } = e.target
            const selectedFiles = files as FileList
            props.setUploadVirtualLineFile(selectedFiles[0])
        }
    }

    const onUploadedPasFile = (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        if(e.target.files !== null)
        {
            const { files } = e.target
            const selectedFiles = files as FileList
            props.setUploadPasFile(selectedFiles[0])
        }
    }

    if(props.company.pattern_coding_unlock === true && props.hidePasImport === false)
    {
        return (
            <div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor="path-file"> Virtual Line CSV: </label>
                    <input
                        type="file"
                        className="limn-file-upload"
                        onChange={onUploadedVirtualLineFile}/>
                </div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor="pas-file"> P.A.S. CSV (<b>optional</b>): </label>
                    <input
                        type="file"
                        className="limn-file-upload"
                        onChange={onUploadedPasFile}/>
                </div>
            </div>
        )
    }
    else
    {
        return (
            <div>
                <div className='limn-div-row limn-margin-10 limn-width-75'>
                    <label className='limn-label-padded limn-width-200px' htmlFor="path-file"> Virtual Line CSV: </label>
                    <input
                        type="file"
                        className="limn-file-upload"
                        onChange={onUploadedVirtualLineFile}/>
                </div>
            </div>
        )
    }
}

interface PathInformationEntryProps {
    company: Company,
    hidePasImport: boolean,
    getPreviewUrl: (company_id: string, import_id: string) => string
}

const PathInformationEntry: React.FC<PathInformationEntryProps> = (props) => {
    const [pathName, setPathName] = useState<string>('')
    const [pathDescription, setPathDescription] = useState<string>('')
    const [uploadVirtualLineFile, setUploadVirtualLineFile] = useState<File|undefined>(undefined)
    const [uploadPasFile, setUploadPasFile] = useState<File|undefined>(undefined)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [importFile, setImportFile] = useState<ImportedPath|undefined>(undefined)

    const onPathNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        setPathName(e.target.value)
    }
    const onPathDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    {
        setPathDescription(e.target.value)
    }
    const onSubmit = (e: React.FormEvent) =>
    {
        /*
         * This will stop the default behavor of refreshing the page
         */
        e.preventDefault()

        /*
         * Lets validate before submitting anything
         */
        let validated_path_name: boolean = validatePathName(pathName)
        if(validated_path_name === false)
        {
            alert("The path name entered is invalid")
            return
        }

        let validated_path_desc: boolean = validatePathDescription(pathDescription)
        if(validated_path_desc === false)
        {
            alert("The path description entered is invalid")
            return
        }

        if(uploadVirtualLineFile === undefined)
        {
            alert("You must upload a file")
            return
        }

        const zip = require('jszip')()
        const dataFolder = zip.folder('data')
        dataFolder.file('lla.csv', uploadVirtualLineFile)

        if(uploadPasFile !== undefined)
        {
            dataFolder.file('pattern_transitions.csv', uploadPasFile)
        }

        let onImportedPathResponse = (response: ImportedPath|undefined): void =>
        {
            if(response === undefined)
            {
                alert("There was a problem sending the zip file to LimnTech's servers. Please reach out to the LimnTech team for assistance")
                setShowLoading(false)
            }
            else
            {
                setImportFile(response)
            }
        }

        zip.generateAsync({type: 'blob'}).then(
            (content: Blob) =>
            {
                db.import_path(
                    pathName,
                    pathDescription,
                    props.company.id,
                    content,
                    'data.zip',
                    onImportedPathResponse)

            }
        ).catch(
            (error: any) =>
            {
                console.log(error)
                alert("There was a problem zipping the contents of the files you uploaded. Please reach out to the LimnTech team for assistance")
                setShowLoading(false)
            }
        )

        setShowLoading(true)
    }

    if(importFile)
    {
        return (
            <Navigate
                to={props.getPreviewUrl(props.company.id, importFile.id)}
            />
        )
    }
    else if(showLoading)
    {
        return (
            <div>
                <Loading/>
            </div>
        )
    }
    else
    {
        return (
            <div>
                <hr className='limn-form-separator-80'></hr>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <ImportGuidelines company={props.company} hidePasImport={props.hidePasImport}/>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <hr className='limn-form-separator-80'></hr>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <form onSubmit={onSubmit}>
                    <div className='limn-div-row limn-margin-10 limn-width-75'>
                        <label className="limn-label-padded limn-width-200px" htmlFor="path-name"> Path Name: </label>
                        <input
                            className="limn-input"
                            type="text"
                            id="path-name"
                            name="path-name"
                            placeholder={"Please enter the name of the new path you would like to create..."}
                            value={pathName}
                            onChange={onPathNameChange}
                            required />
                    </div>
                    <PathNameWarningMessage
                        pathName={pathName}
                    />
                    <div className='limn-div-row limn-margin-10 limn-width-75'>
                        <label className='limn-label-padded limn-width-200px' htmlFor="path-description"> Path Description: </label>
                        <textarea
                            className="limn-input"
                            id="path-description"
                            name="path-description"
                            placeholder={"Optionally, please enter a description of the new path..."}
                            value={pathDescription}
                            onChange={onPathDescriptionChange}/>
                    </div>
                    <PathDescriptionWarningMessage
                        pathDescription={pathDescription}
                    />
                    <FileUploadForm
                        setUploadVirtualLineFile={setUploadVirtualLineFile}
                        setUploadPasFile={setUploadPasFile}
                        company={props.company}
                        hidePasImport={props.hidePasImport}/>
                    <div className='limn-width-50 limn-margin-25 limn-div-centered'>
                        <button className="limn-width-50 limn-button" type="submit">Preview Path</button>
                    </div>
                </form>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
            </div>
        )
    }
}

export default PathInformationEntry
