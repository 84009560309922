import React, { useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Link, Navigate } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { ProcessedPathInfo } from 'web_common/tsx/api/db/Models'
import BigTable from 'web_common/tsx/components/misc/BigTable'

import { util } from 'web_common/tsx/components/misc/Util'
import { urls } from 'InternalUrls'

interface ProposedPathAdminTableProps {
    proposedPathList: Array<ProcessedPathInfo>,
}

const ProposedPathAdminTable: React.FC<ProposedPathAdminTableProps> = (props) => {
    const [redirectToAdd, setRedirectToAdd] = useState<boolean>(false)

    let headers = [
        {
            title: "Path Name",
            field: "path_name",
            dateFilterable: false,
            width: 2000,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "Path Description",
            field: "path_description",
            dateFilterable: false,
            width: 2000,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customStringify: undefined,
            customSort: (a: string, b: string) => {
                return a.localeCompare(b)
            }
        },
        {
            title: "Date Created",
            field: "date_created",
            dateFilterable: true,
            width: 1000,
            minWidth: 300,
            sortable: true,
            searchable: true,
            customStringify: (d: Date) => {
                return util.formatDateString(d)
            },
            customSort: (a: Date, b: Date) => {
                return b.getTime() - a.getTime()
            },
        },
        {
            title: "Edit",
            field: "edit",
            dateFilterable: false,
            width: 150,
            minWidth: 150,
            sortable: false,
            searchable: false,
            customStringify: undefined,
            customSort: undefined
        },
        {
            title: "Delete",
            field: "delete",
            dateFilterable: false,
            width: 150,
            minWidth: 150,
            sortable: false,
            searchable: false,
            customStringify: undefined,
            customSort: undefined
        },
    ]

    let rows = props.proposedPathList.map(
        (obj, idx) =>
        {
            let edit_link = urls.getProposedPathsEdit(obj.id)
            let edit_icon = (
                <div className='limn-div-row limn-div-centered'>
                    <Link to={edit_link}>
                        <ModeEditIcon/>
                    </Link>
                </div>
            )

            let delete_link = urls.getProposedPathsDelete(obj.id)
            let delete_icon = (
                <div className='limn-div-row limn-div-centered'>
                    <Link to={delete_link}>
                        <DeleteIcon/>
                    </Link>
                </div>
            )

            return {
                path_name: obj.path_name,
                path_description: obj.path_description,
                date_created: obj.date_created,
                edit: edit_icon,
                delete: delete_icon,
            }
        }
    )

    if(redirectToAdd === true)
    {
        return (
            <Navigate
                to={urls.getProposedPathsCreate()}
            />
        )
    }
    else
    {
        return (
            <div>
                <div>
                    <BigTable
                        title={"Proposed Path List"}
                        headers={headers}
                        rows={rows}
                        defaultSort={{
                            key: 'column-2',
                            order: 'desc'
                        }}
                        showDateFilter={true}
                        onVisibilityChange={(val)=>{}}
                    />
                </div>
                <hr className='limn-form-separator-100'/>
                <div className='limn-div-row-nowrap limn-padded-vert-10px'>
                    <div>
                        <button
                            onClick={()=>{setRedirectToAdd(true)}}
                            className='limn-button-padded-5 limn-button'>
                            Create Proposed Path
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProposedPathAdminTable
