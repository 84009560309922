import React, { useState, useEffect } from 'react'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { Company, LifemarkUserModel, AuthToken, ProcessedPathDetail, PathPreview } from 'web_common/tsx/api/db/Models'
import { service_api, DownloadResponse, PatternTransitionResponse, PatternTransitions } from 'web_common/tsx/api/service/Api'
import { requests } from 'web_common/tsx/api/Request'
import MapOverlay from 'web_common/tsx/components/misc/MapOverlay'
import Loading from 'web_common/tsx/components/misc/Loading'
import ApiError from 'web_common/tsx/components/misc/ApiError'

interface ExportDetailProps {
    company: Company,
    pathDetail: ProcessedPathDetail,
    pathPreview: PathPreview,
    exported: boolean
}

const ExportDetailForm: React.FC<ExportDetailProps> = (props) => {
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [apiError, setApiError] = useState<boolean>(false)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [userToken, setUserToken] = useState<AuthToken|undefined>(undefined)
    const [patternTransitions, setPatternTransitions] = useState<PatternTransitionResponse|undefined>(undefined)

    const { pathDetail } = props

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response !== undefined)
            {
                setLifemarkUser(response)
            }
            else
            {
                setApiError(true)
            }
        }
        db.current_user(onCurrentUser)
    }, [])

    useEffect(() => {
        if(lifemarkUser !== undefined)
        {
            const onResponse = (response: AuthToken|undefined) =>
            {
                if(response !== undefined)
                {
                    setUserToken(response)
                }
                else
                {
                    setApiError(true)
                }
            }

            db.auth_token(onResponse)
        }
    }, [lifemarkUser])

    useEffect(() => {
        if(userToken !== undefined && pathDetail !== undefined)
        {
            const onResponse = (response: PatternTransitionResponse) =>
            {
                if(response.error === undefined)
                {
                    setPatternTransitions(response)
                }
                else
                {
                    setApiError(true)
                }
            }

            if(props.company.pattern_coding_unlock === true)
            {
                service_api.preview_transitions(
                    userToken.token,
                    pathDetail.id,
                    onResponse)
            }
            else
            {
                setPatternTransitions({
                    transitions: [],
                    reference_path: undefined,
                    version: undefined,
                    error: undefined
                })
            }
        }
    }, [userToken, pathDetail, props.company.pattern_coding_unlock])

    if(apiError === true)
    {
        return <ApiError />
    }
    else if(showLoading === true)
    {
        return <Loading/>
    }
    else if(showSuccess === true)
    {
        return (
            <div>
                <div className='limn-div-left-padded-10'>
                    <p> The path has successfully exported! Please check your downloads folder to find the zipped content. If you wish to perform additional actions, please use the buttons at the top of this website.</p>
                </div>
            </div>
        )
    }

    const onExport = () => {
        const onDownloadComplete = (success: boolean) =>
        {
            if(success === true)
            {
                setShowSuccess(true)
                setShowLoading(false)
            }
            else
            {
                alert("There was a problem downloading your requested file. This this problem persists, please contact the LimnTech team.")
            }
        }

        const onDownloadResponse = (response: DownloadResponse) =>
        {
            if(response.error !== undefined)
            {
                alert("There was a problem preparing the path for download: " + response.error)
                setShowLoading(false)
            }
            else
            {
                if(response.url)
                {
                    requests.download_file(
                        response.url,
                        'data.zip',
                        onDownloadComplete)
                }
            }
        }

        const onAuthTokenResponse = (response: AuthToken|undefined): void =>
        {
            if(response === undefined)
            {
                alert("There was a problem retrieving the proper authentication credentials")
                setShowLoading(false)
            }
            else
            {
                service_api.download(
                    response.token,
                    props.pathDetail.id,
                    onDownloadResponse)
            }
        }

        db.auth_token(onAuthTokenResponse)
        setShowLoading(true)
    }

    if(props.company.high_res_data_subscription === false || props.pathDetail.path_length_meters === null)
    {
        return (
            <div>
                <div className='limn-div-centered'>
                    <p> You do not have permission to export this path! </p>
                </div>
            </div>
        )
    }
    else if(patternTransitions === undefined)
    {
        return <Loading/>
    }

    const METER_TO_MILE: number = 0.000621371
    let length_miles = 0
    if(props.exported === false)
    {
        length_miles = props.pathDetail.path_length_meters * METER_TO_MILE
    }
    let remaining_length = props.company.subscription_remaining_dl_milage +
            props.company.persistent_remaining_dl_milage

    if(length_miles > remaining_length)
    {
         return (
            <div>
                <div className='limn-div-centered'>
                    <p> You do not have enough remaining miles to download this path! </p>
                </div>
            </div>
        )
    }
    else
    {
        return (
            <div>
            <   div className='limn-div-left-padded-10'>
                    <p> Please verify the information below. If everything is correct, hit the button entitled <b> Export </b> to export this path's high resolution coordinates. </p>
                    <ul>
                        <li><b> Path Name: </b> { props.pathDetail.path_name } </li>
                        <li><b> Path Description: </b> { props.pathDetail.path_description } </li>
                        <li><b> Company: </b> { props.company.name } </li>
                    </ul>
                    <p> If you decide to export this path, here is how it will affect { props.company.name }'s remaining download milage: </p>
                    <ul>
                        <li><b> Miles before download:</b> { remaining_length.toFixed(3) }</li>
                        <li><b> Miles required for download:</b> { length_miles.toFixed(3) }</li>
                        <li><b> Remaining miles after download:</b> { (remaining_length - length_miles).toFixed(3) }</li>
                    </ul>
                    <hr className='limn-form-separator-100'></hr>
                    <p> Below is an overlay of the imported path on a map. You can use this to help verify you have selected the correct path to export. </p>
                    <div className='limn-div-centered'>
                        <MapOverlay
                            encodedPaths={[{
                                path_list: props.pathPreview.path_list,
                                precision: props.pathPreview.precision,
                            }]}
                            patternTransitionReference={patternTransitions.reference_path !== undefined ? [patternTransitions.reference_path] : []}
                            patternTransitions={patternTransitions.transitions}
                            setPatternTransitions={(val: Array<PatternTransitions>) => { }}
                            defaultTransitionColor={"unknown"}
                            transitionEditable={false}
                            showPatternAutomation={true}
                            loaded={ true }
                        />
                    </div>
                </div>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <hr className='limn-form-separator-80'></hr>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <span>&ensp;</span>
                <div className='limn-div-left-padded-10'>
                    <div className='limn-div-row limn-div-centered'>
                        <button
                            onClick={onExport}
                            className='limn-button-padded-5 limn-button limn-width-75'>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExportDetailForm
