import React, { useState } from 'react';

import { Navigate } from 'react-router-dom'
import queryString from 'query-string'

import "web_common/css/LimnTech.css"
import { db } from 'web_common/tsx/api/db/Api'
import Loading from 'web_common/tsx/components/misc/Loading'

interface PasswordResetConfirmProps {
    passwordResetConfirmSuccessUrl: string
}

const PasswordResetConfirm: React.FC<PasswordResetConfirmProps> = (props) => {
    const [newPassword1, setNewPassword1] = useState<string>('')
    const [newPassword2, setNewPassword2] = useState<string>('')
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [passwordErrors, setPasswordErrors] = useState<Array<string>>([])
    const [passwordResetSuccess, setPasswordResetSuccess] = useState<boolean>(false)

    const showPasswordErrors = () =>
    {
        if(passwordErrors.length > 0)
        {
            return (
                <div className="limn-warning">
                    <p> Password change failed! </p>
                    <ul>
                        {
                            passwordErrors.map(
                                (obj, idx) =>
                            {
                                return <li key={idx}> { obj } </li>
                            })
                        }
                    </ul>
                </div>
            )
        }
        else
        {
            return <></>
        }
    }

    const handleSubmit = (e: React.FormEvent) =>
    {
        e.preventDefault()
        const parsed = queryString.parse(window.location.search)

        let uid: string|undefined|null|(string|null)[] = parsed.uidb64
        let token: string|undefined|null|(string|null)[] = parsed.token

        if(typeof uid !== 'string' || typeof token !== 'string')
        {
            setPasswordErrors(['The required information is not present to reset your password. Did you follow the link from the password reset email?'])
            return
        }

        const onResponse = (success: boolean, error: Array<string>) =>
        {
            if(success === true)
            {
                setPasswordResetSuccess(true)
            }
            else
            {
                setPasswordErrors(error)
            }
            setShowLoading(false)
        }

        setShowLoading(true)
        db.reset_password_confirm(
            newPassword1,
            newPassword2,
            uid,
            token,
            onResponse)
    };

    if(showLoading)
    {
        return (
            <div>
                <Loading/>
            </div>
        )
    }
    else if(passwordResetSuccess)
    {
        return <Navigate to={props.passwordResetConfirmSuccessUrl}/>
    }
    else
    {
        return (
            <div className="limn-login-container">
                <h2 className="limn-header">Change Password</h2>
                <form className='limn-column-form' onSubmit={handleSubmit}>
                    <label className="limn-label-nopad" htmlFor="new-password">New Password:</label>
                    <input className="limn-input" type="password" id="new-password" name="new-password" value={newPassword1} onChange={(e)=>setNewPassword1(e.target.value)} required />
                    <label className="limn-label-nopad" htmlFor="new-password2">Re-enter New Password:</label>
                    <input className="limn-input" type="password" id="new-password2" name="new-password2" value={newPassword2} onChange={(e)=>setNewPassword2(e.target.value)} required />
                    { showPasswordErrors() }
                    <button className="limn-button" type="submit">Change Password</button>
                </form>
            </div>
        );
    }
};

export default PasswordResetConfirm;
